import { observable, action, makeObservable, runInAction } from 'mobx';
import axios from 'axios';
import { AuthStore } from '@vaettyr/boltcave-auth-client';
import { storeHelpers } from '@vaettyr/boltcave-client-core';
import { Location } from '../type';

export default class LocationStore
{
    @observable locations: Location[] = [];
    @observable busy:boolean = false;

    private route:string;

    constructor()
    {
        makeObservable(this);
        this.route = storeHelpers.GetEndpoint("RPGService");
    }

    @action init = () => {
        if (this.locations?.length > 0) return;
        const { __RPG: { data: { locations = false } = {} } = {} } = window as any;
        if (!!locations) {
            this.locations = locations as Location[];
        } else {
            this.fetch();
        }
    }

    @action save = async (location:Location):Promise<Location> =>
    {
        this.busy = true;
        return new Promise((resolve, reject) => {
            const isUpdate = !!location.id;
            const call = isUpdate ?
                axios.post(`${this.route}api/v1/location`, location, AuthStore.GetConfig()):
                axios.put(`${this.route}api/v1/location`, location, AuthStore.GetConfig());

            call.then(response => {
                const updatedLocation = response.data;
                runInAction(() => {
                    if(isUpdate) {
                        const index = this.locations.findIndex(i => i.id === updatedLocation.id);
                        if(index >= 0) {
                            this.locations = [...this.locations.slice(0, index), updatedLocation, ...this.locations.slice(index + 1)];
                        }
                    } else {
                        this.locations = [...this.locations, updatedLocation];
                    }
                });
                resolve(updatedLocation);
            })
            .catch(err => reject(err))
            .finally(() => {
                runInAction(() => { this.busy = false; });
            });
        })
    }

    @action fetch = async():Promise<Location[]> =>
    {
        this.busy = true;
        return new Promise((resolve, reject) => {
            axios.get(`${this.route}api/v1/location`, AuthStore.GetConfig())
            .then(response => {
                const { data = [] }:{ data: Location[] } = response;
                runInAction(() => {
                    this.locations = data;
                });
                resolve(data ?? []);
            })
            .catch(err => reject(err))
            .finally(() => {
                runInAction(() => { this.busy = false; })
            });
        });
    }

    @action remove = async(item:Location):Promise<boolean> => {
        return new Promise((resolve, reject) => {
            this.busy = true;
            axios.delete(`${this.route}api/v1/location/${item.id}`, AuthStore.GetConfig())
                .then((response) => {
                    runInAction(() => {
                        const index = this.locations.findIndex(v => v.id === item.id);
                        if(index) {
                            this.locations = [...this.locations.slice(0, index), ...this.locations.slice(index + 1)];
                        }
                        resolve(response.data);
                    });
                })
                .catch((err) => {
                    reject(err.response);
                })
                .finally(() => {
                    runInAction(() => {
                        this.busy = false;
                    });
                })
        });
    }
}
import { AuthStore } from "@vaettyr/boltcave-auth-client";
import { Game, Player, Event } from '../type';
import { claimTypes } from "../const";
// this is just a utility to give some basic permissions in a given context
type PermissionArgs = {
  authstore?: AuthStore,
  game?: Game,
  event?: Event,
  isFull?: boolean,
  self?: Player
}

type Permissions = {
  canEditGame?: boolean,
  canJoinGame?: boolean,
  canJoinWaitlist?: boolean,
  canSwitchGame?: boolean,
  canLeaveGame?: boolean,
  canLeaveWaitlist?: boolean,
  canSeeAllPlayers?: boolean,
  canAddPlayer?: boolean,
  canUpdatePreferences?: boolean,
  inGame?: boolean
}

export default ({ authstore, game, isFull, self, event }: PermissionArgs): Permissions => {

  const { user, claims } = authstore ?? {};
  const CanEditAllPlayers = claims?.find((c) => c.Key === claimTypes.Players)?.Value as boolean ?? false;
  const CanEditGames = claims?.find((c) => c.Key === claimTypes.Games)?.Value as "OWN"|"ALL"|undefined;

  const eventLocked = (event?.locked ?? false) || (event?.cancelled ?? false);

  const isHost = self?.host?.id === game?.id;
  const isFlexHost = self?.host?.isFlex ?? false;
  const inAnyGame = !!self?.game || (!!self?.host && !isFlexHost);
  const inThisGame = (!!self?.game && !!game && self.game.id === game.id) || isHost;
  const isPrivate = game?.metadata?.type?.toLowerCase() === 'private';

  const canEditGame = CanEditGames === "ALL" || (!!game && CanEditGames === "OWN" && (game?.owner?.id === user?.id || isHost) && !eventLocked);
  const canJoinGame = !inAnyGame && !isHost && !isPrivate && !isFull && !eventLocked;
  const canJoinWaitlist = !isHost && (!self?.waitlist || (!!game && !self.waitlist?.includes(game.id as number))) && !eventLocked;
  const canSwitchGame = inAnyGame && !inThisGame && !isHost && !isPrivate && !isFull && !eventLocked;
  const canLeaveGame = inThisGame && !isHost && !eventLocked;
  const canLeaveWaitlist = !!game && self?.waitlist?.includes(game.id as number) && !eventLocked;
  const canAddPlayer = (CanEditAllPlayers || isHost || inThisGame) && !eventLocked;
  const canUpdatePreferences = inThisGame && !eventLocked && (game?.systems?.length ?? 0) > 1;

  return {
    canEditGame,
    canJoinGame,
    canJoinWaitlist,
    canSwitchGame,
    canLeaveGame,
    canLeaveWaitlist,
    canSeeAllPlayers: CanEditAllPlayers,
    canAddPlayer,
    canUpdatePreferences,
    inGame: inThisGame
  };
}
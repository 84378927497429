import { inject, observer } from 'mobx-react';
import { useContext, useMemo, ReactNode } from 'react';
import ReactMarkdown from 'react-markdown';
import GameContext from './../game.context';
import { Game, Player, System } from '../../../type';
import SystemIcon from '../../system/widgets/system.icon.view';
import PlayerIcon from '../../player/widgets/player.icon';
import useGame from '../../../hooks/useGame';
import useGamePermissions from '../../../hooks/useGamePermissions';
import PlayerStore from '../../../stores/playerStore';
import EventContext from '../../event/event.context';
import AuthorizedContext from '../../../auth/authorized.context';
import { AuthStore, User } from '@vaettyr/boltcave-auth-client';
import { modalService } from '@vaettyr/boltcave-client-core';
import AddPlayerModal from '../../player/modals/player.modal.add';
import LeavePlayerModal from '../../player/modals/player.modal.leave';
import SwitchGameModal from '../modals/game.switch';
import PlayerPreference from '../../player/modals/player.modal.preference';
import PlayerListView from '../../player/views/player.list.view';
import GameMenu from '../menus/game.menu';
import GameBadges from '../widgets/game.badges';
import GameStatusIcon from '../widgets/game.status.icon';

type GameDetailProps = {
  authstore?: AuthStore
  game: Game,
  modalservice?: modalService
  playerstore?: PlayerStore,
  navigate: ((i:number) => void)
}

export default inject('authstore', 'modalservice', 'playerstore') ( observer (
  ({ authstore, game, modalservice, playerstore, navigate }: GameDetailProps) => {
    const { user } = authstore ?? {};
    const { host, players = [] } = playerstore?.games[game.id as number] ?? {};
    const { event, self } = useContext(EventContext);
    const authorizedAction = useContext(AuthorizedContext);
    const details = useGame({ event, game, players, user: user as User });
    const { playerCap, playerStatus: { committed } } = details;
    const {
      inGame,
      canJoinGame,
      canJoinWaitlist,
      canSwitchGame,
      canLeaveGame,
      canLeaveWaitlist,
      canAddPlayer,
      canUpdatePreferences
    } = useMemo(() => useGamePermissions({ authstore, game, event, self, isFull: committed >= playerCap }), [event?.id, game?.id, self?.id, self?.game?.id, self?.waitlist?.length]);
    const { isFlex, metadata: { level, pregen, premade, type, flat } = {} } = game;
    const gameIsFull = committed >= playerCap;

    const playerObject = { ...self, user: user?.id };

    const joinGame = () => authorizedAction({ action:"join", event: event as Event, game }, <p>You must be logged in to join a game</p>);
    const switchToGame = () => {
      if(!!self?.host && !self.host.isFlex) {
        modalservice?.show({
          body: <SwitchGameModal
              game={game as Game}
              event={event as Event}
          />,
          options:{className:"switch-modal"},
          key:"switch-modal",
        });
      } else {
        authorizedAction({ action:"join", event: event as Event, game, player: playerObject }, <p>You must be logged in to join a game</p>);
      }
    }

    const updatePreferences = () => {
      const onWaitlist = self?.waitlist?.includes(game.id ?? 0);
      modalservice?.show({
        body: <PlayerPreference game={game as Game} event={event as Event} player={self} waitlist={onWaitlist} />,
        options:{ className: "player-preference" },
        key:'player-preference'
      });
    }

    const leave = () => modalservice?.show({
      body: <LeavePlayerModal
          game={game as Game}
          event={event as Event}
          player={self as Player}
          isSelf
      />,
      options:{className:"player-modal"},
      key:"player-modal",
    });
    const joinWaitlist = () => authorizedAction({ action:"waitlist", event: event as Event, game, player: playerObject }, <p>You must be logged in to join a game's waitlist</p>);
    const addPlayer = (toWaitlist?: boolean) => () => modalservice?.show({
      body: <AddPlayerModal waitlist={gameIsFull || toWaitlist} game={game} event={event as Event}/>,
      options:{className:"player-modal"},
      key:"player-modal",
    });

    let campaignType: ReactNode = null;
    switch(type) {
        case "West Marches":
          campaignType = <li>This is a West Marches campaign (long-running, drop-in, drop-out)</li>
          break;
        case "One-shot":
          campaignType = <li>This is a one-shot game</li>
          break;
        case "Limited Series":
          campaignType = <li>This is a limited series campaign</li>
          break;
        case "Private":
          campaignType = <li>This is a private campaign</li>
          break;
    }

    const isPrivate = game.metadata?.type?.toLowerCase() === 'private';

    return (
      <GameContext.Provider value={{game, players, host, self, details}}>
        <div className="game-detail-view">
          <div className="game-header">
            <span className="icon menu-nav" onClick={() => navigate(-1)}><i className="fa-solid fa-angle-left"></i></span>
            <SystemIcon systems={ game.systems as System[] }>
              <GameStatusIcon />
              {!flat && <span className="host-info">
                Hosted By:
                <PlayerIcon player={ host } large showHistory/>
              </span>}
              <GameBadges game={game} showFlat className="header"/>
            </SystemIcon>
            <GameMenu/>
            <span className="icon menu-nav" onClick={() => navigate(1)}><i className="fa-solid fa-angle-right"></i></span>
          </div>
          <div className="badges-detail"><GameBadges game={game} showFlat className="details-bar"/></div>
          <h4 className="title is-4 game-title">{game.title}</h4>
          <div className="game-description markdown"><ReactMarkdown>{game.description ?? ""}</ReactMarkdown></div>
          <ul className="game-details">
            <li>{ game.minPlayers && `Minimum Players: ${game.minPlayers}, `}Maximum Players {playerCap}</li>
            { !!flat && <li>This is a GM-less game</li>}
            { !!isFlex && <li>This is a flex game: It will only be run if other games are full</li> }
            { committed >= playerCap && <li>This game is full. You may sign up for the waitlist if a seat becomes available</li>}
            { campaignType }
            { !!level && <li>For level {level} characters</li> }
            { !!pregen && <li>Pre-generated characters are provided</li> }
            { !!premade && <li>Home-made characters allowed (with approval)</li> }
          </ul>
          {(game.systems?.length ?? 0) <= 1 && (
            <PlayerListView/>
          )}
          {(game.systems?.length ?? 0) > 1 && (
            <div className="players-by-system">
              {game.systems?.map((system, systemIndex) => (
                <div className="player-system-list" key={`players-system-${systemIndex}`}>
                  <SystemIcon systems={[system as System]}/>
                  <PlayerListView system={system as System}/>
                </div>
              ))}
            </div>
          )}
          <div className="actions">
            { canJoinGame && <button className="button is-primary" onClick={joinGame}>Join Game</button> }
            { canUpdatePreferences && <button className="button is-primary" onClick={updatePreferences}>Update Preferences</button>}
            { canLeaveGame && <button className="button is-primary" onClick={leave}>Leave Game</button> }
            { canSwitchGame&& <button className="button is-primary" onClick={switchToGame}>Switch to Game</button> }
            { canJoinWaitlist && <button className="button is-primary is-outlined" onClick={joinWaitlist}>{isPrivate ? "Request to Join" : `${inGame ? "Move to" : "Join" } Waitlist`}</button> }
            { canLeaveWaitlist && <button className="button is-primary is-outlined" onClick={leave}>{isPrivate ? "Withdraw Request" : "Leave Waitlist"}</button> }
            { canAddPlayer && <button className="button is-primary is-outlined" onClick={addPlayer()}>Add a Friend</button> }
            { canAddPlayer && <button className="button is-primary is-outlined" onClick={addPlayer(true)}>Add a Friend to Waitlist</button> }
          </div>
        </div>
      </GameContext.Provider>
    );
  }
));

import { Card, modalService, toastService } from "@vaettyr/boltcave-client-core";
import { inject, observer } from 'mobx-react';
import { Prize } from '../../../type';
import { revertPrizeToasts } from "../../../utility/toast.utility";
import PrizeStore from "../../../stores/prizeStore";

type PrizeDeleteProps = {
  prizestore?: PrizeStore,
  prize: Prize,
  modalservice?: modalService,
  toastservice?: toastService
}

export const modalName = "prize-revert";

export default inject('prizestore', 'modalservice', 'toastservice') ( observer (
  ({prize, prizestore, modalservice, toastservice }: PrizeDeleteProps ) => {

    const onReclaim = () => {
      const { success, failure } = revertPrizeToasts(toastservice, prize.name);
      prizestore?.revert(prize)
        .then(() => {
            modalservice?.hide(modalName);
            success();
        })
        .catch(failure);
    }

    const onCancel = () => modalservice?.hide(modalName);

    const actions = (
      <>
        <button type="button" className="card-footer-item button is-primary" onClick={ onReclaim }>Yes</button>
        <button className="card-footer-item button" onClick={onCancel}>No</button>
      </>
    )

    return (
      <Card header={`Revert Prize`} footer={actions}>
        <p className="is-bold" key="bid-name">Are you sure you want to Revert this prize?</p>
        <p key="bid-warning">This will unlock all placed bids and make the prize available for future bids and re-awarding.</p>
      </Card>
    )
  }
));
import { observer, inject } from 'mobx-react';
import { modalService, Table, Tag, TextInput } from '@vaettyr/boltcave-client-core';
import SystemStore from '../../stores/systemStore';
import EditSystem from '../../components/system/modals/system.edit';
import { System } from '../../type';
import { Secured } from '@vaettyr/boltcave-auth-client';
import SystemAction from '../../components/system/system.action';
import { Formik, Form } from 'formik';
import { useState } from 'react';

type SystemsProps = {
    modalservice?: modalService
    systemstore?: SystemStore
}

export default inject('modalservice', 'systemstore')
(observer(({ modalservice, systemstore }:SystemsProps) => {

    const [filterText, setFilterText] = useState<string|undefined>(undefined);

    const columns = [
        { name: "name", label: "Name", format: (item:System) => (
        [
            <span key={`system-${item.id}`}>{item.name}</span>,
            item.userSubmitted ? <span className="icon" key={`is-new-${item.id}`}><i className="fa-solid fa-bell"></i></span> : null
        ]) },
        { name: "shortName", label: "Short Name" },
        { name:"aliases", label: "Aliases", format:(item:System) => {
            return item.aliases?.map((alias, i) => <Tag value={alias} key={`system-${item?.id}-alias-${i}`}/> );
        }},
        { name:"", format: (item:System) => <SystemAction item={item} /> }
    ];

    const AddSystem = () => {
        modalservice?.show({
            body: <EditSystem />,
            key: "edit-system",
            options: { className: "edit-system" }
        });
    }

    const { activeSystems: systems = [], busy } = systemstore ?? {};
    const sortedSystems = systems.slice().sort((a,b) =>{
        if(a.userSubmitted && !b.userSubmitted) return -1;
        if(b.userSubmitted && !a.userSubmitted) return 1;
        return (a.name?.toLowerCase() ?? '') < (b.name?.toLowerCase() ?? '') ? -1 : 1;
    }).filter((sys) => !filterText ||
        sys.name?.toLowerCase().includes(filterText?.toLowerCase()) ||
        sys.shortName?.toLowerCase().includes(filterText?.toLowerCase()) ||
        sys.aliases?.some((a) => a.toLowerCase().includes(filterText?.toLowerCase()))
    );

    const clearFilter = !!filterText && <i className="fa-solid fa-delete-left clickable" onClick={() => setFilterText(undefined)}></i>;

    return (
        <Secured claims={{key:"BOOLEAN.SystemService.CanEditSystems", value:true}}>
            <div className="systems">
                {busy && (<progress className="progress is-small is-primary" max="100">15%</progress>)}
                <h3 className="title is-3">Systems</h3>
                <Formik initialValues={{filter:''}} onSubmit={({filter}) => setFilterText(filter)}>
                    {() => (
                        <Form>
                            <TextInput
                                name="filter"
                                placeholder="filter systems"
                                onChange={(input) => setFilterText(input)}
                                icons={{right: clearFilter}}
                            />
                        </Form>
                    )}
                </Formik>
                <Table
                    className="systems-table"
                    columns={columns}
                    data={ sortedSystems } />
                <button className="button is-primary" role="button" onClick={AddSystem}>Add System</button>
            </div>
        </Secured>
    );
}));
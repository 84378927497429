import { Player } from '../../../type';

type SortDelegate = (a: Player, b:Player) => -1 | 1 | 0;

const sortByHas = (a:any, b:any) => {
  if (!a && !!b ) return -1;
  if (!!a && !b ) return 1;
  return 0;
}

export const sort = {
  checkIn: ({checkedIn: chA}: Player, {checkedIn: chB}: Player) => sortByHas(chA, chB),
  game: ({game: gA}: Player, {game: gB}: Player) => sortByHas(gA, gB),
  host: ({host: hostA}: Player, {host: hostB}: Player) => sortByHas(hostA, hostB),
  name: ({ user: a}: Player, { user: b}: Player) => {
    const aFil = a?.LastName ?? a?.Username ?? a?.FirstName ?? '';
    const bFil = b?.LastName ?? b?.Username ?? b?.FirstName ?? '';
    return aFil === bFil ? 0 : aFil > bFil ? -1 : 1;
  }
}

export const ChainSort = (...args: SortDelegate[]) => (a: Player, b:Player) => {
  let order: number = 0;
  let pos: number = 0;

  while(order === 0 && pos < (args.length - 1)) {
    order = args[0](a, b);
    pos++;
  }

  return order;
}

export const ChainFilter = (...args: ((player:Player) => boolean)[]) => {
  return (player:Player) => args.reduce((matches, f) => matches && f(player), true);
}

export const filter = {
  host: ({host}: Player) => !!host,
  user: ({user}:Player) => !!user
}
import { useEffect, useState } from 'react';
import PlayerStore from '../../../stores/playerStore';
import TokenStore from '../../../stores/tokenStore';
import { inject, observer } from 'mobx-react';
import { AuthStore, User } from '@vaettyr/boltcave-auth-client';
import PlayerIcon from '../widgets/player.icon';
import { Card, modalService } from '@vaettyr/boltcave-client-core';
import usePlayerName from '../../../hooks/usePlayerName';
import PlayerHistoryItem from '../widgets/player.history.item';
import { Player } from '../../../type';
import { fromString } from '../../../utility/date.utility';
import TokenItemView from '../../token/token.item.view';
import EditToken, { modalName as tokenModalName } from '../../token/modals/token.edit.view';
import TokenCoinView from '../../token/widgets/token.coin.view';

type PlayerHistoryViewProps = {
  authstore?: AuthStore,
  isModal?: boolean,
  user: User,
  player: Player,
  modalservice?: modalService,
  playerstore?: PlayerStore,
  showTokens?: boolean,
  tokenstore?: TokenStore
}

export const modalName = 'player-history';

// TODO: split this up into separate components. Will be used for general player info

export default inject('authstore', 'modalservice', 'playerstore', 'tokenstore') ( observer (
  ({ authstore, isModal = false, user, modalservice, playerstore, player, showTokens, tokenstore }: PlayerHistoryViewProps) => {

    const { user: self } = authstore ?? {};
    const canEditTokens = authstore?.hasPermission("BOOLEAN.TokenService.CanEditTokens", true);
    const canSeeTokens = showTokens && ((self?.id === user.id) || canEditTokens);

    const { details = {} } = playerstore ?? {};
    const { tokens = [], count: tokenCount = 0 } = tokenstore ?? {};
    const userId = user.id?.toString() ?? "0";

    const playerName = usePlayerName(player, user);

    const [activeTab, setActiveTab] = useState<"history"|"tokens">("history");
    const [tokenPage, setTokenPage] = useState<number>(0);

    useEffect(() => {
      playerstore?.history(user);
      if (canSeeTokens) {
        tokenstore?.get({ type: "user", id: user.id ?? 0 }, { size: 10, offset: 0 });
      }
    }, [userId]);

    const { history = [], count: historyCount = 0 } = Object.keys(details).includes(userId) ? details[userId] : {};
    const canPageHistory = historyCount > history.length;
    const canPageTokens = tokenCount > tokens.length;

    const pageHistory = () => {
      playerstore?.history(user);
    }

    const pageTokens = () => {
      tokenstore?.get({ type: 'user', id: user.id ?? 0 }, { size: 10, offset: tokenPage + 1})
        .then(() => setTokenPage(tokenPage + 1));
    }

    const addToken = () => {
      modalservice?.show({
        body: <EditToken user={user} isModal />,
        key: tokenModalName,
        options: { className: tokenModalName }
      });
    }

    const cancel = () => modalservice?.hide(modalName);
    const userCreated = user.created ? fromString(user.created as string) : undefined;

    const gameHistory = (
      <div>
        <div className="player-games-list">
          { history.map((item, index) => <PlayerHistoryItem item={item} key={`player-${userId}-${index}`} /> ) }
        </div>
        { canPageHistory && <button className="button is-primary" onClick={pageHistory}>See More</button>}
      </div>
    );

    const tokenHistory = canSeeTokens && (
      <div>
        <div className="player-tokens-list">
          { tokens.map((token) => <TokenItemView token={token} key={`token-${token.id}`} date description sign/>)}
        </div>
        { canPageTokens && <button className="button is-primary" onClick={ pageTokens }>See More</button>}
        <div className="player-tokens-summary">
          { canEditTokens && <button className="button is-primary" onClick={ addToken }>Add Token</button> }
          { !canPageTokens && (
            <div className="player-tokens-balance">
              Balance: <TokenCoinView amount={tokens.reduce((t, s) => t + (s.amount ?? 0), 0)}/>
            </div>
          )}
        </div>
      </div>
    )

    const contents = (
      <div className="player-history">
        <div className="user-info">
          <PlayerIcon player={{ user }} hide={['name', 'status']} />
          <div>
            <span className="user-name">{playerName}</span>
            { !!userCreated && <span className="member-since">Joined {userCreated?.toLocaleDateString('en-US')}</span> }
          </div>
          <span>{ historyCount } game{historyCount > 1 ? 's':''} played</span>
        </div>
        {!canSeeTokens && gameHistory}
        {canSeeTokens && (
          <>
            <div className="tabs is-centered is-large">
              <ul>
                <li className={`${activeTab === 'history' ? 'is-active' : ''}`}>
                  <a onClick={() => setActiveTab('history')}>History</a>
                </li>
                <li className={`${activeTab === 'tokens' ? 'is-active' : ''}`}>
                  <a onClick={() => setActiveTab('tokens')}>Tokens</a>
                </li>
              </ul>
            </div>
            <div className="tab-window">
              <section className={`${activeTab === 'history' ? 'is-active' : ''}`}>
                {gameHistory}
              </section>
              <section className={`${activeTab === 'tokens' ? 'is-active' : ''}`}>
                {tokenHistory}
              </section>
            </div>
          </>
        )}
      </div>
    );

    return !isModal ? contents : (
      <Card header="User Info" footer={<button className="card-footer-item button" onClick={cancel}>OK</button>}>
        {contents}
      </Card>
    );
  }
));
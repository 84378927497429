import { observer, inject } from 'mobx-react';
import { Formik, Form } from 'formik';
import { Card, TextInput, toastService, modalService } from '@vaettyr/boltcave-client-core';
import { AuthStore, authHelpers } from '@vaettyr/boltcave-auth-client';
import LocationStore from '../../../stores/locationStore';
import { useEffect } from 'react';
import { Location } from '../../../type';

type EditLocationProps = {
    authstore?:AuthStore,
    toastservice?:toastService,
    modalservice?:modalService,
    item?: Location,
    locationstore?: LocationStore
}

const modalName = 'edit-location';

export default inject( 'locationstore', 'toastservice', 'modalservice','authstore' )
    ( observer(({ toastservice, modalservice, authstore, locationstore, item }: EditLocationProps ) => {

    useEffect(() => {
      locationstore?.init();
    }, []);

    const update = !!item;

    function saveLocation( values:Location ):void {
        locationstore?.save({ id: item?.id, ...values })
            .then(() => {
                modalservice?.hide(modalName);
            })
            .catch( err => {
                authHelpers.HandleSecureError(err, toastservice, authstore);
            });
    }

    function onCancel() {
        modalservice?.hide(modalName);
    }

    const disabled = locationstore?.busy;

    const initialValues: Location = item ?? {
        name: '',
        description: '',
        address: { line1: '', line2: '', city: '', state: '', zip: '' },
        geo: { lat: 0, long: 0 }
    }

    return (
        <Formik initialValues={ initialValues } onSubmit={ saveLocation }>
            {({dirty, isValid, handleSubmit}) => {

                const actions = (
                    <>
                        <button type="button" className="card-footer-item button is-primary" disabled={!dirty||!isValid||disabled} onClick={()=>{handleSubmit()}}>Save</button>
                        <button className="card-footer-item button" onClick={onCancel}>Cancel</button>
                    </>
                );

                return (
                    <Card header={update ? "Edit Location" : "Create New Location"} footer={actions}>
                        <Form className="edit-location">
                            { locationstore?.busy && (<progress className="progress is-primary is-medium" max="100">15%</progress>)}
                            <div className="game-details">
                                <TextInput name='name' label="Name" disabled={disabled} required />
                                <h6 className="subtitle is-6">Address</h6>
                                <TextInput name="address.line1" label="Line 1" disabled={disabled} />
                                <TextInput name="address.line2" label="Line 2" disabled={disabled} />
                                <TextInput name="address.city" label="City" disabled={disabled} />
                                <TextInput name="address.state" label="State" disabled={disabled} />
                                <TextInput name="address.zip" label="Zip Code" disabled={disabled}/>
                            </div>
                        </Form>
                    </Card>
                );
            } }
        </Formik>
    );
}));
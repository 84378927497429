import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import EventStore from '../stores/eventStore';
import { Event, EventType } from '../type';
import GameStore from '../stores/gameStore';
import PrizeStore from '../stores/prizeStore';
import PlayerStore from '../stores/playerStore';
import { toISO } from '../utility/date.utility';
import ContentStore from '../stores/contentStore';

type UseEventArgs = {
  contentstore?: ContentStore
  date?: Date,
  eventstore?: EventStore,
  gamestore?: GameStore,
  prizestore?: PrizeStore,
  playerstore?: PlayerStore,
  type?: EventType
}

export default ({contentstore, date, eventstore, gamestore, prizestore, playerstore, type}:UseEventArgs): Event[] => {
  const { eventName } = useParams();
  const { busy } = playerstore ?? {};

  const [delayed, setDelayed] = useState<Event[]|null>(null);
  const [lastFetched, setLastFetched] = useState<{ eventName?:string, date?:Date }|null>(null);

  const getData = ( mode?: "date"|"next" ) => {
    setLastFetched({eventName, date});
    switch(mode) {
      case "date":
        eventstore?.getByDate({date: date as Date, name:eventName, type})
        .then((response) => {
          if(type === 'game night') {
            gamestore?.fetch(response);
          } else if( type === 'dm workshop') {
            const parents = response.map((e) => e.id ?? 0);
            contentstore?.fetch({ parent: 'event', type: 'topic', id: parents });
          }
          getPlayers(response);
        });
        break;
      case "next":
        eventstore?.getNext({name: eventName ?? "none", type})
        .then((response) => {
          gamestore?.fetch(response);
          getPlayers(response);
        });
        break;
    }
  }

  const getPlayers = (response: Event[]) => {
    if(playerstore?.busy) {
      setDelayed(response);
    } else {
      playerstore?.fetch(response);
    }
  }

  useEffect(() => {
    if(!busy && delayed) {
      getPlayers(delayed);
      setDelayed(null);
    }
  }, [busy, delayed]);

  useEffect(() => {
    if(!lastFetched || lastFetched.eventName !== eventName || toISO(lastFetched.date) !== toISO(date)) {
      if((eventName || type === 'dm workshop') && date) {
        getData("date");
      } else {
        getData("next");
      }
    }
  }, [eventName, date, lastFetched]);

  const events = eventstore?.onDate(date, eventName) ?? [];

  return events
}
import { inject, observer } from 'mobx-react';
import { Secured } from '@vaettyr/boltcave-auth-client';
import { modalService, Checkbox, TextInput } from '@vaettyr/boltcave-client-core';
import PrizeStore from '../../stores/prizeStore';
import PrizePod from '../../components/prize/prize.pod.view';
import EditPrize, { modalName } from '../../components/prize/modals/prize.modal.edit';
import { useEffect } from 'react';
import { Formik } from 'formik';

type PrizePageProps = {
    modalservice?: modalService;
    prizestore?: PrizeStore;
}

export default inject('modalservice', 'prizestore') ( observer (({ modalservice, prizestore }: PrizePageProps) => {

    const { prizes = [], page, canPage = false } = prizestore ?? {};

    const addPrize = () => {
        modalservice?.show({
            body: <EditPrize />,
            options: { className: 'edit-prize' },
            key: modalName
        });
    }

    useEffect(() => {
        prizestore?.get({ active: true, withBids: true }, { size: 8, offset: 0 });
    }, []);

    const onSearch = ({ won, name }: { won: boolean, name: string}) => {
        prizestore?.get({ active: !won, withBids: true, name }, { size: 8, offset: 0 });
    }

    return (
        <div className="prizes">
            <Secured claims={[{key: "ENUM.PrizeService.CanEditPrizes", value: "ALL"}, {key: "ENUM.PrizeService.CanEditPrizes", value: "OWN"}]}>
                <button className="button is-info add-prize" onClick={addPrize}>Add Prize</button>
            </Secured>
            <div className="prize-search">
                <Formik initialValues={{ won: false, name: '' }} onSubmit={onSearch}>
                    {({ handleSubmit, setFieldValue, values }) => {
                        const { won } = values;
                        return (
                            <>
                                <TextInput
                                    name="name" placeholder="Search Prizes"
                                    icons={{
                                        left: <span className="icon clickable has-tooltip-bottom" onClick={() => {setFieldValue("won", !won)}} data-tooltip="Show Inactive Prizes">
                                            <i className={`fa-${won ? 'solid' : 'regular'} fa-${won ? 'square-check' : 'square'}`}></i>
                                        </span>,
                                        right: <span className="icon clickable has-tooltip-bottom" onClick={() => handleSubmit()} data-tooltip="Search">
                                            <i className="fa-solid fa-magnifying-glass"></i>
                                        </span>
                                    }}/>
                            </>
                        )
                    }}
                </Formik>
            </div>
            <div className="prize-gallery">
                { prizes.map((prize) => <PrizePod prize={prize} key={`prize-${prize.id ?? 0}`} />)}
                { canPage && <button className="button is-primary load-more" onClick={page}>Load More</button>}
            </div>
        </div>
    );
}));
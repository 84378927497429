import { inject, observer } from 'mobx-react';
import { useContext, useMemo } from 'react';
import GameContext from '../game.context';
import EventContext from '../../event/event.context';
import GameStore from '../../../stores/gameStore';
import useMenu from '../../../hooks/useMenu';
import useGamePermissions from '../../../hooks/useGamePermissions';
import usePlayerPermissions from '../../../hooks/usePlayerPermissions';
import { modalService, toastService } from '@vaettyr/boltcave-client-core';
import { AuthStore } from '@vaettyr/boltcave-auth-client';
import EditGame from '../modals/game.edit';
import DeleteGame from '../modals/game.delete';
import PlayerStore from '../../../stores/playerStore';
import { checkinToasts, flexToasts } from '../../../utility/toast.utility';

type GameMenuProps = {
  authstore?: AuthStore,
  gamestore?: GameStore,
  modalservice?: modalService,
  playerstore?: PlayerStore,
  toastservice?: toastService
}

export default inject('authstore', 'gamestore', 'modalservice', 'playerstore', 'toastservice') (observer (
({ authstore, gamestore, modalservice, playerstore, toastservice }: GameMenuProps) => {
    const { game = {}, host = {} } = useContext(GameContext);
    const { event = {}, self } = useContext(EventContext);
    const { isActive, open, close } = useMenu();
    const { canEditGame } = useMemo(() => useGamePermissions({ authstore, game, event, self }), [event?.id, game?.id, self?.id]);
    const { canCheckPlayerIn } = useMemo(() => usePlayerPermissions({ authstore, game, player: host, self}), [authstore?.user?.id, event?.id, game?.id, self?.id, host?.id]);
    const { isFlex } = game;
    const { checkedIn } = host;

    const menuClick = (e?:React.MouseEvent) => {
      e?.preventDefault();
      e?.stopPropagation();
      if(isActive) {
        close(e?.nativeEvent);
      } else {
        open(e?.nativeEvent);
      }
    }

    const toggleFlex = (e:React.MouseEvent) => {
      e?.preventDefault();
      e?.stopPropagation();
      const { success, failure } = flexToasts(toastservice, game.title, !isFlex);
      gamestore?.save({ ...game, isFlex: !isFlex, host: host?.user })
      .then(({host: flexHost}) => {
        playerstore?.add(flexHost);
        success();
      }).catch(failure).finally(() => close(e?.nativeEvent));
    }

    const toggleCheckin = (e:React.MouseEvent) => {
      e?.preventDefault();
      e?.stopPropagation();
      const { success, failure } = checkinToasts(toastservice, 'host', checkedIn);
      playerstore?.checkin(event, game, host, checkedIn)
      .then(success).catch(failure).finally(() => close(e?.nativeEvent));
    }

    const deleteGame = (e:React.MouseEvent) => {
      e?.preventDefault();
      e?.stopPropagation();
      modalservice?.show({
          body: <DeleteGame game={game} />,
          key: 'game-delete',
          options: { className: 'game-delete'}
      });
      close(e?.nativeEvent);
    }

    const editGame = (e:React.MouseEvent) => {
      e?.preventDefault();
      e?.stopPropagation();
      modalservice?.show({
          body: <EditGame isModal game={game} host={host} />,
          options:{className:"edit-game"},
          key:"edit-game"
      });
      close(e?.nativeEvent);
    }

    if(!canEditGame) return <span></span>;

    return (
      <span className={`dropdown clickable is-right${isActive ? ' is-active' : ''}`}>
          <span className="icon is-large is-primary dropdown-trigger" onClick={menuClick}>
            <i className="fa-solid fa-ellipsis-vertical"></i>
          </span>
          <div className="dropdown-menu">
            <div className="dropdown-content">
                <div className="dropdown-item clickable" onClick={toggleFlex}>Set {isFlex ? 'Active' : 'Flex'}</div>
                { canCheckPlayerIn && <div className="dropdown-item clickable" onClick={toggleCheckin}>Check Host {checkedIn ? 'Out' : 'In'}</div> }
                <div className="dropdown-item clickable" onClick={deleteGame}>Delete</div>
                <div className="dropdown-item clickable" onClick={editGame}>Edit</div>
            </div>
        </div>
      </span>
    )
} ));
import { inject, observer } from 'mobx-react';
import useMenu from '../../../hooks/useMenu';
import { Event, Prize } from '../../../type';
import { modalService } from '@vaettyr/boltcave-client-core';
import { AuthStore } from '@vaettyr/boltcave-auth-client';
import { MouseEvent, ReactNode } from 'react';
import EditPrize, { modalName as editModalName } from '../modals/prize.modal.edit';
import DeletePrize, { modalName as deleteModalName } from '../modals/prize.modal.delete';
import AwardPrize, { modalName as awardModalName } from '../modals/prize.modal.award';
import RevertPrize, { modalName as revertModalName } from '../modals/prize.modal.revert';

type PrizeMenuProps = {
    authstore?: AuthStore,
    event?: Event,
    prize: Prize,
    modalservice?: modalService
}

export default inject('authstore', 'modalservice') ( observer(
    ({ authstore, event, prize, modalservice }: PrizeMenuProps) => {
        const { isActive, menuClick, close } = useMenu();

        const showModal = (modal: 'edit'|'delete'|'award'|'reclaim'|'copy') => (e?: MouseEvent) => {
            let Modal: ReactNode;
            let modalName: string;
            switch(modal) {
                case 'edit':
                    Modal = <EditPrize prize={prize} />;
                    modalName = editModalName;
                    break;
                case 'copy':
                    const { id, ...rest } = prize;
                    Modal = <EditPrize prize={rest} />;
                    modalName = editModalName;
                    break;
                case 'award':
                    Modal = <AwardPrize prize={prize} event={event} />;
                    modalName = awardModalName;
                   break;
                case 'reclaim':
                    Modal = <RevertPrize prize={prize} />;
                    modalName = revertModalName;
                    break;
                case 'delete':
                default:
                    Modal = <DeletePrize prize={prize} />;
                    modalName = deleteModalName;
            }
            modalservice?.show({
                body: Modal,
                key: modalName,
                options: { className: modalName }
            });
            close(e?.nativeEvent);
        }

        const canAward = authstore?.hasPermission('BOOLEAN.PrizeService.CanAwardPrizes', true);

        return (
            <span className={`prize-menu dropdown is-right${isActive ? ' is-active' : ''}`}>
                <span className="icon is-large is-primary dropdown-trigger clickable" onClick={menuClick}>
                    <i className="fa-solid fa-ellipsis-vertical"></i>
                </span>
                <div className="dropdown-menu">
                    <div className="dropdown-content">
                        { !prize.wonBy && canAward && <div className="dropdown-item clickable" onClick={showModal('award')}>Award</div> }
                        { prize.wonBy && canAward && <div className="dropdown-item clickable" onClick={showModal('reclaim')}>Retract Award</div>}
                        <div className="dropdown-item clickable" onClick={showModal('edit')}>Edit</div>
                        <div className="dropdown-item clickable" onClick={showModal('copy')}>Copy</div>
                        <div className="dropdown-item clickable" onClick={showModal('delete')}>Delete</div>
                    </div>
                </div>
            </span>
        );
    }
));
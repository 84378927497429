import { CollapsibleGroup, modalService } from '@vaettyr/boltcave-client-core';
import { AuthStore } from '@vaettyr/boltcave-auth-client';
import { Content } from '../../type';
import ContentView from './content.view';
import { inject, observer } from 'mobx-react';
import EditContent from './content.edit.view';
import { modalName } from './content.edit.controller';
import { Fragment } from 'react';

type ContentGroupProps = {
  authstore?: AuthStore,
  contents: Content[],
  className?: string,
  modalservice?: modalService
}

export default inject('authstore', 'modalservice') ( observer( ({ authstore, contents, className, modalservice }: ContentGroupProps) => {
  const types = contents.reduce<string[]>((alltypes, c) => alltypes.includes(c.metadata?.display ?? "") ? alltypes: [...alltypes, c.metadata?.display ?? ""], []);

  const canEdit = authstore?.hasPermission("ENUM.ContentService.CanEditContent", "ALL");

  const showEdit = (type: "faq"|"link"|"about") => () => modalservice?.show({
    body: <EditContent type={type} />,
    key: modalName,
    options: { className: modalName }
  });

  return <>
    {
      types.map((type, index) => {
        const contentOfType = contents.filter((c) => c.metadata?.display === type || (type === '' && !c.metadata?.display) );
        const isSingle = contentOfType.length === 1 && (contentOfType[0].metadata?.single ?? false);
        const contentTypes = contents.map((c) => c.type).filter((c, ci, set) => !!c && set.indexOf(c) === ci) as string[];
        const contentType = contentTypes.length > 0 ? contentTypes[0] : "about";
        const editButton = !!canEdit && !isSingle && <button className="button is-primary" onClick={showEdit(contentType as "faq"|"link"|"about")}>{`Add ${contentType}`}</button>

        switch(type.toLowerCase()) {
          case "accordion":
            return (<Fragment key={`content-${type}-${index}`}>
              <CollapsibleGroup>
                {contentOfType.map((c,i) => <ContentView content={c} key={`content-${type}-${index}-${i}`} className={className} />)}
              </CollapsibleGroup>
              {editButton}
            </Fragment>);
          default:
            return (<Fragment key={`content-${type}-${index}`}>
              {contentOfType.map((c,i) => <ContentView content={c} key={`content-${type}-${index}-${i}`} className={className} />)}
              {editButton}
            </Fragment>);
        }
      })
    }
  </>;
} ));
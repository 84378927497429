import { inject, observer } from 'mobx-react';
import { HistoryGame } from '../../../stores/playerStore';
import SystemStore from '../../../stores/systemStore';
import LocationStore from '../../../stores/locationStore';
import { Event, System } from '../../../type';
import SystemIcon from '../../system/widgets/system.icon.view';

type PlayerHistoryProps = {
  systemstore?: SystemStore,
  locationstore?: LocationStore,
  item: HistoryGame
}

export default inject ('systemstore', 'locationstore') ( observer (
  ({ item: { event, player, self, systems: sys, title }, systemstore, locationstore }: PlayerHistoryProps) => {
    const { locations } = locationstore ?? {};
    const { eventDate, location } = (event as Event);
    const date = eventDate?.toLocaleDateString('en-US')
    const { host } = player;
    const shared = !!self?.id;
    const { systems = [] } = systemstore ?? {};
    const mappedSystems = sys?.map((system) => systems.find((s) => s.id === (system as number))).filter((c) => !!c) as System[];
    const locationName = locations?.find((l) => l.id === location?.id)?.name;

    return (
      <div className="player-history-game">
        <div className="event-info">
          <span className="event-date">{ date }</span>
          <span className="event-location">{ locationName } </span>
        </div>
        <div className="game-info">
          <SystemIcon systems={mappedSystems} mode='short' />
          <span className="game-title">{title}</span>
          <span className="tags">
            { !!host && <span className="icon tag is-rounded" key="shared-host" data-tooltip="Was host of"><i className="fa-solid fa-crown" key="host-badge"></i></span> }
            { !!shared && <span className="icon tag is-rounded" key="shared-badge" data-tooltip="You also played in"><i className="fa-solid fa-people-arrows"></i></span> }
          </span>
        </div>
      </div>
    );
  }
))
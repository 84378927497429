import { User } from '@vaettyr/boltcave-auth-client';
import { Event, Game, Player } from '../type';

type useGameProps = {
  game:Game,
  event?: Event,
  players?: Player[],
  user?: User
}

export type useGameResponse = {
  totalSlots: number,
  playerCap: number,
  playerStatus: {
    committed: number,
    waitlisted: number,
    confirmed: number
  },
  canEdit: boolean,
  canEditAll: boolean
}

export default ({event, game, players, user }: useGameProps): useGameResponse => {
  const playerCap = event?.playersPerTable ?
    ( event.playersPerTable < ( game.maxPlayers ?? event.playersPerTable ) ? event.playersPerTable : game.maxPlayers ?? 5 ) :
    game.maxPlayers ?? 5;

  const host = players?.find((player) => player.host?.id === game.id);
  const committedPlayers = players?.filter((player) => player.game?.id === game.id && player.id !== host?.id) ?? [];
  const waitlistedPlayers = players?.filter((player) => player.waitlist?.includes(game.id ?? 0)) ?? [];
  const totalPlayers = committedPlayers.length + waitlistedPlayers.length;
  const totalSlots = totalPlayers < playerCap ? playerCap : totalPlayers;
  const confirmedPlayers = committedPlayers.filter((player) => player.checkedIn);

  const claim = user?.claims?.find((c) => c.key === "ENUM.GameService.CanEditGames")?.value;
  const canEditAll = claim === "ALL";
  const canEdit = canEditAll || ((claim === "OWN") && (game.owner?.id === user?.id || host?.id === user?.id));

  return {
    totalSlots,
    playerCap,
    playerStatus: {
      committed: committedPlayers.length,
      waitlisted: waitlistedPlayers.length,
      confirmed: confirmedPlayers.length
    },
    canEdit: canEdit ?? false,
    canEditAll
  }
}
import { observer, inject } from 'mobx-react';
import { Formik, Form } from 'formik';
import { Card, AutoComplete, TextInput, NumberInput, toastService, modalService } from '@vaettyr/boltcave-client-core';
import { AuthStore, authHelpers } from '@vaettyr/boltcave-auth-client';
import { ImageInput } from '@vaettyr/boltcave-media-client';
import SystemStore from '../../../stores/systemStore';
import { Tag } from '@vaettyr/boltcave-client-core';
import { System } from '../../../type';

type EditSystemProps = {
    authstore?:AuthStore,
    toastservice?:toastService,
    modalservice?:modalService,
    item?: System,
    systemstore?: SystemStore
}

const modalName = 'edit-system';

export default inject( 'systemstore', 'toastservice', 'modalservice','authstore' )
    ( observer(({ toastservice, modalservice, authstore, systemstore, item }: EditSystemProps ) => {

    const update = !!item;

    function saveSystem( values:System ):void {
        systemstore?.save(values)
            .then(() => {
                modalservice?.hide(modalName);
            })
            .catch( err => {
                authHelpers.HandleSecureError(err, toastservice, authstore);
            });
    }

    function onCancel() {
        modalservice?.hide(modalName);
    }

    const disabled = systemstore?.busy;

    const initialValues: System = {
        id: item?.id,
        name: item?.name ?? '',
        shortName: item?.shortName ?? '',
        aliases: item?.aliases ?? [],
        icon: item?.icon,
        banner: item?.banner,
    }

    return (
        <Formik initialValues={ initialValues } onSubmit={ saveSystem }>
            {({dirty, isValid, values, handleSubmit, setFieldValue}) => {

                const { name } = values;

                const actions = (
                    <>
                        <button type="button" className="card-footer-item button is-primary" disabled={!dirty||!isValid||disabled} onClick={()=>{handleSubmit()}}>Save</button>
                        <button className="card-footer-item button" onClick={onCancel}>Cancel</button>
                    </>
                );

                return (
                    <Card header={update ? "Edit System" : "Create New System"} footer={actions}>
                        <Form className="edit-system">
                            { systemstore?.busy && (<progress className="progress is-primary is-medium" max="100">15%</progress>)}
                            <div className="system-details">
                                <TextInput name='name' label="Name" disabled={disabled} required />
                                <TextInput name='shortName' label="Short Name" disabled={disabled} />
                                <AutoComplete name='aliases' label="Aliases" autoAccept delimiter={[",", 49]} multiple template={ (alias) => <Tag value={ alias }/>} />
                                <ImageInput
                                    name='icon'
                                    showButton
                                    label="Icon"
                                    ingest
                                    fullPath
                                    // onAccept={ onSet("icon") }
                                    imageProps={{ name: `system-icon-${name ?? 'new'}`, sector: 'system', tags: ['system', 'icon'] }}
                                    previewProps={{ mode: 'crop', size: 48, rounded: true }}
                                />
                                <ImageInput
                                    name='banner'
                                    showButton
                                    label="Banner"
                                    ingest
                                    fullPath
                                    // onAccept={ onSet("banner") }
                                    imageProps={{ name: `system-banner-${name ?? 'new'}`, sector: 'system', tags: ['system', 'banner'] }}
                                    previewProps={{ mode: 'crop', ratio: '16by9' }}
                                />
                                <NumberInput name="seats" label="Max Players" min={ 0 } disabled={disabled} />
                            </div>
                        </Form>
                    </Card>
                );
            } }
        </Formik>
    );
}));
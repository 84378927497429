import { inject, observer } from 'mobx-react';
import { Event } from '../../type';
import { useNavigate } from 'react-router-dom';
import { stringHelpers, modalService } from '@vaettyr/boltcave-client-core';
import { AuthStore } from '@vaettyr/boltcave-auth-client';
import { toISO } from '../../utility/date.utility';
import EditEvent from './modals/event.edit';

type EventDateViewProps = {
  event?: Event,
  authstore?: AuthStore,
  modalservice?: modalService
}

export default inject('authstore', 'modalservice') ( observer (
({ event, authstore, modalservice }: EventDateViewProps) => {

  const navigate = useNavigate();

  const { user } = authstore ?? {};

  const canEdit = authstore?.hasPermission('ENUM.EventService.CanEditEvents', 'ALL') ||
    ( authstore?.hasPermission('ENUM.EventService.CanEditEvents', 'OWN') && event?.owner?.id === user?.id);

  const goToEvent = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    const date = toISO(event?.eventDate, 3);
    const name = stringHelpers.prettyEncodeUri(event?.name);
    if(name && event?.type === 'game night') {
      navigate(`/menu/${name}?date=${date}`);
    } else if( event?.type === 'dm workshop' ) {
      navigate(`/workshop?date=${date}`)
    } else if (canEdit && !!event) {
      modalservice?.show({
        body: <EditEvent event={event} />,
        options: { className:"edit-event" },
        key:"edit-event"
      });
    }
  }

  const title = event?.name ?? `${event?.location?.name}${event?.eventDate ? `@${event.eventDate.toLocaleTimeString('en-US',{hour:'numeric', minute:'numeric'})}`:''}`;

  return event ? (
    <>
      <span className={`event event-date${event?.cancelled ? ' cancelled':''}`} onClick={ goToEvent } key={`event-date-${event.id}`}>
        { title }
      </span>
      {event?.cancelled && (
          <span className="event cancelled-event" key={`event-date-${event.id}-cancelled`}>cancelled</span>
        )}
    </>
  ) : null;
}));
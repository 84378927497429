type TokenCoinProps = {
  amount: number;
  className?: string;
  sign?: boolean;
  tooltip?: string;
}

export default ({ amount, className = '', sign = false, tooltip }: TokenCoinProps) => {
  const style = `${className} token-coin ${amount > 0 ? 'positive' : 'negative'}`;
  const valueSign = sign && amount < 0 ? '-' : (sign ? '+' : '');
  return <span className={style} data-tooltip={tooltip}>{`${valueSign}${Math.abs(amount)}`}</span>;
};
import { observer, inject } from 'mobx-react';
import { AutoComplete } from '@vaettyr/boltcave-client-core';
import SystemStore from '../../../stores/systemStore';
import { useEffect, useState } from 'react';
import { useField } from 'formik';
import { Tag } from '@vaettyr/boltcave-client-core';
import { System } from '../../../type';

type SystemsProps = {
    systemstore?: SystemStore,
    disabled?: boolean
}

export default inject('systemstore') ( observer (
({ disabled, systemstore }: SystemsProps) => {

    const [_field, meta] = useField("systems");
    const { value = [] } = meta;

    useEffect(() => {
        if(!systemstore?.systems || ((systemstore?.systems.length ?? 0) === 0)) {
            systemstore?.init();
        }
    }, []);

    const [systemFilter, setSystemFilter] = useState<RegExp>();
    const { activeSystems: systems = [] } = systemstore ?? {};

    const filterSystems = (input:string) => {
        const tokens = input?.split(' ').map((entry) => entry?.trim()).filter((entry) => !!entry);
        const expression = tokens.length > 1 ? new RegExp(`(${tokens.join('|')})`) :
            (tokens.length > 0 ? new RegExp(tokens[0], 'i') : undefined);
        setSystemFilter(expression);
    };
    const systemSuggestions = (systemFilter ?
        systems.filter(s => systemFilter.test(s.name ?? "") || s.aliases?.some(a => systemFilter.test(a))):
        systems).filter((s) => !value || value.length === 0 || !value.some((v:System) => v.id === s.id));
    const systemTemplate = (system:System|string, mode?: "selection"|"suggestion") =>
        mode === 'suggestion' ? (system as System).name ?? "" : <Tag value={typeof(system) === 'object' ? system.name ?? "" : system}/>

    return (
        <AutoComplete
            name='systems'
            label="Game System(s)"
            required
            multiple
            delimiter={[",", 49]}
            autoAccept={true}
            disabled={disabled}
            suggestions={systemSuggestions}
            onChange={filterSystems}
            template={systemTemplate}
            className="system-picker"
        />
    )
} ));
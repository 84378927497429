import { inject, observer } from 'mobx-react';
import { useState, useEffect } from 'react';
import { AuthStore } from '@vaettyr/boltcave-auth-client';
import { EventType } from '../../type';
import ContentStore from '../../stores/contentStore';
import EventStore from '../../stores/eventStore';
import GameStore from '../../stores/gameStore';
import EventMenuView from '../../components/event/event.menu.view';
import useEvents from '../../hooks/useEvents';
import useDates from '../../hooks/useDates';
import PlayerStore from '../../stores/playerStore';
import PrizeStore from '../../stores/prizeStore';
import CheckinContext from '../../components/checkin/checkin.context';
import useCheckinKey from '../../hooks/useCheckinKey';
import WorkshopView from '../../components/event/workshop/event.workshop.view';

type MenuProps = {
  contentstore?: ContentStore,
  gamestore?: GameStore,
  prizestore?: PrizeStore,
  eventstore?: EventStore,
  authstore?: AuthStore,
  playerstore?: PlayerStore,
  type: EventType
}

export default inject('contentstore', 'gamestore', 'prizestore', 'eventstore', 'modalservice', 'playerstore', 'authstore') ( observer (
({ contentstore, eventstore, gamestore, prizestore, playerstore, type }: MenuProps) => {
  const { busy = false } = eventstore ?? {};
  const [date] = useDates('single') ?? [undefined];
  const events = useEvents({contentstore, date, eventstore, gamestore, prizestore, playerstore, type});
  const [initialized, setInitialized] = useState<boolean|null>(null);

  const checkinParams = useCheckinKey();
  useEffect(() => {
    if(initialized === null && busy) {
      setInitialized(false);
    } else if(initialized === false && !busy) {
      setInitialized(true);
    }
  }, [busy]);

  // this is getting triggered when it shouldn't
  if(!initialized) return null;

  const Component = type === 'game night' ? EventMenuView : WorkshopView;

  return events.length > 0 ? (
    <div className="menu expand-height">
      <CheckinContext.Provider value={checkinParams}>
        { events.map((event, index) => <Component event={event} key={`event-menu-${index}`} />) }
      </CheckinContext.Provider>
    </div>
  ) : (
    <div>There are no events on this date</div>
  );
}));
import { observer, inject } from 'mobx-react';
import { Card, toastService, modalService } from '@vaettyr/boltcave-client-core';
import LocationStore from '../../../stores/locationStore';
import { Location } from '../../../type';
import { deleteItemToasts } from '../../../utility/toast.utility';

type DeleteLocationProps = {
    toastservice?:toastService,
    modalservice?:modalService,
    item?: Location,
    locationstore?: LocationStore
}

const modalName = 'delete-location';

export default inject( 'locationstore', 'toastservice', 'modalservice' )
    ( observer(({ toastservice, modalservice, item, locationstore }: DeleteLocationProps ) => {

    const onDelete = ():void => {
        const { success, failure } = deleteItemToasts(toastservice, item?.name);
        locationstore?.remove(location as Location)
            .then(success).catch(failure).finally(() => modalservice?.hide(modalName));
    }

    const onCancel = () => {
        modalservice?.hide(modalName);
    }

    const actions = (
        <>
            <button type="button" className="card-footer-item button is-primary" onClick={onDelete}>Delete</button>
            <button className="card-footer-item button" onClick={onCancel}>Cancel</button>
        </>
    );

    return (
        <Card header="Delete Location" footer={actions}>
            <p>Are you sure you want to delete Location {item?.name ?? ''}</p>
        </Card>
    );
}));
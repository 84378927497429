import { Location, Address } from "../../type"

type AddressProps = {
  address?: Address,
  className?: string,
  icon?: string,
  iconStyle?: "solid" | "regular",
  item?: Location
}

export default ({ address, icon, className = "", iconStyle = "solid", item }: AddressProps) => {
  const displayAddress = address ?? item?.address;
  if(!displayAddress) return null;
  const { line1, line2, city, state, zip } = displayAddress;

  const isGarbage = (): boolean => {
    const { platform, userAgent } = navigator;
    const exp = /iPad|iPhone|iPod|Mac/i;
    return exp.test(platform) || exp.test(userAgent);
  }

  const mapSelector = () => {
    const { geo: { lat = 0, long = 0 } = {} } = item ?? {};
    const destination = !!lat && !!long ? `${lat},${long}&amp;ll=` : `${line1.replaceAll(' ', '+')},${city},${state},${zip}`;
    window.open(`${isGarbage() ? 'maps' : 'https'}://maps.google.com/maps?daddr=${destination}`)
  }

  return (
    <p className={`location-address clickable ${className}`} onClick={mapSelector}>
      <span>
        { icon && ( <span className="icon">
          <i className={`fa-${iconStyle} fa-${icon}`}></i>
        </span> ) }
        {line1}
      </span>
      { line2 && <span>{line2}</span>}
      <span>
        <span>{city},</span>
        <span>{state}</span>
        <span>{zip}</span>
      </span>
    </p>
  )
}
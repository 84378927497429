import { observer, inject } from 'mobx-react';
import { Formik, Form } from 'formik';
import { Card, TextInput, NumberInput, toastService, modalService, Checkbox, Select } from '@vaettyr/boltcave-client-core';
import { AuthStore, UserLookup, User } from '@vaettyr/boltcave-auth-client';
import GameStore from '../../../stores/gameStore';
import { Game, Event, Player, System } from '../../../type';
import PlayerStore from '../../../stores/playerStore';
import SystemPicker from '../../system/form/system.picker';
import GameTemplate from './game.template';
import MarkdownInput from '../../markdown/markdown.preview.input';
import { saveItemToasts } from '../../../utility/toast.utility';

type EditGameProps = {
    authstore?:AuthStore,
    toastservice?:toastService,
    modalservice?:modalService,
    gamestore?: GameStore,
    playerstore?: PlayerStore,
    game?: Game & { name?: string },
    host?: Player|User,
    event?: Event,
    isModal?:boolean,
    template?: number
}

const modalName = 'edit-game';

export default inject( 'gamestore', 'playerstore', 'toastservice', 'modalservice','authstore' )
    ( observer(({ toastservice, modalservice, authstore, gamestore, playerstore, game, host, event, isModal = false, template = -1 }:EditGameProps ) => {

    const update = !!game?.id;

    const saveGame = ({ systems, name, ...rest }: Game & { host?: User } & { systems?: (string|number|System)[]|undefined|string} & { name?: string } ):void => {
        if(template < 0) {
            const { success, failure } = saveItemToasts(toastservice, rest.title ?? "Game", update);
            gamestore?.save({
                ...rest,
                id: game?.id,
                event: (game?.event as Event)?.id ?? event?.id,
                systems: typeof(systems) === 'string' ? [systems] : systems
            })
                .then(({game: savedGame, host: savedHost, previous}) => {
                    success();
                    playerstore?.add(savedHost);
                    playerstore?.add(previous);
                    modalservice?.hide(modalName);
                    if(!update && !gamestore?.supressed) {
                        modalservice?.show({
                            body: <GameTemplate game={savedGame} />,
                            key: 'game-template',
                            options: { className: 'game-template' }
                        })
                    }
                })
                .catch(failure);
        } else {
            const saved = gamestore?.saveTemplate(
                name as string,
                { ...rest, systems: typeof(systems) === 'string' ? [systems] : systems},
                gamestore?.supressed,
                template);
            if(saved) modalservice?.hide(modalName);
        }

    }

    const onCancel = () => {
        modalservice?.hide(modalName);
    }

    const disabled = gamestore?.busy;

    const canAddHost = authstore?.hasPermission("ENUM.GameService.CanEditGames", "ALL") && template < 0;

    const validateTitle = (value?: string): string|undefined => {
        if(!!value && value.length > 50) return 'Title must be less than 50 characters';
    }

    const validateTemplate = (value?: string): string|undefined => {
        const newTemplate = !game?.name ?? true;
        if(!!value && Object.keys(gamestore?.templates ?? {})
            .reduce((count, key) => key === value ? count + 1 : count, 0) > (newTemplate ? 0 : 1)){
            return 'This name is already used by another template'
        };
    }

    const hostUser: User|undefined = !!(host as any)?.user ? (host as Player).user : (host as User);

    const { user } = authstore ?? {};

    const initialValues: Game & { host?: User } & { name?: string } = {
        title: game?.title ?? '',
        name: game?.name ?? '',
        description: game?.description ?? '',
        host: {
            id: hostUser?.id ?? user?.id,
            FirstName: hostUser?.FirstName ?? user?.FirstName,
            LastName: hostUser?.LastName ?? user?.LastName,
            Username: hostUser?.Username ?? user?.Username,
            Avatar: hostUser?.Avatar ?? user?.Avatar,
            Email: hostUser?.Email ?? user?.Email
        },
        systems: game?.systems ?? [],
        isFlex: game?.isFlex ?? false,
        metadata: game?.metadata ?? {},
        minPlayers: game?.minPlayers ?? 2,
        maxPlayers: game?.maxPlayers ?? 5
    }

    const eventId = event?.id ?? (typeof(game?.event) === 'number' ? game?.event : game?.event?.id);

    return (
        <Formik initialValues={ initialValues } onSubmit={saveGame}>
            {({dirty, isValid, values, handleSubmit, setFieldValue}) => {
                // fix display on the progress bar. Maybe use a different type
                 const clearHost = (flat?: boolean) => {
                    if(flat) setFieldValue("host", undefined);
                }

                const hostPlayer = (values.host && typeof(values.host) !== 'string') ? playerstore?.players.find((p) => p.user?.id === values.host?.id && p.event?.id === eventId) : undefined;
                const willClearGame = !!hostPlayer && !!hostPlayer.host && (!game?.id || hostPlayer.host.id !== game.id);
                const willMovePlayer = !willClearGame && !!hostPlayer && !!hostPlayer.game && !!hostPlayer.game.id && hostPlayer.game.id !== game?.id && !hostPlayer.game.isFlex && !values.isFlex;

                const renderForm = () => {
                    return (
                        <Form className="game-edit">
                            { gamestore?.busy && (<progress className="progress is-primary is-medium" max="100">15%</progress>)}
                            <div className="game-details">
                                { canAddHost && (
                                    <div className="control-row">
                                        <UserLookup name="host" label="Host" limited={false} disabled={disabled || !!values.metadata?.flat}/>
                                        <Checkbox name="metadata.flat" label="GM-less" disabled={disabled} onChange={clearHost}/>
                                    </div>
                                )}
                                { (willClearGame || willMovePlayer) && (
                                    <div className="has-text-warning">
                                        <span className="icon">
                                            <i className="fa-solid fa-triangle-exclamation"></i>
                                        </span>
                                        { willClearGame && 'This user is already the host of another game. If you assign them to this game, their existing game will be deleted.' }
                                        { willMovePlayer && 'This user is already in another game. If you assign them to this game, they will be removed from the game they signed up for.' }
                                    </div>
                                )}
                                { template >= 0 && (
                                    <TextInput name='name' label="Template Name" required validate={ validateTemplate } />
                                )}
                                <div className="control-row">
                                    <SystemPicker />
                                    <Checkbox name="isFlex" label={<label className="label has-tooltip-left" data-tooltip="Will only run if other games are full">Flex</label>} disabled={disabled}/>
                                </div>
                                <TextInput name='title' label="Title" disabled={disabled} validate={validateTitle} />
                                <MarkdownInput name="description" label="Description" disabled={disabled} />
                                <div className="control-row details">
                                    <NumberInput name="metadata.level" label="Character Level" min={0} disabled={disabled}/>
                                    <Checkbox name="metadata.pregen" label={<label className="label has-tooltip-left" data-tooltip="Will Pre-generated characters be provided?">Pre-gens</label>} disabled={disabled}/>
                                    <Checkbox name="metadata.premade" label={<label className="label has-tooltip-left" data-tooltip="Can players bring homemade characters?">Homemade</label>} disabled={disabled}/>
                                </div>
                                <Select name="metadata.type" label="Campaign Type" options={["", "West Marches", "One-shot", "Limited Series", "Private"]} />
                                <div className="control-row players">
                                    <NumberInput name="minPlayers" label="Min Players" min={ 1 } disabled={disabled}/>
                                    <NumberInput name="maxPlayers" label="Max Players" min={ 1 } disabled={disabled}/>
                                </div>
                                { !isModal && (
                                    <button type="submit" className="button is-primary" disabled={!dirty||!isValid||disabled}>Save</button>
                                )}
                            </div>
                        </Form>
                    );
                }

                const actions = (
                    <>
                        <button type="button" className="card-footer-item button is-primary" disabled={!dirty||!isValid||disabled} onClick={()=>{handleSubmit()}}>Save</button>
                        <button className="card-footer-item button" onClick={onCancel}>Cancel</button>
                    </>
                )

                return isModal ? (
                    <Card header={update ? "Edit Game" : "Create New Game"} footer={actions}>
                        {renderForm()}
                    </Card>
                ) : renderForm();
            } }
        </Formik>
    );
}));
import { useContext } from 'react';
import GameContext from '../../game/game.context';
import PlayerContext from '../player.context';
import PlayerIcon from '../widgets/player.icon';
import PlayerMenu from '../menus/player.menu';
import { System, Player } from '../../../type';

type PlayerListViewProps = {
  system?: System
}

export default ({ system }: PlayerListViewProps) => {
  const { players, game, details: { playerCap = 0 } = {} } = useContext(GameContext);
  const prefersSystem = (player: Player) => !system || !player.systemPreference || !player.systemPreference[game?.id ?? 0] ||
    player.systemPreference[game?.id ?? 0].length === 0 || player.systemPreference[game?.id ?? 0].includes(system?.id ?? 0);
  const committedPlayers = players?.filter((player) => !!player?.game && player.game?.id === game?.id && prefersSystem(player)) ?? [];
  const waitlistPlayers = players?.filter((player) => !!player.waitlist && player.waitlist.includes(game?.id ?? 0) && prefersSystem(player)) ?? [];
  const isPrivate = game?.metadata?.type?.toLowerCase() === 'private';

  return (
    <div className="player-list">
      {committedPlayers.length === 0 && <p>There are no players signed up for this game yet</p>}
      {committedPlayers.length > 0 && (
        <div className="players">
          <h5 className="subtitle is-5">Players {committedPlayers.length}/{playerCap}</h5>
          { committedPlayers?.map((player, index) => (
            <PlayerContext.Provider value={{player}} key={`player-${index}`}>
              <PlayerIcon player={player} large showHistory>
                <PlayerMenu/>
              </PlayerIcon>
            </PlayerContext.Provider>
          )) }
        </div>
        )}
      {waitlistPlayers.length > 0 && (
        <div className="waitlist">
          <h5 className="subtitle is-5">{isPrivate ? "Pending Approval" : "Waitlist"}</h5>
          { waitlistPlayers?.map((player, index) => (
            <PlayerContext.Provider value={{player}} key={`waitlist-${index}`}>
              <PlayerIcon player={player} large showHistory>
                <PlayerMenu/>
              </PlayerIcon>
            </PlayerContext.Provider>
          )) }
        </div>
      )}
    </div>
  );
}
import { TextInput } from '@vaettyr/boltcave-client-core';

type EditEventProps = {
  busy?: boolean;
  type?: string;
}

export default ({ busy, type }: EditEventProps) => {
  switch(type) {
    case 'topic':
      return <>
        <TextInput name="metadata.title" label="Topic Title" required disabled={busy} />
        <TextInput name="metadata.description" label="Topic Description" disabled={busy} lines={4} />
      </>;
    default:
      return null;
  }
}
import { Card, modalService } from "@vaettyr/boltcave-client-core";
import { inject, observer } from 'mobx-react';
import { Event, Game } from '../../../type';
import { useContext } from 'react';
import AuthorizedContext from "../../../auth/authorized.context";

type SwitchModalProps = {
    modalservice?: modalService,
    event: Event,
    game: Game
}

export default inject('modalservice', 'authstore', 'playerstore', 'toastservice') ( observer(
( { modalservice, event, game }: SwitchModalProps ) => {

    const authorizedAction = useContext(AuthorizedContext);

    const switchGame = () => {
      authorizedAction({ action:"join", event: event as Event, game }, <p>You must be logged in to join a game</p>);
    };

    const onCancel = () => {
        modalservice?.hide("switch-modal");
    }

    const actions = (
        <>
            <button type="button" className="card-footer-item button is-primary" onClick={switchGame}>OK</button>
            <button className="card-footer-item button" onClick={onCancel}>Cancel</button>
        </>
    );

    return (
        <Card header="Switch Games" footer={actions}>
            <p className="has-text-centered">Are you sure you want to switch to {game?.title ?? 'this game'}?</p>
            <p className="has-text-centered">You are the host of another game. If you switch then your other game will be cancelled.</p>
        </Card>
    );
}));
import { useSearchParams } from 'react-router-dom';
import { fromString, Today } from '../utility/date.utility';

type rangeType = "single"|"week"|"month"

export default (range:rangeType = "month"):Date[]|undefined => {
  const [searchParams] = useSearchParams();
  let date = fromString(searchParams.get('date') ?? undefined);
  const start = date ? new Date(date) : Today();
  const end = date ? new Date(date) : Today();
  switch(range) {
    case "single":
      return date ? [date] : undefined;
    case "week":
      if(!date) { date = Today(); }
      start.setDate(date.getDate() - date.getDay()); // always sets to last sunday
      end.setDate(date.getDate() + (6 - date.getDay())); // always set to upcoming saturday
      break;
    case "month":
      start.setDate(1); // set to first of the month
      start.setDate(1 - start.getDay()); // set to first sunday on or before
      end.setMonth(end.getMonth() + 1); // next month
      end.setDate(0); // last day of this month
      end.setDate(end.getDate() + (6 - end.getDay())); // first saturday on or after
      break;
  }
  return [date ?? Today(), start, end];
}
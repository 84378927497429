import { inject, observer } from 'mobx-react';
import { modalService, Table, Tag } from '@vaettyr/boltcave-client-core';
import GameStore from '../../stores/gameStore';
import EditGame from '../../components/game/modals/game.edit';
import DeleteGame from '../../components/game/modals/game.delete';
import { Game } from '../../type';
import { Fragment } from 'react';

type TemplatesProps = {
  gamestore?: GameStore,
  modalservice?: modalService
}

export default inject('gamestore', 'modalservice') ( observer(
  ({ gamestore, modalservice }: TemplatesProps) => {

    const templateColumns = [
      { name: 'name', label: 'Name' },
      { name: 'system', label: 'System', format: ({ systems }: Game, i?: number) => <> {
        systems?.map((system, index) => typeof(system) === 'object' ?
          ( !!system.shortName ?
            <Fragment key={`templates-systems-${i}`}>
              <Tag key={`system-${index}-short`} className="short-system" value={ system.shortName } />
              <Tag className="long-system" key={`system-${index}-long`} value={ system.name ?? 'unknown' } />
            </Fragment>
            : <Tag key={`system-${index}`} value={ system.name ?? 'unknown' } /> )
          :null )
      } </>},
      { name: 'title', label: 'Title'},
      { name: 'metadata', label: 'Info', format: ({ metadata: { pregen, premade, type, level } = {} }: Game, index?: number) => <>
        { !!pregen && <Tag value="Pregens" key={`template-tag-pregen-${index}`} /> }
        { !!premade && <Tag value="Homemade" key={`template-tag-homemade-${index}`} /> }
        { !!type && <Tag value={type} key={`template-tag-type-${index}`} /> }
        { !!level && <Tag value={`Level ${level}`} key={`template-tag-level-${index}`} /> }
      </> },
      { name: 'players', label: 'Players', format: ({ minPlayers, maxPlayers }: Game, index?: number) => <div key={`player-count-${index}`}>{`${minPlayers}/${maxPlayers}`}</div> },
      { name: 'actions', label: '', format: (_game: Game, index?: number) => <>
          <span className="icon clickable" onClick={ onEdit(index) } data-tooltip="Edit Template" key={`template-edit-${index}`}>
              <i className="fa-solid fa-pen-to-square"></i>
          </span>
          <span className="icon clickable" onClick={ onDelete(index) } data-tooltip="Delete Template" key={`template-delete-${index}`}>
              <i className="fa-solid fa-trash"></i>
          </span>
      </>}
    ];

    const onEdit = (index?: number) => () => {
      modalservice?.show({
        body: <EditGame game={getTemplate(index)} template={ index } isModal />,
        key: 'edit-game',
        options: { className: 'edit-game' }
      });
    }

    const onDelete = (index?: number) => () => {
      const { name, ...rest } = getTemplate(index) ?? {};
      modalservice?.show({
        body: <DeleteGame game={rest} template={name} />,
        key: 'game-delete',
        options: { className: 'game-delete' }
      });
    }

    const getTemplate = (index?: number): (Game & { name: string })|undefined => {
      if(index !== undefined && index >= 0 && index < Object.keys(templates).length) {
        const key = Object.keys(templates)[index];
        return { ...templates[key], name: key };
      }
      return;
    }

    const { templates = {} } = gamestore ?? {};
    const formatted = Object.keys(templates).map((key) => ( { name: key, ...templates[key] }));
    return (
      <div className="templates">
        <h3 className="title is-3">Game Templates</h3>
        { formatted.length === 0 && <p>You don't have any saved Game Templates yet</p> }
        { formatted.length > 0 && (
          <Table
            columns={ templateColumns }
            data={ formatted }
            className="templates-table"
          />
        )}
        <button className="button is-primary" role="button" onClick={ onEdit(formatted.length) }>Add New Game Template</button>
      </div>
    )
  }
));
import { Card, NumberInput, TextInput, modalService, toastService } from "@vaettyr/boltcave-client-core";
import MarkdownInput from '../../markdown/markdown.preview.input';
import { inject, observer } from 'mobx-react';
import { Formik, Form } from 'formik';
import { Prize } from '../../../type';
import PrizeStore from '../../../stores/prizeStore';
import { ImageInput } from '@vaettyr/boltcave-media-client';
import { saveItemToasts } from "../../../utility/toast.utility";

type PlayerModalProps = {
    modalservice?: modalService,
    prizestore?: PrizeStore,
    toastservice?: toastService,
    prize?: Prize,
    copy?: boolean,
}

export const modalName = 'edit-prize';

export default inject('prizestore', 'modalservice', 'toastservice') ( observer(
( { prizestore, modalservice, prize, toastservice, copy }: PlayerModalProps ) => {

    const onCancel = () => {
        modalservice?.hide(modalName);
    }

    const savePrize = (values: Prize): void => {
      const { success, failure } = saveItemToasts(toastservice, "Prize", !!prize?.id);
      prizestore?.save(values)
        .then(() => {
            success();
            modalservice?.hide(modalName)
        })
        .catch(failure);
    }

    const initialValues = {
      id: prize?.id,
      name: prize?.name ?? '',
      description: prize?.description ?? '',
      image: prize?.image,
      wonBy: prize?.wonBy,
      wonAt: prize?.wonAt
    }

    const headerText = prize ? (copy ? 'Copy Prize' : 'Edit Prize') : 'Create Prize';

    return (
        <Formik initialValues={ initialValues } onSubmit={ savePrize }>
            {({ isValid, values, handleSubmit }) => {
                const actions = (
                    <>
                        <button type="button" className="card-footer-item button is-primary" disabled={!isValid} onClick={()=>{handleSubmit()}}>Save Prize</button>
                        <button className="card-footer-item button" onClick={onCancel}>Cancel</button>
                    </>
                );
                return (
                    <Card header={headerText} footer={actions}>
                        <Form>
                            <TextInput name="name" label="Name" required />
                            <MarkdownInput name="description" label="Description" />
                            <NumberInput name="minimum_bid" label="Minimum Bids" />
                            <ImageInput
                                name='image'
                                showButton
                                label="Image"
                                ingest
                                fullPath
                                required
                                disabled={!values.name}
                                imageProps={{ name: `prize-image-${values.name ?? 'new'}`, sector: 'prize', tags: {save: [`prize-${values.name}`]} }}
                                previewProps={{ mode: 'crop', size: 96, }}
                            />
                        </Form>
                    </Card>
                )
            }}
        </Formik>
    )
}));
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { inject, observer } from 'mobx-react';
import { SecureApp, Secured, Authenticator, AuthStore } from '@vaettyr/boltcave-auth-client';
import { NavLink, Route, Routes, Navigate } from "react-router-dom";
import SystemStore from './stores/systemStore';
import LocationStore from './stores/locationStore';
import EventStore from './stores/eventStore';
import GameStore from './stores/gameStore';
import PlayerStore from './stores/playerStore';
import UpdateStore from './stores/updateStore';
import ContentStore from './stores/contentStore';
import TokenStore from './stores/tokenStore';
import PrizeStore from './stores/prizeStore';
import Menu from './pages/menu/menu.view';
import System from './pages/system/system.view';
import Location from './pages/location/location.view';
import Calendar from './pages/calendar/calendar.view';
import Templates from './pages/template/template.page';
import About from './pages/about/about.page';
import Prizes from './pages/prizes/prizes.page';
import AuthorizedContext from './auth/authorized.context';
import useAuthorizedAction from './auth/authorized.action';
import useCheckinKey from './hooks/useCheckinKey';
import FallbackContext from './utility/fallback.context';
import { modalService, storeHelpers, toastService } from '@vaettyr/boltcave-client-core';
import { EditProfileAvatar, ImageStore, media, useImport } from '@vaettyr/boltcave-media-client';
import TokenCoin from './components/token/widgets/token.coin.view';
import PlayerIcon from './components/player/widgets/player.icon';
import "./style/index.scss";


type RPGProps = {
    authstore?: AuthStore,
    gamestore?: GameStore,
    imagestore?: ImageStore,
    locationstore?: LocationStore,
    modalservice?: modalService,
    systemstore?: SystemStore,
    toastservice?: toastService,
    playerstore?: PlayerStore,
    updatestore?: UpdateStore,
    contentstore?: ContentStore,
    tokenstore?: TokenStore,
    prizestore?: PrizeStore,
}

const imageStore = new ImageStore();
const eventstore = new EventStore();
const gamestore = new GameStore();
const playerstore = new PlayerStore();
const updatestore = new UpdateStore(eventstore, gamestore, playerstore);
const contentStore = new ContentStore();
const tokenstore = new TokenStore();
const prizestore = new PrizeStore();

const stores = {
  'systemstore': new SystemStore(),
  'locationstore': new LocationStore(),
  imagestore: imageStore,
  eventstore,
  gamestore,
  playerstore,
  updatestore,
  contentstore: contentStore,
  tokenstore,
  prizestore
}

const RPG = inject('locationstore', 'systemstore', 'authstore', 'imagestore', 'tokenstore', 'gamestore', 'modalservice', 'playerstore', 'toastservice', 'updatestore', 'contentstore')(observer(
    function RPGApp({ systemstore, locationstore, imagestore, tokenstore: tstore, updatestore: ustore, toastservice, authstore, contentstore, ...rest }:RPGProps) {
        const { user } = authstore ?? {};
        const [fallbacks, setFallbacks] = useState<media[]>([]);
        const [newSystems, setNewSystems] = useState<boolean>(false);

        const { systems = [] } = systemstore ?? {};
        const { siteContents = [] } = contentstore ?? {};
        const { balance } = tstore ?? {};

        /* let's make all of these initialization calls bootstrappable from the server render */
        useEffect(() => {
            systemstore?.init();
            locationstore?.init();
            contentstore?.init();
            // would love to embed this data also
            imagestore?.fetch({ tags: ['fallback'] })
                .then((images) => {
                    setFallbacks(images);
                });
        }, []);

        useEffect(() => {
            if(authstore?.authenticated) {
                ustore?.initialize(authstore?.user, toastservice);
                tstore?.init(authstore?.user?.id ?? 0);
            }
        }, [authstore?.authenticated])

        useEffect(() => {
            setNewSystems(systems.some((system) => system.userSubmitted));
        }, [systems.length]);

        useImport({ authstore, imagestore }, /^https:\/\/cdn\.discordapp\.com\/embed\/avatars\/\d\.png$/i);

        const checkin = useCheckinKey();
        const authAction = useAuthorizedAction({ checkin, authstore, toastservice, ...rest });

        const [isActive, setActive] = useState<boolean>(false);
        const toggle = () => { setActive(!isActive)};
        const close = () => { setActive(false)};
        const active = isActive ? " is-active" : "";
        const title = storeHelpers.GetEnvVar('title') as string|undefined ?? "ATLRPG";

        return (
            <div>
                <FallbackContext.Provider value={{images: fallbacks}}>
                    <nav className="navbar is-fixed-top" role="navigation" aria-label="main-navigation">
                        <div className="navbar-brand">
                            <div className="navbar-item">
                                <p className="title">{title}</p>
                            </div>
                            <a role="button" className={`navbar-burger${active}`} aria-label="menu" aria-expanded="false" onClick={toggle}>
                                <span aria-hidden="true"></span>
                                <span aria-hidden="true"></span>
                                <span aria-hidden="true"></span>
                            </a>
                        </div>
                        <div className={`navbar-menu${active}`}>
                            <div className="navbar-start">
                                <NavLink className={`navbar-item`} to={"/calendar"} onClick={close}>Calendar</NavLink>
                                <NavLink className={`navbar-item`} to={`/menu`} onClick={close}>Menu</NavLink>
                                <NavLink className={`navbar-item`} to={`/prizes`} onClick={close}>Prizes</NavLink>
                                <Secured claims={{key:"BOOLEAN.LocationService.CanEditLocations", value:true}}>
                                    <NavLink className={`navbar-item`} to={`/location`} onClick={close}>Locations</NavLink>
                                </Secured>
                                <Secured claims={{key:"BOOLEAN.SystemService.CanEditSystems", value:true}}>
                                    <NavLink className={`navbar-item`} to={`/system`} onClick={close}>
                                        <span>Systems</span>
                                        {newSystems && (<span className="icon"><i className="fa-solid fa-bell"></i></span>)}
                                    </NavLink>
                                </Secured>
                                { siteContents.length === 0 && (
                                    <Secured claims={{key:'ENUM.ContentService.CanEditContent', value:'ALL'}}>
                                        <NavLink to={`/about`} className={`navbar-item`} onClick={close}>Add Site Content</NavLink>
                                    </Secured>
                                ) }
                                { siteContents.length === 1 && (
                                    <NavLink to={`/about`} className={`navbar-item`} onClick={close}>About</NavLink>
                                ) }
                                { siteContents.length > 1 && (
                                    <div className="navbar-item has-dropdown is-hoverable">
                                        <NavLink to={`/about`} className={`navbar-link`} onClick={close}>About</NavLink>
                                        <div className="navbar-dropdown">
                                            {siteContents.filter((c) => c.toUpperCase() !== "ABOUT").map((c) =>
                                                <NavLink className="navbar-item" to={`/about/${c.toLowerCase()}s`} key={`menu-about-${c}`} onClick={close}>
                                                    <span>{`${c}s`}</span>
                                                </NavLink>
                                            )}
                                        </div>
                                    </div>
                                ) }
                            </div>
                            <div className="navbar-end">
                            { !!balance && <TokenCoin amount={balance} tooltip="Tokens" className="has-tooltip-bottom" /> }
                                <Authenticator
                                    editProfile={EditProfileAvatar}
                                    trigger={ <PlayerIcon player={{ user }} hide={['name', 'status']} /> }
                                    onClick={ close }
                                >
                                    <NavLink to="/template">Manage Templates</NavLink>
                                </Authenticator>
                            </div>
                        </div>
                    </nav>
                    <div className="rpg-body">
                        <AuthorizedContext.Provider value={authAction}>
                            <Routes>
                                <Route path="/calendar" element={<Calendar/>} />
                                <Route path="/menu/:eventName?" element={<Menu type='game night' /> } />
                                <Route path="/prizes" element={<Prizes />} />
                                <Route path="/system" element={<System/>} />
                                <Route path="/location" element={<Location/>} />
                                <Route path="/template" element={<Templates/>} />
                                <Route path="/about/:param?" element={<About/>} />
                                <Route path="/workshop" element={<Menu type='dm workshop' /> } />
                                <Route path="*" element={<Navigate to="/menu" replace/>}/>
                            </Routes>
                        </AuthorizedContext.Provider>
                    </div>
                </FallbackContext.Provider>
            </div>
        );
    }
));

/*-- this is just the hackiest shit --*/
/*-- maybe I can just do it in the css file directly instead of this crap --*/
document.documentElement.classList.add("has-navbar-fixed-top");

ReactDOM.render(
  <React.StrictMode>
      <SecureApp stores={stores}>
        <RPG key="rpg"/>
      </SecureApp>
  </React.StrictMode>,
  document.getElementById('root')
);
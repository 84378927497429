import { useEffect } from 'react';
import { observer, inject } from 'mobx-react';
import { Table, modalService } from '@vaettyr/boltcave-client-core';
import LocationStore from '../../stores/locationStore';
import { Secured } from '@vaettyr/boltcave-auth-client';
import LocationAction from '../../components/location/location.action';
import Address from '../../components/location/location.address.view';
import EditLocation from '../../components/location/modals/location.edit';

type LocationsProps = {
    locationstore?: LocationStore,
    modalservice?: modalService
}

export default inject('locationstore', 'modalservice')
(observer(({ locationstore, modalservice }:LocationsProps) => {

    useEffect(() => {
        locationstore?.init();
    }, []);

    const addLocation = () => {
        modalservice?.show({
            body: <EditLocation/>,
            options: { className: 'edit-location'},
            key: 'edit-location'
        });
    }

    const columns = [
        { name: "name", label: "Name" },
        { name: "address", label: "Address", content: <Address /> },
        { name:"", content:<LocationAction /> }
    ];

    const { locations = [], busy } = locationstore ?? {};
    return (
        <Secured claims={{key:"BOOLEAN.LocationService.CanEditLocations", value:true}}>
            <div className="locations">
                <h3 className="title is-3">Locations</h3>
                {busy && (<progress className="progress is-small is-primary" max="100">15%</progress>)}
                <Table
                    className="locations-table"
                    columns={columns}
                    data={ locations }
                />
                <button className="button is-primary" role="button" onClick={addLocation}>Add Location</button>
            </div>
        </Secured>
    );
}));
import { inject, observer } from 'mobx-react';
import { useContext, useEffect, useMemo, useState } from 'react';
import EventContext from './event.context';
import AuthorizedContext from '../../auth/authorized.context';
import GameStore from '../../stores/gameStore';
import GameView from '../../components/game/views/game.view';
import GameDetail from '../../components/game/views/game.detail.view';
import EventDetailView from './detail/event.detail.view';
import { Game, Event } from '../../type';
import PlayerStore from '../../stores/playerStore';
import { AuthStore, User } from '@vaettyr/boltcave-auth-client';
import { modalService } from '@vaettyr/boltcave-client-core';
import SwipeableViews from 'react-swipeable-views';
import { useNavigate, useLocation } from 'react-router-dom';
import useEventPermissions from '../../hooks/useEventPermissions';
import StartEvent from './modals/event.start';

type EventMenuViewProps = {
  authstore?: AuthStore
  event:Event,
  gamestore?: GameStore,
  modalservice?: modalService,
  playerstore?: PlayerStore
}

const sortValue = (game:Game) => (game.isFlex ? 100 : (game.metadata?.type?.toLowerCase() === 'private' ? 50 : 0)) + (game.id ?? 0);

export default inject('authstore', 'gamestore', 'modalservice', 'playerstore') ( observer (
({ authstore, event, gamestore, modalservice, playerstore }:EventMenuViewProps) => {

  const { games = [] } = gamestore ?? {};
  const { user = {} } = authstore ?? {};

  const eventGames = games.filter((g) => (g.event as Event)?.id === event?.id);
  eventGames.sort((a,b) => sortValue(a) - sortValue(b));
  const flexStart = eventGames.findIndex((g) => !!g.isFlex);
  const self = playerstore?.players.find((p) => ((p.user as User)?.id ?? 0) === (user?.id ?? -1) && p.event?.id === event.id);

  const { canAddGame, canEditEvent } = useMemo(() => useEventPermissions({ authstore, event, self }), [authstore?.user?.id, event?.id, self?.id]);

  const { hash, pathname, search } = useLocation();
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState<number|undefined>(undefined);
  // const [{game, flex}, setActiveHash] = useState<{game?:number, flex?: number}>({}); // is this even used?

  useEffect(() => {
    if(eventGames.length > 0) {
      const hashMatches = (/^\#(game|flex)\-(\d+)$/i).exec(hash);
      let gameIndex: number = 0;
      if(hashMatches && hashMatches.length > 1) {
        gameIndex = Number.parseInt(hashMatches[2], 10);
        // setActiveHash({game: hashMatches[1].toLowerCase() === 'game' ? gameIndex : undefined, flex: hashMatches[1].toLowerCase() === 'flex' ? gameIndex : undefined});
        if(hashMatches[1].toLowerCase() === 'flex') gameIndex += Math.max(0, flexStart);
        if(Number.isNaN(gameIndex)) { gameIndex = 0; }
      }
      if(activeIndex !== gameIndex) {
        setTab(gameIndex);
      }
    }
  }, [eventGames.length, hash])

  const setTab = (index:number) => {
    if(index > eventGames.length || index < 0) return;
    setActiveIndex(index);
    const isFlex = flexStart >= 0 && index > flexStart;
    const path = `${pathname}${search}${index > 0 ? `#${isFlex ? 'flex' : 'game'}-${index - (isFlex ? flexStart : 0)}` : ''}`;
    navigate(path);
    window.scrollTo({top: 0});
  }

  const incrementTab = (direction: number = 1) => setTab(direction + (activeIndex ?? 0));

  const authorizedAction = useContext(AuthorizedContext);

  const addGame = () => {
    authorizedAction({ action: "addgame", event }, <p>You must be logged in to add a game</p>);
  }

  const beginEvent = () => {
    modalservice?.show({
      body: <StartEvent event={event} />,
      key: 'start-event',
      options: { className: 'start-event' }
    });
  }

  return (
    <div className="event">
      <EventContext.Provider value={{event, self}}>
        <EventDetailView />
        {!event.cancelled && (
          <div className="event-menu">
            { canAddGame && <button className='add-game button is-info desktop' onClick={ addGame }>Add Game</button> }
            { canEditEvent && !event.locked && !event.cancelled && <button className="add-game button is-success dekstop" onClick={ beginEvent }>Begin Event</button> }
            <SwipeableViews index={activeIndex} onChangeIndex={setTab}>
              <div className="games-list">
                { eventGames.map((eventGame, i) => <GameView game={eventGame} line={i} onSelect={() => setTab(i + 1)} key={`game-${i}`} flexStart={i === flexStart - 1 ? true : false}/> )}
                { canAddGame && <button className='add-game button is-info' onClick={ addGame }>Add Game</button> }
              </div>
              { eventGames.map((eventGame, i) => <GameDetail game={eventGame} key={`game-detail-${i}`} navigate={incrementTab} /> )}
            </SwipeableViews>
          </div>
        )}
      </EventContext.Provider>
    </div>
  );
}));
import { AuthStore } from "@vaettyr/boltcave-auth-client";
import { Event, Player } from '../type';
import { claimTypes } from "../const";
// this is just a utility to give some basic permissions in a given context
type PermissionArgs = {
  authstore?: AuthStore,
  event?: Event,
  self?: Player
}

type Permissions = {
  canAddEvent?: boolean,
  canEditEvent?: boolean,
  canAddGame?: boolean
}

export default ({ authstore, event, self }: PermissionArgs): Permissions => {

  const { user, claims } = authstore ?? {};
  const CanEditEvents = claims?.find((c) => c.Key === claimTypes.Events)?.Value as "OWN"|"ALL"|undefined;
  const CanEditGames = claims?.find((c) => c.Key === claimTypes.Games)?.Value as "OWN"|"ALL"|undefined;
  const isHost = !!self?.host;

  const canAddEvent = CanEditEvents !== undefined;
  const canEditEvent = CanEditEvents === "ALL" || (!!event && CanEditEvents === "OWN" && event?.owner?.id === user?.id);
  const canAddGame = (CanEditGames === "ALL" || ((CanEditGames === "OWN" && !isHost) || !user?.id)) && !event?.locked && !event?.cancelled; // it's an auth action, so they'll be prompted

  return {
    canAddEvent,
    canEditEvent,
    canAddGame
  };
}
import { Card, modalService, toastService } from "@vaettyr/boltcave-client-core";
import { inject, observer } from 'mobx-react';
import { Formik, Form } from 'formik';
import { Event, Game, Player } from '../../../type';
import SystemPreference from "../../system/form/system.preference";
import PlayerStore from "../../../stores/playerStore";
import { addPlayerToasts } from "../../../utility/toast.utility";
import { User } from "@vaettyr/boltcave-auth-client";
import usePlayerName from "../../../hooks/usePlayerName";
import GameStore from "../../../stores/gameStore";

type PlayerModalProps = {
    gamestore?: GameStore
    modalservice?: modalService,
    playerstore?: PlayerStore,
    toastservice?: toastService,
    event: Event,
    player?: Player,
    user?: User,
    game: Game,
    waitlist?: boolean,
}

export default inject('gamestore', 'modalservice', 'playerstore', 'toastservice') ( observer(
( { gamestore, modalservice, playerstore, toastservice, waitlist, game, event, player, user }: PlayerModalProps ) => {

    const savePlayer = ({ systemPreference }: { systemPreference?: Record<number, number[]> }) => {
      const name = usePlayerName(player, user);
      const { success, failure } = addPlayerToasts(toastservice, name, game.title, waitlist);
        if(!!player) {
          playerstore?.setPreference(event, game, {id: player.id, systemPreference}, waitlist)
          .then(success).catch(failure).finally(() => modalservice?.hide('player-preference'));
        } else {
          playerstore?.join(event, game, {name, user: user?.id, systemPreference}, waitlist)
          .then(({deleted}) => {
            if(!!deleted) {
                gamestore?.clear(deleted);
            }
            success();
          }).catch(failure).finally(() => modalservice?.hide('player-preference'));
        }

    };

    const onCancel = () => {
        modalservice?.hide("player-preference");
    }

    const initialValues = {
        systemPreference: !!player?.systemPreference ? player.systemPreference : [],
        preference: player?.systemPreference && player.systemPreference[game.id ?? 0] ?
          Object.fromEntries(player.systemPreference[game.id ?? 0].map((gameId) => [gameId, true])) : undefined
    }



    return (
        <Formik initialValues={ initialValues } onSubmit={ savePlayer }>
            {({ isValid, handleSubmit }) => {
                const actions = (
                    <>
                        <button type="button" className="card-footer-item button is-primary" disabled={!isValid} onClick={()=>{handleSubmit()}}>{!!player ? "Update" : "Join"}</button>
                        <button className="card-footer-item button" onClick={onCancel}>Cancel</button>
                    </>
                );

                return (
                    <Card header={`${!!player ? "Update Preference(s) for" : "Join"} ${game?.title ?? 'Game'}${waitlist? ' waitlist' : ''}`} footer={actions}>
                        <Form>
                            <SystemPreference name="systemPreference" label="System Preference(s)" game={game} className="system-preference"/>
                        </Form>
                    </Card>
                );
            }}
        </Formik>
    )
}));
import { inject, observer } from 'mobx-react';
import { modalService } from '@vaettyr/boltcave-client-core';
import DeleteEvent from '../modals/event.delete';
import EditEvent from '../modals/event.edit';
import { Event } from '../../../type';
import EventStore from '../../../stores/eventStore';
import EventAttendees, { modalName } from '../attendees/event.attendees.view';
import StartEvent from '../modals/event.start';
import PrizeStore from '../../../stores/prizeStore';

type GameMenuProps = {
    modalservice?: modalService,
    event: Event,
    eventstore?: EventStore,
    prizestore?: PrizeStore,
    onClick: ((e?:React.MouseEvent) => void)
}

const transformEvent = (event:Event):Event & {parent?: number, location?: number} => {
    const { parent, location } = event;
    return {
        ...event,
        location: location ? location.id : undefined,
        parent: parent ? parent.id : undefined
    };
}

export default inject('eventstore', 'modalservice', 'prizestore') (observer (
({ event, eventstore, modalservice, prizestore, onClick }: GameMenuProps) => {

    const { locked, cancelled, eventDate } = event;

    const pastEvent = eventDate && eventDate < new Date(Date.now());

    const deleteEvent = (e:React.MouseEvent) => {
        modalservice?.show({
            body: <DeleteEvent event={event} />,
            options: { className: 'delete-event' },
            key: 'delete-event'
        });
        onClick(e);
    }

    const editEvent = (e:React.MouseEvent) => {
        modalservice?.show({
            body: <EditEvent event={event} />,
            options: { className:"edit-event" },
            key:"edit-event"
        });
        onClick(e);
    }

    const showAttendees = (e:React.MouseEvent) => {
        modalservice?.show({
            body: <EventAttendees event={event} isModal mode="status" />,
            key: modalName,
            options: { className: modalName }
        });
        onClick(e);
    }

    const showStart = (e:React.MouseEvent) => {
        modalservice?.show({
            body: <StartEvent event={event} />,
            key: 'start-event',
            options: { className: 'start-event' }
        });
        onClick(e);
    }

    const unlock = (e:React.MouseEvent) => {
        event.locked = false;
        eventstore?.save(transformEvent(event))
        .then(() => {
            if (event.type === 'dm workshop') {
                prizestore?.get({ withBids: true, hasBids: true, active: true });
            }
        });
        onClick(e);
    }

    const toggleCancel = (e:React.MouseEvent) => {
        event.cancelled = !event.cancelled;
        eventstore?.save(transformEvent(event));
        onClick(e);
    }

    return (
        <div className="dropdown-menu">
            <div className="dropdown-content">
                <div className="dropdown-item clickable" onClick={editEvent}>Edit</div>
                <div className="dropdown-item clickable" onClick={deleteEvent}>Delete</div>
                <div className="dropdown-item clickable" onClick={showAttendees}>View Attendees</div>
                { !cancelled && !pastEvent && <div className="dropdown-item clickable" onClick={toggleCancel}>Cancel</div> }
                { cancelled && !pastEvent && <div className="dropdown-item clickable" onClick={toggleCancel}>Un-Cancel</div> }
                { !locked && <div className="dropdown-item clickable" onClick={showStart}>Begin Event</div> }
                { locked && <div className="dropdown-item clickable" onClick={unlock}>Unlock Event</div> }
            </div>
        </div>
    )
} ));
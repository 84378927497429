import { observer, inject } from 'mobx-react';
import { Card, toastService, modalService } from '@vaettyr/boltcave-client-core';
import TokenStore from '../../../stores/tokenStore';
import { Token } from '../../../type';
import { deleteItemToasts } from '../../../utility/toast.utility';
import { AuthStore } from '@vaettyr/boltcave-auth-client';
import PrizeStore from '../../../stores/prizeStore';

type DeleteTokenProps = {
    authstore?: AuthStore;
    toastservice?:toastService,
    modalservice?:modalService,
    token: Token,
    tokenstore?: TokenStore,
    prizestore?: PrizeStore,
    onAction?: ((e?:React.MouseEvent) => void)
}

export const modalName = 'delete-token';

export default inject( 'authstore', 'tokenstore', 'toastservice', 'modalservice', 'prizestore' )
    ( observer(({ authstore, toastservice, modalservice, onAction, token, tokenstore, prizestore }: DeleteTokenProps ) => {
    const { user: { id = 0 } = {} } = authstore ?? {};
    const { amount = 0, user: { id: userId = 0 } = {} } = token;
    const tokenType = amount > 0 ? "Token" : "Bid";

    const onDelete = ():void => {
        const { success, failure } = deleteItemToasts(toastservice, tokenType);
        const refreshId = id === userId ? userId : undefined;
        tokenstore?.delete(token, refreshId)
            .then(() => {
                success();
                if (token.prize) {
                    prizestore?.refresh(token.prize);
                }
                if (onAction) {
                    onAction();
                }
            }).catch(failure).finally(() => modalservice?.hide(modalName));
    }

    const onCancel = () => {
        modalservice?.hide(modalName);
    }

    const actions = (
        <>
            <button type="button" className="card-footer-item button is-primary" onClick={onDelete}>Delete</button>
            <button className="card-footer-item button" onClick={onCancel}>Cancel</button>
        </>
    );

    return (
        <Card header={`Delete ${tokenType}`} footer={actions}>
            <p>Are you sure you want to delete this {tokenType}</p>
        </Card>
    );
}));
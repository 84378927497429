import { Event, Token } from "../../type";
import TokenMenu from './menus/token.menu.view';
import { AuthStore } from '@vaettyr/boltcave-auth-client';
import { inject, observer } from 'mobx-react';
import PlayerIcon from "../player/widgets/player.icon";
import TokenCoin from "./widgets/token.coin.view";

type TokenItemProps = {
    authstore?: AuthStore;
    user?: boolean;
    className?: string;
    date?: boolean;
    description?: boolean;
    sign?: boolean;
    edit?: boolean;
    bid?: boolean;
    onClick?: ((e?: React.MouseEvent) => void);
    onAction?: ((e?: React.MouseEvent) => void)
    showAward?: boolean;
    event?: Event;
    token: Token;
}

export default inject('authstore') ( observer (
({ authstore, bid = false, user: showUser, className = '', date: showDate, description: showDescription, edit = true, onClick, onAction, showAward, event, token, sign }: TokenItemProps) => {
    const { created, updated, user, prize, won, description, amount = 0 } = token;

    const canEdit = ((!won && user?.id === authstore?.user?.id && bid) || authstore?.hasPermission("BOOLEAN.TokenService.CanEditTokens", true)) && edit;
    const dateObj = (event?.eventDate ?? updated ?? created);

    const date = showDate ? (
        <span className="date">{dateObj ? (typeof dateObj === 'string' ? new Date(dateObj) : dateObj).toLocaleDateString('en-US') : ''}</span>
    ) : undefined;

    const descText = description ?? prize?.name ?? event?.name ?? '';

    return (
        <div className={`token-item ${className}`} onClick={onClick}>
            { showUser && <PlayerIcon player={{ user }} /> }
            { date }
            { showDescription && <span className="description">{descText}</span> }
            <div className="bid-amount">
                <TokenCoin amount={amount} className="is-bold" sign={sign} />
                { canEdit && <TokenMenu token={token} showAward={showAward} onAction={onAction} event={event} /> }
            </div>
        </div>
    )
}));
import { observer, inject } from 'mobx-react';
import { Formik, Form } from 'formik';
import { Card, Select } from '@vaettyr/boltcave-client-core';
import useEditContentController, { EditContentProps } from './content.edit.controller';
import EditGlobalContent from './content.edit.global';
import EditEventContent from './content.edit.event';

export default inject( 'contentstore', 'toastservice', 'modalservice','authstore' )
    ( observer((props: EditContentProps ) => {

    const { busy, cancel, id, initialValues, save, type, update } = useEditContentController(props);

    const types = ["", "about", "faq", "link"];

    const isEmpty = (item: string|Record<string, string>): boolean =>
      typeof(item) === 'string' ?
        !item :
        Object.keys(item).reduce<boolean>((empty, key) => empty && isEmpty(item[key]), true);

    return (
        <Formik initialValues={ initialValues } onSubmit={save}>
            {({dirty, isValid, values, handleSubmit, errors}) => {

              const hasErrors = !isEmpty(errors);
              const disabled = !dirty || (!isValid && hasErrors) || busy;
              const { type: inputType, linkType: inputLinkType } = values;
              const displayType = inputType ?? type;

              const title = `${update ? 'Update' : 'Edit'} ${type ?? 'Content'}`;
              const actions = (
                <>
                  <button type="button" className="card-footer-item button is-primary" disabled={disabled} onClick={()=>{handleSubmit()}}>Save</button>
                  <button className="card-footer-item button" onClick={cancel}>Cancel</button>
                </>
              )

              return (
                <Card header={title} footer={actions}>
                  <Form className="content-edit">
                      { busy && (<progress className="progress is-primary is-medium" max="100">15%</progress>)}
                      <div className="content-details">
                        { !type && <Select name="type" label="Content Type" options={types} required disabled={busy} /> }
                        { inputLinkType === 'event' ? <EditEventContent busy={busy} type={displayType} /> : <EditGlobalContent busy={busy} type={displayType} id={id} /> }
                      </div>
                  </Form>
                </Card>
              )
            } }
        </Formik>
    );
}));
import ContentStore from '../../stores/contentStore';
import { AuthStore } from '@vaettyr/boltcave-auth-client';
import { modalService } from '@vaettyr/boltcave-client-core';
import { inject, observer } from 'mobx-react';
import { useParams, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import ContentGroup from '../../components/content/content.group';
import { Content } from '../../type';
import EditContent from '../../components/content/content.edit.view';

type AboutProps = {
  contentstore?: ContentStore,
  authstore?: AuthStore,
  modalservice?: modalService
}

export default inject('contentstore', 'authstore', 'modalservice') ( observer ( ({ contentstore, authstore, modalservice }: AboutProps) => {

  const { contents: { global = [] } = {}, siteContents = [], busy } = contentstore ?? {};
  const { param = 'about' } = useParams();
  const sub = param.lastIndexOf('s') === param.length - 1 ? param.substring(0, param.length - 1) : param;

  const [activeTab, setActiveTab] = useState<string>(sub);
  useEffect(() => {
    if(sub !== activeTab) {
      setActiveTab(sub);
    }
  }, [sub]);

  const isActive = (name: string, value?: any) => name.toLowerCase() === activeTab?.toLowerCase() ? (!!value ? value : true) : (!!value ? undefined : false);

  const navigate = useNavigate();
  const setTab = (tab: string) => () => {
    setActiveTab(tab);
    const navTo = tab.toLowerCase() === 'about' ? '' : `/${tab}s`;
    navigate(`/about${navTo}`)
  }

  const singleContent = siteContents.length === 1 ? global.filter((c) => c.type === siteContents[0]) : undefined;
  const hasSingleContent = !!singleContent ? singleContent.length > 0 : false;

  const showEdit = (type: "faq"|"link"|"about") => () => {
    modalservice?.show({
      body: <EditContent type={type} />,
      key: 'edit-content',
      options: { className: 'edit-content' }
    });
  }

  const showButtons = authstore?.hasPermission("ENUM.ContentService.CanEditContent", "ALL")
    && !busy
    && (!global.some((c) => c.type === 'about') || !global.some((c) => c.type === 'link') ||!global.some((c) => c.type === 'faq') );

  return (
    <div className="about-page">
      { !hasSingleContent && (
        <div className="tabs is-centered is-large">
          <ul>
          { siteContents.map((c, i) => (
            <li className={ isActive(c, "is-active") } key={`about-tab-${i}`}>
              <a onClick={setTab(c)}>{`${c}${c.toLowerCase() === 'about' ? '' : 's'}`}</a>
            </li>
          ))}
          </ul>
        </div>
      )}
      { hasSingleContent && (
        <div>
          <ContentGroup contents={singleContent as Content[]} />
        </div>
      ) }
      { !hasSingleContent && (
        <div className="site-content">
          { siteContents.map((c, i) => (
            <section className={isActive(c, "is-active")} key={`site-content-${i}`}>
              <ContentGroup contents={ global.filter((content) => content.type === c) } className={c}/>
            </section>
          ))}
        </div>
      )}
      { showButtons && (
        <div className="content-buttons">
          { !global.some((c) => c.type === 'about') && <button className="button is-primary" onClick={showEdit("about")}>Add About Page</button> }
          { !global.some((c) => c.type === 'link') && <button className="button is-primary" onClick={showEdit("link")}>Add Link</button> }
          { !global.some((c) => c.type === 'faq') && <button className="button is-primary" onClick={showEdit("faq")}>Add FAQ</button> }
        </div>
      )}
    </div>
  )
} ));
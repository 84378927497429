import { Event, Prize } from '../../type';
import { MediaImage } from '@vaettyr/boltcave-media-client';
import { inject, observer } from 'mobx-react';
import { AuthStore } from '@vaettyr/boltcave-auth-client';
import { modalService } from '@vaettyr/boltcave-client-core';
import TokenStore from '../../stores/tokenStore';
import usePrizeController from './prize.controller';
import PrizeDetail, { modalName } from './prize.detail.view';
import PrizeMenu from './menus/prize.menu';
import TokenCoin from '../token/widgets/token.coin.view';
import useFallback from '../../hooks/useFallback';

type ThumbnailProps = {
    authstore?: AuthStore;
    tokenstore?: TokenStore;
    modalservice?: modalService;
    event?: Event;
    prize: Prize;
}

export default inject('authstore', 'tokenstore', 'modalservice') ( observer (
({ authstore, tokenstore, modalservice, event, prize }: ThumbnailProps) => {
    const { name, image } = prize;

    const controller = usePrizeController({ prize, authstore, tokenstore });
    const fallback = useFallback("prize") as string | undefined;
    const { canBid, owns, owned, totalBids } = controller;
    // really need to make this a utility
    const className = `prize-pod${canBid ? ' active' : ''}${owns ? ' owns' : ''}${owned ? ' owned' : ''} clickable`;

    const showDetail = () => {
        modalservice?.show({
            body: <PrizeDetail prize={prize} controller={controller} isModal event={event} />,
            key: modalName,
            options: { className: modalName }
        });
    }

    const canEditPrize = authstore?.hasPermission('ENUM.PrizeService.CanEditPrizes', 'ALL') ||
    (authstore?.hasPermission('ENUM.PrizeService.CanEditPrizes', 'OWN') && ((prize.createdBy?.id ?? 0) === authstore?.user.id ?? -1));

    return (
        <div className={ className }>
            <MediaImage src={ image as string ?? '' } mode="crop" ratio='1by1' fallback={fallback} className="prize-thumbnail" onClick={showDetail} />
            { !owned && (totalBids ?? 0) > 0 && <TokenCoin amount={totalBids as number} /> }
            <div className="prize-text">
                <h5 className="prize-name title is-5">{ name }</h5>
                { canEditPrize && <PrizeMenu prize={prize} event={event} /> }
            </div>
        </div>
    )
} ));
import { Card, Checkbox, TextInput, toastService, modalService, Select } from "@vaettyr/boltcave-client-core";
import { User } from '@vaettyr/boltcave-auth-client';
import GameStore from "../../../stores/gameStore";
import EditGame from './game.edit';
import { Formik, Form } from 'formik';
import { inject, observer } from 'mobx-react';
import { Game, Event, Player } from '../../../type';
import { saveItemToasts } from "../../../utility/toast.utility";
import { useState } from 'react';

type GameTemplateProps = {
  gamestore?: GameStore,
  event?: Event,
  game?: Game,
  host?: User|Player,
  modalservice?: modalService
  mode?: "save" | "load",
  toastservice?: toastService
}

// this needs some cleanup

const modalName = "game-template";

export default inject('gamestore', 'modalservice', 'playerstore', 'toastservice') ( observer (
  ({ game, event, host, gamestore, modalservice, mode = "save", toastservice }: GameTemplateProps ) => {

    const initialValues = {
      name: '',
      supress: false
    }

    const saveTemplate = ({ name, supress, template }: { name: string, supress: boolean, template?: string}) => {
      switch(mode) {
        case 'save':
          const { success } = saveItemToasts(toastservice, name);
          gamestore?.saveTemplate(name, game as Game, supress);
          success();
          modalservice?.hide(modalName);
          break;
        case 'load':
          const gameTemplate = (gamestore?.templates ?? {})[template as string];
          // pass this into the edit game modal, not create it automatically
          modalservice?.hide(modalName);
          modalservice?.show({
            body: <EditGame event={event} isModal host={host} game={gameTemplate} />,
            key: 'edit-game',
            options: { className: 'edit-game' }
          });
      }
    }

    const validateName = (name?: string) => {
      return !!name && Object.keys(gamestore?.templates ?? {}).includes(name) ? "You already have a template saved with this name" : undefined;
    }

    const [picker, setPicker] = useState<boolean>(false);
    const templates = ['', ...Object.keys(gamestore?.templates ?? {})];

    return (
      <Formik initialValues={ initialValues } onSubmit={saveTemplate}>
          {({dirty, isValid, values, handleSubmit}) => {

            const onCancel = () => {
              const { supress } = values;
              switch(mode) {
                case 'save':
                  if( supress ) {
                    gamestore?.Supress();
                  }
                  modalservice?.hide(modalName);
                  break;
                case 'load':
                  if(supress) {
                    gamestore?.Supress(true, true);
                  }
                  modalservice?.hide(modalName);
                  modalservice?.show({
                    body: <EditGame event={event} isModal host={host}/>,
                    key: 'edit-game',
                    options: { className: 'edit-game' }
                  });
                  break;
              }
            }

            const chooseTemplate = () => {
              setPicker(true);
              const { supress } = values;
              if(supress) {
                gamestore?.Supress(true, true);
              }
            }

            const actions = (
              <>
                {(mode === 'save' || picker) && (
                  <button type="button" className="card-footer-item button is-primary" disabled={ !dirty || !isValid } onClick={()=>{handleSubmit()}}>
                    {mode === 'save' ? 'Save' : 'Continue'}
                  </button>)}
                { mode === 'load' && !picker && <button type="button" className="card-footer-item button is-primary" onClick={chooseTemplate}>Yes</button>}
                <button className="card-footer-item button" onClick={onCancel}>No</button>
              </>
            )

            return (
              <Card header={ mode === "save" ? "Save Game Template" : "Create Game From Template" } footer={actions}>
                { mode === 'save' && (
                  <>
                    <p>Would you like to save this game as a template to re-use in the future?</p>
                    <Form>
                      <TextInput name="name" label="Name" required validate={validateName} />
                      <Checkbox name="supress" label="Don't ask me again"/>
                    </Form>
                  </>
                ) }
                { mode === 'load' && !picker && (
                  <>
                    <p>Would you like to create a game from a saved template?</p>
                    <Form>
                      <Checkbox name="supress" label="Don't ask me again"/>
                    </Form>
                  </>
                )}
                { mode === 'load' && picker && (
                  <Form>
                    <Select name="template" label="Template" options={templates} required />
                  </Form>
                )}
              </Card>
            );
          }}
      </Formik>
    );
  }
));
import { observer, inject } from 'mobx-react';
import { modalService } from '@vaettyr/boltcave-client-core';
import EditToken, { modalName as editModalName } from '../modals/token.edit.view';
import DeleteToken, { modalName as deleteModalName } from '../modals/token.delete.view';
import AwardPrize, { modalName as awardModalName } from '../../prize/modals/prize.modal.award';
import { Event, Token, Prize } from '../../../type';
import { AuthStore } from '@vaettyr/boltcave-auth-client';

type TokenActionProps = {
  showAward?: boolean;
  event?: Event;
  token: Token;
  modalservice?: modalService;
  authstore?: AuthStore;
  onAction?: ((e?:React.MouseEvent) => void)
}

export default inject('authstore', 'modalservice') ( observer (
({ authstore, showAward, event, token, modalservice, onAction }: TokenActionProps) => {
  const onEdit = () => {
    modalservice?.show({
      body:<EditToken token={token} isModal onAction={ onAction } />,
      options:{ className: editModalName },
      key: editModalName
    });
  }

  const onDelete = () => {
    modalservice?.show({
      body:<DeleteToken token={token} onAction={ onAction } />,
      options:{ className: deleteModalName },
      key: deleteModalName
    });
  }

  const onAward = () => {
    modalservice?.show({
      body: <AwardPrize prize={token.prize as Prize} bid={token} event={event} onAction={ onAction } />,
      options: { className: awardModalName },
      key: awardModalName
    });
  }

  const { amount = 0 } = token;
  const tokenType = amount > 0 ? 'Token' : 'Bid';
  const canAward = authstore?.hasPermission('BOOLEAN.PrizeService.CanAwardPrizes', true);

  return (
      <>
          <span className="icon clickable" onClick={ onEdit } data-tooltip={`Edit ${tokenType}`}>
              <i className="fa-solid fa-pen-to-square"></i>
          </span>
          <span className="icon clickable" onClick={ onDelete } data-tooltip={`Delete ${tokenType}`}>
              <i className="fa-solid fa-trash"></i>
          </span>
          { showAward && canAward && tokenType === 'Bid' && (
            <span className="icon clickable" onClick={ onAward } data-tooltip={"Award Prize"}>
              <i className="fa-solid fa-star"></i>
            </span>
          )}
      </>
  );
} ));
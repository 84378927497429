
import { inject, observer } from 'mobx-react';
import { useContext, useEffect, useState } from 'react';
import { AuthStore } from '@vaettyr/boltcave-auth-client';
import CheckinContext from '../../checkin/checkin.context';
import AuthorizedContext from '../../../auth/authorized.context';
import EventContext from '../event.context';
import UpdateStore from '../../../stores/updateStore';
import PlayerStore from '../../../stores/playerStore';
import { toISO, Today } from '../../../utility/date.utility';
import { modalService, toastService } from '@vaettyr/boltcave-client-core';
import EventCode from '../modals/event.code';
import { cancelRSVPToasts } from '../../../utility/toast.utility';
import { createEvent } from 'ics';
import { toCalendarEvent } from '../../../utility/calendar.utility';

type eventActionProps = {
  authstore?: AuthStore,
  modalservice?: modalService,
  playerstore?: PlayerStore,
  toastservice?: toastService,
  updatestore?: UpdateStore,
}

export default inject('authstore', 'modalservice', 'playerstore', 'updatestore', 'toastservice') ( observer (
({ authstore, modalservice, playerstore, updatestore, toastservice }: eventActionProps) => {
  const { authenticated } = authstore ?? {};
  const { event = {}, self = {} } = useContext(EventContext)
  const checkin = useContext(CheckinContext);
  const authorizedAction = useContext(AuthorizedContext);
  const { canCheckIn, key, dateFor, autoCheckin, isLocationEnabled } = checkin;
  const { checkedIn } = self;
  const [calendarEvent, setCalendarEvent] = useState<string|null>(null);

  // this needs to run on the server?
  useEffect(() => {
    const filename = `${event?.name ?? 'game-night'}.ics`;
    createEvent(toCalendarEvent(event, 4), (error, value) => {
      if(!error) {
        const url = URL.createObjectURL(new File([value], filename, { type: 'text/calendar' }));
        setCalendarEvent(url);
        URL.revokeObjectURL(url);
      }
    })
  }, [event.id])

  useEffect(() => {
    const baseCriteria = !!authenticated && !checkedIn && (dateFor === toISO(event.eventDate, 3));
    const autoQRCode = !!key && !!autoCheckin;
    const autoLocation = !!isLocationEnabled && (!!window.__VARS?.autoCheckin ?? false);
    if(baseCriteria && (autoQRCode || autoLocation)) {
      authorizedAction({action:"checkin", event });
    }
  }, [authenticated, checkedIn, key, dateFor, isLocationEnabled]);

  useEffect(() => {
    if(checkedIn && !updatestore?.isConnected) {
      updatestore?.connect(event, self);
    } else if(!checkedIn && !!updatestore?.isConnected) {
      updatestore?.disconnect();
    }
  }, [checkedIn])

  const isToday = !!event.eventDate && toISO(event.eventDate, 3) === toISO(Today(), 3);
  const showCheckinPrompt = !checkedIn && canCheckIn && isToday;

  const checkIn = () => {
    authorizedAction({action:"checkin", event }, <p>You must be logged in to check in to an event</p>);
  }

  const rsvp = () => {
    authorizedAction({ action: "join", event }, <p>You must be logged in to RSVP</p>);
  }

  const cancelRSVP = () => {
    const { success, failure } = cancelRSVPToasts(toastservice, event?.name);
    playerstore?.leave(event, undefined, self ).then(success).catch(failure);
  }

  const showCode = () => {
    modalservice?.show({
      body: <EventCode event={event} player={self} />,
      key: 'event-code',
      options: { className: 'event-code' }
    });
  }

  return (
    <div>
      { !self?.id && <button className="button is-primary" onClick={rsvp}>RSVP</button> }
      { !!self?.id && <button className="button is-primary" onClick={cancelRSVP}>Cancel RSVP</button>}
      { showCheckinPrompt && <button className="button is-primary" onClick={checkIn}>Check In</button> }
      { !!self?.id && !!calendarEvent && <a className="icon is-large clickable" data-tooltip="Add to Calendar" href={calendarEvent} download={`${event?.name ?? 'game-night'}.ics`}>
        <i className="fa-solid fa-2xl fa-calendar-plus"></i>
      </a>}
      <span className="icon is-large clickable" onClick={showCode} data-tooltip="Get QR Code">
        <i className="fa-solid fa-2xl fa-qrcode"></i>
      </span>
    </div>
  );
}));

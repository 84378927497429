
import { Card, modalService, toastService } from "@vaettyr/boltcave-client-core";
import TokenItem from "../../token/token.item.view";
import { inject, observer } from 'mobx-react';
import { Event, Prize, Token } from '../../../type';
import { awardPrizeToasts } from "../../../utility/toast.utility";
import PrizeStore from "../../../stores/prizeStore";
import TokenStore from '../../../stores/tokenStore';
import usePlayerName from "../../../hooks/usePlayerName";
import { useEffect, useState } from 'react';

type PrizeDeleteProps = {
  prizestore?: PrizeStore,
  tokenstore?: TokenStore,
  prize: Prize,
  bid?: Token,
  event?: Event,
  modalservice?: modalService,
  toastservice?: toastService,
  onAction?: ((e?:React.MouseEvent) => void)
}

export const modalName = "prize-award";

export default inject('prizestore', 'modalservice', 'tokenstore', 'toastservice') ( observer (
  ({ bid, prize, prizestore, modalservice, event, tokenstore, onAction, toastservice }: PrizeDeleteProps ) => {

    useEffect(() => {
      if (!bid) {
        tokenstore?.get({ type: "prize", id: prize.id ?? 0 });
      }
    }, [])

    const { tokens: bids = [] } = tokenstore ?? {};

    const [activeBid, setActiveBid] = useState<Token|undefined>(bid);

    const playerName = usePlayerName({ user: activeBid?.user }, activeBid?.user);

    const toggleBid = (t: Token) => {
      setActiveBid(activeBid?.id ?? 0 === t.id ?? 0 ? undefined : t);
    }

    const onAward = () => {
      const { success, failure } = awardPrizeToasts(toastservice, prize.name, playerName);
      prizestore?.award(prize, activeBid as Token, event)
        .then(() => {
            modalservice?.hide(modalName);
            if (onAction) {
              onAction();
            }
            success();
        })
        .catch(failure);
    }

    const onCancel = () => modalservice?.hide(modalName);

    const actions = (
      <>
        <button type="button" className="card-footer-item button is-primary" onClick={ onAward }>{activeBid ? "Yes" : "Close Bidding with no winner"}</button>
        <button className="card-footer-item button" onClick={onCancel}>No</button>
      </>
    )

    return (
      <Card header={`Award Prize`} footer={actions}>
        { !!activeBid && (
          <span className="confirm-message">
            <p className="is-bold" key="bid-name">Are you sure you want to Award this prize to {playerName}?</p>
            <p key="bid-warning">After this, no further changes can be made to bids on this prize.</p>
          </span>
        )}
        { !activeBid && !bid && bids && (
          <p className="instructions">Select the winning bid.</p>
        )}
        { !bid && bids && (
          <div className="bid-select-list">
            { bids.map((b, index) => <TokenItem user token={b} key={`prize-bid-${index}`} edit={false} onClick={() => toggleBid(b)} className={`clickable ${activeBid?.id === b.id ? 'selected':''}`}/>)}
          </div>
        )}
      </Card>
    )
  }
));
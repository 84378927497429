import { inject, observer } from 'mobx-react';
import { useState, useEffect, useMemo } from 'react';
import EventContext from '../event.context';
import EventDetailView from '../detail/event.detail.view';
import { Content, Event } from '../../../type';
import PlayerStore from '../../../stores/playerStore';
import PrizeStore from '../../../stores/prizeStore';
import { modalService, toastService } from '@vaettyr/boltcave-client-core';
import { AuthStore, User, Secured } from '@vaettyr/boltcave-auth-client';
import ContentStore from '../../../stores/contentStore';
import EditPrize, { modalName as prizeModalName } from '../../prize/modals/prize.modal.edit';
import Topic from './event.topic.view';
import PrizeThumbnail from '../../prize/prize.pod.view';
import AuthorizedContext from '../../../auth/authorized.context';
import { useContext } from 'react';
import SwipeableViews from 'react-swipeable-views';
import useEventPermissions from '../../../hooks/useEventPermissions';
import EventStore from '../../../stores/eventStore';
import { beginEventToasts } from '../../../utility/toast.utility';
import { useNavigate } from 'react-router-dom';

type EventMenuViewProps = {
  authstore?: AuthStore,
  contentstore?: ContentStore,
  event: Event,
  eventstore?: EventStore,
  prizestore?: PrizeStore,
  modalservice?: modalService,
  playerstore?: PlayerStore,
  toastservice?: toastService
}

export default inject('authstore', 'contentstore', 'eventstore', 'prizestore', 'modalservice', 'playerstore', 'toastservice') ( observer (
({ authstore, contentstore, event, eventstore, prizestore, modalservice, playerstore, toastservice }: EventMenuViewProps) => {

  const { user = {} } = authstore ?? {};
  const { prizes = [] } = prizestore ?? {};
  const { contents: { event: eventTopics = {} } = {} } = contentstore ?? {};
  const topics = eventTopics ? ((eventTopics as Record<number, Content[]>)[event.id ?? 0] ?? []).slice() : [];

  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [prizesLoaded, setPrizesLoaded] = useState<boolean>(false);
  useEffect(() => {
    if (!prizesLoaded) {
      const params = event.locked ? { withBids: true, event } : { active: true, withBids: true, hasBids: true }
      prizestore?.get(params)
        .then(() => {
          setPrizesLoaded(true);
        })
    }
  }, []);
  const navigate = useNavigate();

  const self = playerstore?.players.find((p) => ((p.user as User)?.id ?? 0) === (user?.id ?? -1) && p.event?.id === event.id);
  const { canEditEvent } = useMemo(() => useEventPermissions({ authstore, event, self }), [authstore?.user?.id, event?.id, self?.id]);
  const authorizedAction = useContext(AuthorizedContext);
  const addTopic = () => authorizedAction({ action: "addtopic", event }, <p>You must be logged in to add a topic</p>)

  const addPrize = () => {
    modalservice?.show({
      body:<EditPrize />,
      key: prizeModalName,
      options: { className: prizeModalName }
    });
  }

  const beginEvent = () => {
    const { success, failure } = beginEventToasts(toastservice, event.name);
    event.locked = true;
    eventstore?.save(event)
      .then(success)
      .catch(failure);
  }

  const viewPrizes = () => navigate('/prizes');

  return (
    <div className="workshop">
      <EventContext.Provider value={{event, self}}>
        <EventDetailView />
        { canEditEvent && !event.locked && !event.cancelled && <button className="begin-event button is-success dekstop" onClick={ beginEvent }>Begin Event</button> }
        <div className="tabs is-centered is-large">
          <ul>
            <li className={`${activeIndex === 0 ? "is-active":""}`} key="topics-tab">
              <a onClick={() => setActiveIndex(0)}>Topics</a>
            </li>
            <li className={`${activeIndex === 1 ? "is-active":""}`} key="prizes-tab">
              <a onClick={() => setActiveIndex(1)}>Prizes</a>
            </li>
          </ul>
        </div>
        <SwipeableViews index={activeIndex}>
          <div className="topic-container">
            <h3 className="subtitle is-3">Topics</h3>
            <div className="topics-list">
              { !event.locked && <button className="button is-primary" onClick={addTopic}>Propose Topic</button> }
              { topics.sort((a,b) => (b.metadata?.upvotes?.length ?? 0) - (a.metadata?.upvotes?.length ?? 0))
                .map((topic, index) => <Topic key={`topic-${index}-${topic.id}`} topic={topic} />)}
            </div>
          </div>
          <div className="prize-container">
            <h3 className="subtitle is-3">Prizes</h3>
            {!event.locked && <Secured claims={[{key: "ENUM.PrizeService.CanEditPrizes", value: "ALL"}, {key: "ENUM.PrizeService.CanEditPrizes", value: "OWN"}]}>
              <button className="button is-primary" onClick={addPrize}>Add Prize</button>
            </Secured>}
            { prizesLoaded && <div className="prize-list">
              { prizes.map((prize, index) => <PrizeThumbnail prize={ prize } key={`prize-${index}-${prize.id}`} event={event} />) }
            </div> }
            { prizesLoaded && !event.locked && prizes.length === 0 && <p>There are no prizes with active bids</p> }
            { !event.locked && <button className="button is-primary" onClick={viewPrizes}>See all available prizes</button>}
          </div>
        </SwipeableViews>
      </EventContext.Provider>
    </div>
  );
}));
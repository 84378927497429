import { createContext } from 'react';
import { Game, Player } from '../../type';
import { useGameResponse } from '../../hooks/useGame';

type gameContext = {
    host?: Player,
    self?: Player,
    players?: Player[],
    game?: Game,
    details?: useGameResponse
}

export default createContext<gameContext>({});
import { Card, Select, modalService, toastService } from "@vaettyr/boltcave-client-core";
import { inject, observer } from 'mobx-react';
import { Formik, Form } from 'formik';
import { Event, Game, Player } from '../../../type';
import SystemPreference from "../../system/form/system.preference";
import PlayerStore from "../../../stores/playerStore";
import { addPlayerToasts } from "../../../utility/toast.utility";
import { User } from "@vaettyr/boltcave-auth-client";
import PlayerUserLookup from "../player.user.lookup";
import GameStore from "../../../stores/gameStore";

type PlayerModalProps = {
    modalservice?: modalService,
    playerstore?: PlayerStore,
    toastservice?: toastService,
    gamestore?: GameStore,
    event: Event,
    player?: Player
    game?: Game,
    games?: Game[],
    waitlist?: boolean,
}

export default inject('gamestore', 'modalservice', 'playerstore', 'toastservice') ( observer(
( { gamestore, modalservice, playerstore, toastservice, waitlist, game, event, player, games }: PlayerModalProps ) => {

    const savePlayer = ({name, game: selectedGame, user, systemPreference }: {name?: string, game?: string, user?: User|string, systemPreference?: Record<number, number[]>}) => {
        const gameId = selectedGame ? parseInt(selectedGame, 10) : undefined;
        const targetGame = selectedGame ? games?.find((g) => g.id === gameId) : game;
        const { success, failure } = addPlayerToasts(toastservice, name, targetGame?.title, waitlist);
        const playerName = user && typeof(user) === 'string' ? user : name;
        const userObj = user && typeof(user) === 'object' ? user : undefined;
        playerstore?.join(event, targetGame, {name: playerName, id: player?.id, user: userObj?.id, systemPreference}, waitlist)
        .then(({deleted}) => {
            // if a game was deleted, we need to update that here
            if(!!deleted) {
                gamestore?.clear(deleted);
            }
            success();
        }).catch(failure).finally(() => modalservice?.hide('player-modal'));
    };

    const onCancel = () => {
        modalservice?.hide("player-modal");
    }

    const initialValues = {
        name: '',
        game: undefined
    }

    const gamesOptions = ["", ...games?.map((g) => ({
        value: g.id as number,
        label: `${g.systems?.map((system) => typeof(system) === 'object' ? system.name : system).join(' or ')} ${g.title ?? ''}`
    })) ?? []];

    const getUserMessage = (activeGame?: Game, user?: string|User): {message?:string, invalid?: boolean} => {
        const userId = !!user ? (typeof(user) === 'string' ? parseInt(user, 10) : user.id) : undefined;
        const selectedPlayer = !!userId ? playerstore?.players.find((p) => p.user?.id === userId) : undefined;
        const userName = selectedPlayer ?
            ( !!selectedPlayer.user ?
                ( selectedPlayer.user.Username ? selectedPlayer.user.Username : `${selectedPlayer.user.FirstName} ${selectedPlayer.user.LastName}` ) :
            selectedPlayer.name )
            : undefined;
        if(selectedPlayer?.host?.id === activeGame?.id || selectedPlayer?.game?.id === activeGame?.id) {
            return selectedPlayer?.host?.id === activeGame?.id ?
                { message: `${userName} is already the host of this game`, invalid: true } :
                { message: `${userName} is already in this game.`, invalid: true };
        } else if(((!!selectedPlayer?.host && !selectedPlayer?.host?.isFlex) || !!selectedPlayer?.game) && !waitlist) {
            return !!selectedPlayer?.host ?
                { message: `${userName} is the host of another game. If you proceed, that game will be removed.`} :
                { message: `${userName} is in another game. If you proceed, they will be moved to this game.` };
        }
        return {};
    }

    return (
        <Formik initialValues={ initialValues } onSubmit={ savePlayer }>
            {({ isValid, values, handleSubmit }) => {
                const { game: selectedGameId = "", user } = values;
                const selectedGame = game ?? (selectedGameId ? (games?.find((g) => g.id === parseInt(selectedGameId, 10))) : undefined);
                const { message, invalid } = getUserMessage(selectedGame, user);

                const actions = (
                    <>
                        <button type="button" className="card-footer-item button is-primary" disabled={!isValid || invalid} onClick={()=>{handleSubmit()}}>Add</button>
                        <button className="card-footer-item button" onClick={onCancel}>Cancel</button>
                    </>
                )

                return (
                    <Card header={`Add Player to ${game?.title ?? 'Game'}${waitlist? ' waitlist' : ''}`} footer={actions}>
                        <Form>
                            <PlayerUserLookup name="user" label="User" required />
                            { message && (
                                <div className={`has-text-${invalid ? 'danger': 'warning'}`}>
                                <span className="icon">
                                    <i className="fa-solid fa-triangle-exclamation"></i>
                                </span>
                                {message}
                            </div>
                            )}
                            { games && (
                                <Select name="game" label="Game" options={gamesOptions} required />
                            )}
                            { selectedGame && (
                                <SystemPreference name="systemPreference" label="System Preference(s)" game={selectedGame} className="system-preference"/>
                            )}
                        </Form>
                    </Card>
                );
            }}
        </Formik>
    )
}));
import { Card, modalService } from "@vaettyr/boltcave-client-core";
import { inject, observer } from 'mobx-react';
import ReactMarkdown from 'react-markdown';

type PreviewProps = {
  text?: string,
  title?: string,
  modalservice?: modalService
}

export const modalName = 'preview-markdown';

export default inject('modalservice') (observer(({ text = "", title = "Preview Markdown", modalservice }: PreviewProps) => {

  const hidePreview = () => modalservice?.hide(modalName);

  return (
    <Card header={title} footer={<button className="card-footer-item button" onClick={hidePreview}>OK</button>}>
      <div className="markdown"><ReactMarkdown>{text}</ReactMarkdown></div>
    </Card>
  );
}));

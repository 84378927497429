import { Card, modalService, toastService } from "@vaettyr/boltcave-client-core";
import { inject, observer } from 'mobx-react';
import { Event, Game, Player } from '../../../type';
import PlayerStore from "../../../stores/playerStore";
import GameStore from '../../../stores/gameStore';
import { removePlayerToasts } from "../../../utility/toast.utility";

type PlayerModalProps = {
    gamestore?: GameStore,
    modalservice?: modalService,
    playerstore?: PlayerStore,
    toastservice?: toastService,
    event: Event,
    game: Game,
    player: Player,
    isSelf?: boolean,
    name?: string
}

export const modalName = 'player-remove';

export default inject('gamestore', 'modalservice', 'authstore', 'playerstore', 'toastservice') ( observer(
( { gamestore, modalservice, playerstore, event, game, player, toastservice, isSelf, name }: PlayerModalProps ) => {

    const isWaitlist = player?.waitlist?.includes(game?.id ?? 0);
    const title = isSelf ? "Leave Game" : "Remove Player";
    const prompt = `Are you sure you want to ${isSelf ? 'leave' : `remove ${name} from`} this ${isWaitlist ? 'game\'s waitlist' : 'game'}?`;
    const buttonText = !!isSelf ? 'Ok' : 'Remove';
    const { host } = player;
    const thisHost = !!host && host?.id === game.id && !host?.isFlex;
    const hostPrompt = thisHost ? `${isSelf ? 'You are' : `${name} is`} the host of this game. If you ${isSelf ? 'leave' : 'remove them'} then this game will be cancelled.`: undefined;

    const leaveGame = () => {
        const payload = { name, id: player?.id };
        const { success, failure } = removePlayerToasts(toastservice, game?.title, !isSelf ? name : undefined);
        playerstore?.leave(event, game, payload, isWaitlist)
        .then((response) => {
            if(typeof(response) === 'object') {
                const { deleted } = response;
                if(!!deleted) {
                    gamestore?.clear(deleted);
                }
            }
            success();
        }).catch(failure).finally(() => modalservice?.hide(modalName));
    };

    const onCancel = () => {
        modalservice?.hide("player-modal");
    }

    const actions = (
        <>
            <button type="button" className="card-footer-item button is-primary" onClick={leaveGame}>{ buttonText }</button>
            <button className="card-footer-item button" onClick={onCancel}>Cancel</button>
        </>
    );

    return (
        <Card header={ title } footer={actions}>
            { prompt && (
                <p className="has-text-centered">{ prompt }</p>
            )}
            {hostPrompt && (
                <p className="has-text-centered">{hostPrompt}</p>
            )}
        </Card>
    );
}));
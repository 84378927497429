import { Event, Game, Token } from '../../../type';
import { Card } from '@vaettyr/boltcave-client-core';
import AttendeesView from '../attendees/event.attendees.view';
import PlayerStore from '../../../stores/playerStore';
import EventStore from '../../../stores/eventStore';
import TokenStore from '../../../stores/tokenStore';
import { inject, observer } from 'mobx-react';
import { modalService, toastService } from '@vaettyr/boltcave-client-core';
import { sort, filter, ChainSort, ChainFilter } from '../attendees/event.attendees.utility';
import { Formik } from 'formik';
import { beginEventToasts } from '../../../utility/toast.utility';

type EventStartProps = {
  event: Event;
  eventstore?: EventStore;
  tokenstore?: TokenStore;
  modalservice?: modalService;
  playerstore?: PlayerStore;
  toastservice?: toastService;
}

export const modalName = 'start-event';

export default inject('eventstore', 'tokenstore', 'modalservice', 'playerstore', 'toastservice') ( observer (
  ({ event, eventstore, tokenstore, modalservice, playerstore, toastservice }: EventStartProps) => {

    const { players = [] } = playerstore ?? {};

    // fix this to set better starting amounts
    const initialValues = players
      .filter(ChainFilter(filter.host, filter.user))
      .sort(ChainSort(sort.checkIn, sort.name))
      .map(({checkedIn, user, host}) => {
        const { isFlex, minPlayers = 2 } = (host as Game);
        const gamePlayers = players.filter(({ game, checkedIn: playerCheckedIn }) => (
          (game?.id ?? -1) === (host?.id ?? 0) &&
          (playerCheckedIn || !isFlex)
        ));
        const amount = checkedIn && gamePlayers.length >= minPlayers ? 1 : 0;
        return { user: user?.id, game: host?.id, event: event?.id, amount, description: event.name };
      });

    const save = (values: Token[]) => {
      const { success, failure } = beginEventToasts(toastservice, event.name);
      tokenstore?.save(values.filter((v) => v.amount !== 0))
        .then(() => {
          event.locked = true;
          eventstore?.save(event)
            .then(() => {
              modalservice?.hide(modalName);
              success();
            })
            .catch(failure)
        })
        .catch(failure);
    }

    const cancel = () => modalservice?.hide(modalName);

    return (
      <Formik initialValues={initialValues} onSubmit={save}>
        {({handleSubmit}) => {
          const actions = (
            <>
              <button className="card-footer-item button" onClick={cancel}>Cancel</button>
              <button type="button" className="card-footer-item button is-primary" onClick={() => handleSubmit()}>Start Event</button>
            </>
          );
          return (
            <Card header="Start Event" footer={actions}>
              <div className='instructions'>
                <p className="emphasis">Please confirm the tokens that will be awarded to each of the hosts</p>
                <p>When you click 'Start Event', the hosts will be awarded tokens and no new games may be added</p>
              </div>
              <AttendeesView event={event} mode='token' filter={ChainFilter(filter.host, filter.user)} sort={ChainSort(sort.checkIn, sort.name)} />
            </Card>
          );
        }}
      </Formik>
    );
  }
));
import { ReactNode, Fragment } from 'react';
import { System } from "../../../type";

type SystemIconProps = {
  children?: ReactNode | ReactNode[],
  systems?: System[],
  mode?: 'short'|'detail',
}

export default ({ children, systems, mode = 'detail' }: SystemIconProps ) => {
  // need to handle multiple systems a little more elegantly
  // also depends on where we're rendering, don't it?

  const renderSystem = (system:System) => {
    const { name, shortName } = system;
    if(!shortName) return name;
    return (
      <>
        <span className="long-name">{name}</span>
        <span className="short-name">{shortName}</span>
      </>
    )
  }

  return (
    <div className={`system-icon ${mode}`}>
      { children }
      {((systems?.length ?? 0) === 0) && (
        <span className={`system-name`}>
          Unknown System
        </span>
      )}
      {((systems?.length ?? 0) === 1 || (mode === 'short' && (systems?.length ?? 0) >= 1)) && (
        <span className={`system-name`}>
          {renderSystem((systems as System[])[0])}
        </span>
      )}
      {(systems?.length ?? 0) > 1 && mode === 'detail' && (
        <span className="systems-container">
          {systems?.map((system, index) => (
            <Fragment key={`system-${index}`}>
              <span className={`system-name`}>
                {renderSystem(system)}
              </span>
              {index < (systems.length - 1) && (
                <span className="system-or">Or</span>
              )}
            </Fragment>
          ))}
        </span>
      )}
      {(systems?.length ?? 0) > 1 && mode === 'short' && (
        <span className="systems-more">+{(systems?.length ?? 0) - 1} more</span>
      )}
    </div>
  );
}
import { observer, inject } from 'mobx-react';
import { Card, toastService, toastStyle, modalService } from '@vaettyr/boltcave-client-core';
import ContentStore from '../../stores/contentStore';
import { Content } from '../../type';

export type ContentType = "about" | "faq" | "link";

type EditContentProps = {
    toastservice?:toastService,
    modalservice?:modalService,
    contentstore?: ContentStore,
    content?: Content,
    type?: ContentType
}

export const modalName = 'delete-content';

export default inject( 'contentstore', 'toastservice', 'modalservice','authstore' )
    ( observer(({ toastservice, contentstore, modalservice, content, type }: EditContentProps ) => {

    const onCancel = () => {
        modalservice?.hide(modalName);
    }

    const onDelete = () => {
      contentstore?.remove({ id: content?.id, type: content?.type })
      .then(() => {
        toastservice?.show({
          style: toastStyle.success,
          message: `${content?.type ?? "Content"} deleted.`,
          dismissable: true,
          lifespan: 5
        });
        modalservice?.hide(modalName);
      })
      .catch(() => {
        toastservice?.show({
          style: toastStyle.danger,
          message: `Unknown error deleting ${content?.type ?? "Content"}`,
          dismissable: true
        });
      });
    }

    const isEmpty = (item: string|Record<string, string>): boolean => typeof(item) === 'string' ? !item : Object.keys(item).reduce<boolean>((empty, key) => empty && isEmpty(item[key]), true);

    const title = `Delete ${content?.type ?? type ?? 'Content'}`;
    const actions = (
    <>
      <button type="button" className="card-footer-item button is-primary" onClick={onDelete}>Delete</button>
      <button className="card-footer-item button" onClick={onCancel}>Cancel</button>
    </>
    );

    return (
      <Card header={title} footer={actions}>
        <p>Are you sure you want to delete {content?.type ?? "Content"}</p>
      </Card>
    );
}));
import PlayerStore from "../../../stores/playerStore";
import { Player } from '../../../type';

type AttendeesControllerArgs = {
  filter?: (player:Player) => boolean;
  playerstore?: PlayerStore;
  sort?: (a:Player, b:Player) => number;
}

type AttendeesController = {
  players: Player[];
}

export default ({ filter, playerstore, sort }: AttendeesControllerArgs): AttendeesController => {
  const { players = [] } = playerstore ?? {};

  const filtered = !!filter ? players.filter(filter) : players.slice();
  const sorted = !!sort ? filtered.sort(sort) : filtered;

  return {
    players: sorted
  };
}


export const weekDays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

const isoRegex = /^(\d{4})-(\d{1,2})(?:-(\d{1,2})(?:T(\d{2})\:(\d{2})\:(\d{2})\.(\d{3})Z)?$)?/;

// this will take a UTC time, but interpret it is as local time (without timezone shift)
export const fromString = (input?:string):Date|undefined => {
  if(!input) return;
  const groups = isoRegex.exec(input);
  if(!groups) return;
  groups.shift();
  const parsed = groups
    .map((entry, index) => parseInt(entry, 10) + (index === 1 ? -1 : 0))
    .filter(i => !Number.isNaN(i));
  if(parsed.length < 2 || parsed.length > 7) return;
  return new Date(...(parsed as [])); // this is so stupid
}

// this will transform local time to the corresponding ISO without timezone shift
export const toISO = (date?:Date, length:2|3|7 = 7):string|undefined => {
  if(!date) return;
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2,'0');
  if(length === 2) return `${year}-${month}`;
  const day = date.getDate().toString().padStart(2,'0');
  if(length === 3) return `${year}-${month}-${day}`;
  const hour = date.getHours().toString().padStart(2,'0');
  const minute = date.getMinutes().toString().padStart(2,'0');
  const second = date.getSeconds().toString().padStart(2,'0');
  const milli = date.getMilliseconds().toString().padStart(3, '0');
  return `${year}-${month}-${day}T${hour}:${minute}:${second}.${milli}Z`;
}

export const addMonths = (date:Date, offset:number = 1):Date => {
  const newDate = new Date(date);
  newDate.setMonth(newDate.getMonth() + offset);
  return newDate;
}

export const addDays = (date:Date, offset: number = 1):Date => {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + offset);
  return newDate;
}

export const Today = ():Date => {
  const today = new Date(Date.now());
  return fromString(toISO(today,3)) as Date;
}
import { modalService } from "@vaettyr/boltcave-client-core";
import { inject, observer } from 'mobx-react';
import { Content } from "../../../type";
import EditContent from '../../content/content.edit.view';
import { modalName } from '../../content/content.edit.controller';
import DeleteContent, { modalName as deleteModalName } from "../../content/content.delete";
import { useState } from "react";

type TopicMenuProps = {
  topic: Content;
  modalservice?: modalService;
}

export default inject('modalservice') ( observer (({ modalservice, topic }: TopicMenuProps) => {

  const [isActive, setIsActive] = useState<boolean>(false);
  const menuClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    setIsActive(!isActive);
  }

  const editTopic = (e:React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    modalservice?.show({
      body: <EditContent type="topic" linkType='event' content={topic} />,
      key: modalName,
      options: { className: modalName }
    });
    setIsActive(false);
  }

  const deleteTopic = (e:React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    modalservice?.show({
      body: <DeleteContent content={topic} />,
      key: deleteModalName,
      options: { className: deleteModalName }
    });
    setIsActive(false);
  }

  return (
    <span className={`dropdown clickable is-right${isActive ? ' is-active' : ''}`}>
      <span className="icon is-large is-primary dropdown-trigger" onClick={menuClick}>
        <i className="fa-solid fa-ellipsis-vertical"></i>
      </span>
      <div className="dropdown-menu">
        <div className="dropdown-content">
            <div className="dropdown-item clickable" onClick={editTopic}>Edit</div>
            <div className="dropdown-item clickable" onClick={deleteTopic}>Delete</div>
        </div>
      </div>
    </span>
  );
}));
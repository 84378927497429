import { inject, observer } from 'mobx-react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import EventStore from '../../stores/eventStore';
import DateView from '../../components/date/date.view';
import { AuthStore } from '@vaettyr/boltcave-auth-client';
import useDates from '../../hooks/useDates';
import { addDays, addMonths, toISO, weekDays } from '../../utility/date.utility';

type CalendarProps = {
    eventstore?: EventStore,
    authstore?: AuthStore
}

export default inject('eventstore', 'modalservice','authstore')
(observer(({ eventstore, authstore }:CalendarProps) => {
    const navigate = useNavigate();
    const [date, from, to] = useDates() ?? [];
    const { busy } = eventstore ?? {};
    const span = Math.ceil((to.getTime() - from.getTime()) / (1000 * 3600 * 24)) + 1;

    const firstOfMonth = new Date(date);
    firstOfMonth.setDate(1);

    useEffect(() => {
        eventstore?.getRange({ from, to, instantiate: authstore?.hasPermission('ENUM.EventService.CanEditEvents', "ALL") });
    }, [from, to]);

    const previousMonth = () => {
        navigate({search: `date=${toISO(addMonths(firstOfMonth, -1), 2)}`});
    };

    const nextMonth = () => {
        navigate({search: `date=${toISO(addMonths(firstOfMonth), 2)}`});
    };

    return (
        <main className="calendar">
            {busy && (<progress className="progress is-small is-primary" max="100">15%</progress>)}
            <section className="month">
                <span className="icon clickable" onClick={ previousMonth }>
                    <i className="fa-solid fa-chevron-left"></i>
                </span>
                <h2 className="month-name">{date.toLocaleDateString('default', { month: 'long', year:'numeric'})}</h2>
                <span className="icon clickable" onClick={ nextMonth }>
                    <i className="fa-solid fa-chevron-right"></i>
                </span>
            </section>
            <section className="view">
                { weekDays.map((day, index) => <div className="month-week-day" key={`weekday-${index}`}>{day}</div>)}
                {([...Array(span).keys()]).map((index) => {
                    return <DateView key={`date-${index}`} month={ date.getMonth() } date={ addDays(from, index) }/>
                })}
            </section>
        </main>
    );
}));
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { Today, toISO } from '../utility/date.utility';
import { useGeolocated } from 'react-geolocated';
import { useState, useEffect } from 'react';

const keyPrefix = 'ATLRPG.PASSKEY';

export type checkinKeyProps = {
    canCheckIn: boolean,
    key?: string,
    coords?: GeolocationCoordinates,
    isPositionEnabled: boolean|null,
    isLocationEnabled: boolean|null,
    getPosition?: (() => void),
    dateFor?: string,
    autoCheckin?: boolean,
}

export default (): checkinKeyProps => {
    const [isPositionEnabled, setPositionEnabled] = useState<boolean|null>(null);
    const [autoCheckin, setAutoCheckin] = useState<boolean>(false);

    const geoConfig = {
        positionOptions: {
            enableHighAccuracy: true,
            maximumAge: 0
        },
        watchPosition: false,
        suppressLocationOnMount: true,
        isOptimisticGeolocationEnabled: false,
        onError: () => { setPositionEnabled(false)},
        onSuccess: () => { setPositionEnabled(true)}
    };
    const { coords, isGeolocationAvailable, isGeolocationEnabled, getPosition } = useGeolocated(geoConfig);
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const { hash } = useLocation();

    const dateFor = toISO(Today(), 3);
    const storageKey = `${keyPrefix}.${dateFor} ?? "ERROR"}`;
    const checkinKey = searchParams.get('key');
    const savedKey = sessionStorage.getItem(storageKey);
    const autoParam = searchParams.get('auto')?.toLowerCase() === 'true' ?? false;

    useEffect(() => {
        if(checkinKey && savedKey) {
            searchParams.delete('key');
            if(autoParam) {
                searchParams.delete('auto');
                setAutoCheckin(true);
            }
            setSearchParams(searchParams,{replace:true});
            navigate({search:searchParams.toString(), hash }, {replace:true});
        }
    }, [checkinKey, savedKey, autoParam]);

    if(checkinKey && (!savedKey || savedKey !== checkinKey)) {
        sessionStorage.setItem(storageKey, checkinKey);
    }

    const storedKey = sessionStorage.getItem(storageKey);
    const canCheckIn = !!storedKey || isGeolocationAvailable ;

    return {
        canCheckIn,
        coords,
        isPositionEnabled,
        isLocationEnabled: isGeolocationEnabled,
        getPosition,
        key: storedKey ? storedKey : undefined,
        dateFor: !!storedKey ? dateFor : undefined,
        autoCheckin
    }
}
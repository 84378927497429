import { observable, action, computed, makeObservable, runInAction } from 'mobx';
import axios from 'axios';
import { AuthStore } from '@vaettyr/boltcave-auth-client';
import { storeHelpers } from '@vaettyr/boltcave-client-core';
import { Token, TokenDefinition, GetResponse } from '../type';
import fromObservable from '../utility/data.utility';

type tokenSearchType = "event"|"user"|"prize"|"balance";

export default class TokenStore
{
    @observable tokens: Token[] = [];
    @observable busy: boolean = false;
    @observable count: number|undefined = undefined;

    private route:string;
    @observable private _balance: number|null = null;

    constructor()
    {
        makeObservable(this);
        this.route = storeHelpers.GetEndpoint("RPGService");
    }

    @computed get balance(): number {
      return this._balance !== null && this._balance !== undefined ? this._balance : 0;
    }

    @action init = (id: number) => {
      if (this._balance !== null) return;
      const { __RPG: { data: { user: { balance = null } = {} } = {} } = {} } = window as any;
      if (balance !== null) {
          const parsed = parseInt(balance, 10);
          runInAction(() => {
            this._balance = !Number.isNaN(parsed) ? parsed : 0;
          });
      } else {
          this.getBalance(id)
          .then((response) => {
            runInAction(() => {
              this._balance = response;
            })
          });
      }
    }

    @action getBalance = async (id: number): Promise<number> => {
      return new Promise((resolve) => {
        this.get({ type: 'balance', id })
        .then((response) => {
          const parsed = response && response.length > 0 ? parseInt(response[0].amount?.toString() ?? "0", 10) : 0;
          resolve(!Number.isNaN(parsed) ? parsed : 0);
        })
      })
    }

    @action get = async ({ type, id }: { type: tokenSearchType, id: number }, paging?: { size: number, offset: number }, sort?: { column: 'amount'|'created', direction?: 1|-1 } ): Promise<Token[]> => {
      this.busy = true;
      return new Promise((resolve, reject) => {
        const page = !!paging ? `pagesize=${paging.size}&page=${paging.offset}` : '';
        const order = !!sort ? `sort=${sort.column}&direction=${(sort.direction ?? 1) > 0 ? 'asc' : 'desc'}` : '';
        const query = !!page || !!order ? `?${page ? page : ''}${page && order ? '&' : ''}${order ? order : ''}` : '';
        axios.get<GetResponse<Token>|Token[]>(`${this.route}api/v1/token/${type}/${id}${query}`)
          .then(({ data }) => {
            const records = type === 'user' ? (data as GetResponse<Token>).records : data as Token[];
            runInAction(() => {
              if (type !== 'balance') {
                if ((paging?.offset ?? 0) > 0) {
                  this.tokens = [...this.tokens, ...TokenStore.mapData(records)];
                } else {
                  this.tokens = TokenStore.mapData(records);
                }
                this.count = (data as GetResponse<Token>).count;
              }
            });
            resolve(records);
          })
          .catch(err => reject(err))
          .finally(() => {
              runInAction(() => { this.busy = false; });
          });
      });
    }

    @action save = async (token: Token|Token[], refreshId?: number): Promise<Token> =>
    {
        this.busy = true;
        return new Promise((resolve, reject) => {
            const isUpdate = !Array.isArray(token) && !!token.id;
            const batch = Array.isArray(token);
            const call = isUpdate ?
                axios.post<Token>(`${this.route}api/v1/token`, fromObservable(token, TokenDefinition), AuthStore.GetConfig()):
                axios.put<Token>(`${this.route}api/v1/token${batch ? '?batch=true':''}`, batch ? token.map((t) => fromObservable(t, TokenDefinition)) : fromObservable(token, TokenDefinition), AuthStore.GetConfig());

            call.then(({ data }) => {
              runInAction(() => {
                if(isUpdate) {
                  const index = this.tokens.findIndex((t) => t.id === token.id);
                  if(index >= 0) {
                    this.tokens = [...this.tokens.slice(0, index), TokenStore.processToken(data), ...this.tokens.slice(index + 1)];
                  }
                } else {
                  const toAdd = batch ? data as Token[] : [data]
                  this.tokens = [...this.tokens, ...TokenStore.mapData(toAdd)];
                }
              });
              resolve(data);
            })
            .catch(err => reject(err))
            .finally(() => {
                runInAction(() => {
                  this.busy = false;
                  if (refreshId) {
                    this.getBalance(refreshId)
                      .then((newBalance) => {
                        this._balance = newBalance;
                      });
                  }
                });
            });
        });
    }

    @action delete = async(item:Token, refreshId?: number):Promise<boolean> => {
      return new Promise((resolve, reject) => {
        this.busy = true;
        axios.delete(`${this.route}api/v1/token/${item.id}`, AuthStore.GetConfig())
          .then((response) => {
            runInAction(() => {
              const index = this.tokens.findIndex((t) => t.id === item.id);
              if(index >= 0) {
                this.tokens = [...this.tokens.slice(0, index), ...this.tokens.slice(index + 1)];
              }
            });
            resolve(response.data);
          })
          .catch((err) => {
            reject(err.response);
          })
          .finally(() => {
            runInAction(() => {
                this.busy = false;
                if (refreshId) {
                  this.getBalance(refreshId)
                    .then((newBalance) => {
                      this._balance = newBalance;
                    });
                };
            });
          });
      });
    }

    static processToken = (token: Token):Token => {
      token.created = token.created && typeof token.created === 'string' ? new Date(token.created) : token.created;
      token.updated = token.updated && typeof token.updated === 'string' ? new Date(token.updated) : token.updated;
      return token;
    }

    static mapData = (data?: Token[]):Token[] => {
      return data?.map(TokenStore.processToken) ?? [];
    }
}
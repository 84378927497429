import { Content } from '../../type';
import ReactMarkdown from 'react-markdown';
import { Collapsible, modalService } from '@vaettyr/boltcave-client-core';
import { AuthStore } from '@vaettyr/boltcave-auth-client';
import { MediaImage } from '@vaettyr/boltcave-media-client';
import { inject, observer } from 'mobx-react';
import EditContent from './content.edit.view';
import { modalName } from './content.edit.controller';
import DeleteContent from './content.delete';

type ContentViewProps = {
  authstore?: AuthStore,
  content: Content,
  className?: string,
  modalservice?: modalService
};

export default inject('authstore', 'modalservice') ( observer (({ authstore, content, className, modalservice }: ContentViewProps) => {

  const { body = "", metadata: { display, icon, uri = "", heading = "" } = {} } = content;

  const canEdit = authstore?.hasPermission("ENUM.ContentService.CanEditContent", "ALL") ||
    (authstore?.hasPermission("ENUM.ContentService.CanEditContent", "OWN") && authstore?.user?.id === content.id);

  const showEdit = () => modalservice?.show({
    body: <EditContent content={content} />,
    key: modalName,
    options: { className: modalName }
  });

  const showDelete = () => modalservice?.show({
    body: <DeleteContent content={content} />,
    key: 'delete-content',
    options: { className: 'delete-content' }
  })

  const editButton = !!canEdit && (
    <span className="icon-set">
      <span className="icon clickable" onClick={ showDelete } data-tooltip={`Delete ${content.type}`}>
          <i className="fa-solid fa-trash"></i>
      </span>
      <span className="icon clickable" onClick={ showEdit } data-tooltip={`Edit ${content.type}`}>
          <i className="fa-solid fa-pen-to-square"></i>
      </span>
    </span>
  );

  const linkHeader = (
    <span className="content-header">
      {heading}
      {editButton}
    </span>
  );

  switch( display ) {
    case "accordion":
      return <Collapsible header={linkHeader} className={className}><ReactMarkdown>{body}</ReactMarkdown></Collapsible>
    case "link":
      return (
        <>
          {editButton}
          <a className={`content-link${className? ` ${className}` : ''}`} href={uri}>
            { !!icon && <MediaImage src={icon} altText={body} rounded mode="crop" size={32} />}
            <span>{heading}</span>
          </a>
        </>
      );
    default:
      return <>{editButton}<div className="markdown content-body"><ReactMarkdown className={className}>{body}</ReactMarkdown></div></>;
  }
} ));
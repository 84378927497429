import { Event, Game, Player } from '../../../type';
import PlayerIcon from '../../player/widgets/player.icon';
import usePlayerName from '../../../hooks/usePlayerName';
import { inject, observer } from 'mobx-react';
import PlayerStore from '../../../stores/playerStore';
import { toastService, NumberInput } from '@vaettyr/boltcave-client-core';
import { checkinToasts } from '../../../utility/toast.utility';

type AttendeePlayerProps = {
  event: Event;
  game?: Game;
  index?: number;
  mode?: 'view' | 'status' | 'token';
  player: Player;
  playerstore?: PlayerStore;
  toastservice?: toastService
}

export default inject('playerstore', 'toastservice') (observer (
  ({ event, game, mode = 'view', player, playerstore, toastservice, index = 0 }: AttendeePlayerProps) => {
    const { checkedIn } = player;

    const playerName = usePlayerName(player, player.user);

    const togglePlayer = ():void => {
      const { success: checkinSuccess, failure: checkinFailure } = checkinToasts(toastservice, playerName, checkedIn);
      playerstore?.checkin(event, game, player, checkedIn)
      .then(checkinSuccess).catch(checkinFailure).finally(() => close());
    }

    const icon = checkedIn ? 'fa-solid fa-circle-check active' : 'fa-regular fa-circle-check';
    const showBadges = mode !== 'token';

    return (
      <>
        <PlayerIcon player={player} large showHistory />
        { showBadges && <div className="badges">
          { !!player.host && (
            <span className="icon is-large tag is-rounded" key="host-badge" data-tooltip="Is hosting">
              <i className="fa-solid fa-crown fa-lg" ></i>
            </span>
          )}
          { !!player.game && !player.host && (
            <span className="icon is-large tag is-rounded" key="game-badge" data-tooltip="In a game">
              <i className="fa-solid fa-dice fa-lg"></i>
            </span>
          )}
        </div> }
        { mode === 'token' && <NumberInput name={`[${index}].amount`} /> }
        { mode === 'status' && (
          <span className="icon is-large tag is-rounded check-toggle" key="player-toggle-checkin" data-tooltip={`Check Player ${checkedIn ? 'Out' : 'In'}`} onClick={togglePlayer}>
            <i className={`fa-2xl ${icon}`}></i>
          </span>
        )}
      </>
    )
  }
));
import { observer, inject } from 'mobx-react';
import { Formik, Form } from 'formik';
import { useNavigate } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import { Card, DateInput, Select, TextInput, NumberInput, toastService, modalService, stringHelpers } from '@vaettyr/boltcave-client-core';
import EventStore from '../../../stores/eventStore';
import LocationStore from '../../../stores/locationStore';
import { toISO } from '../../../utility/date.utility';
import { Event as EventType } from '../../../type';
import { saveItemToasts } from '../../../utility/toast.utility';

type EditEventProps = {
    toastservice?:toastService,
    modalservice?:modalService,
    eventstore?: EventStore,
    locationstore?: LocationStore,
    date?: Date,
    event?: EventType,
    template?: boolean
}

type formValues = EventType & { location?: number, isTemplate?: boolean };

const modalName = 'edit-event';

const repeats = ["", {label:"Weekly", value:"weekly"}, {label:"Monthly", value:"monthly"}];
const positions = ["", {label:"First", value:"first"}, {label:"Second", value:"second"}, {label:"Third", value:"third"}, {label:"Last", value:"last"}];
const days = [
    "",
    {label:"Sunday", value:0},
    {label:"Monday", value:1},
    {label:"Tuesday", value:2},
    {label:"Wednesday", value:3},
    {label:"Thursday", value:4},
    {label:"Friday", value:5},
    {label:"Saturday", value:6}
];

export default inject( 'eventstore', 'locationstore', 'toastservice', 'modalservice' )
    ( observer(({ toastservice, modalservice, eventstore, locationstore, date, event, template = false }: EditEventProps ) => {

    const navigate = useNavigate();
    const update = !!event;

    function saveEvent(values:formValues):void {
        const { success, failure } = saveItemToasts(toastservice, values.name, !!event?.id)
        eventstore?.save(values)
            .then((e) => {
                success();
                modalservice?.hide(modalName);
                navigate(`/menu/${stringHelpers.prettyEncodeUri(e.name)}?date=${toISO(e.eventDate, 3)}`);
            })
            .catch(failure);
    }

    function onCancel() {
        modalservice?.hide(modalName);
    }

    // let non-admins create private events?
    const disabled = eventstore?.busy;

    const initialValues: formValues = {
        id: event?.id ?? undefined,
        type: event?.type ?? undefined,
        name: event?.name ?? '',
        description: event?.description ?? '',
        eventDate: event?.eventDate ?? date,
        location: event?.location?.id ?? undefined,
        template: event?.template ?? undefined
    }

    const locations = locationstore?.locations?.map((location) => ({ value:location.id as number, label: location.name})) ?? [];

    // switch to a controller pattern here

    return (
        <Formik initialValues={ initialValues } onSubmit={saveEvent}>
            {({dirty, isValid, values, handleSubmit}) => {
                const isGameNight = values.type === 'game night';
                const repeatsRequired = !!values.isTemplate;
                const positionRequired = repeatsRequired && values.template?.repeats === 'monthly';
                const dayLabel = values.template?.repeats === 'monthly' ? 'Of The Month' : 'On';
                // this needs some work
                const dateType = !!event?.parent ? 'time' : 'datetime';
                const dateLabel = `Event Date${!!event?.parent ? ` ${event.eventDate?.toLocaleDateString()}, Time` : ''}`;

                const hidePreview = () => modalservice?.hide('preview-description');

                const showPreview = () => {
                    const content = (
                        <Card header="Preview Description Markdown" footer={<button className="card-footer-item button" onClick={hidePreview}>OK</button>}>
                            <div className="markdown"><ReactMarkdown>{renderDescription}</ReactMarkdown></div>
                        </Card>
                    );
                    modalservice?.show({
                        body: content,
                        key: 'preview-description',
                        options: { className: 'preview-description'}
                    });
                }

                const label = (
                    <div className='widget-row'>
                        <label className="label">Description</label>
                        <span className="icon clickable has-tooltip-left" onClick={showPreview} data-tooltip="Preview Markdown">
                            <i className="fa-solid fa-eye"></i>
                        </span>
                    </div>
                );

                const renderDescription = values.description ?? '';
                const actions = (
                    <>
                        <button type="button" className="card-footer-item button is-primary" disabled={!dirty||!isValid||disabled} onClick={()=>{handleSubmit()}}>Save</button>
                        <button className="card-footer-item button" onClick={onCancel}>Cancel</button>
                    </>
                )

                return (
                    <Card header={update ? "Edit Event" : "Create New Event"} footer={actions}>
                        <Form className="edit-event">
                              { eventstore?.busy && (<progress className="progress is-primary is-medium" max="100">15%</progress>)}
                              <div className="game-details">
                                <Select name="type" label="Event Type" options={[null, 'game night', 'dm workshop', 'ad hoc', 'external', 'other']}/>
                                <TextInput name="name" label="Event Name" />
                                <TextInput name="description" label={label} lines={4}/>
                                {/*-- add ability to create new locations here --*/}
                                <Select name="location" label="Location" options={[null, ...locations]} />
                                { !values.isTemplate && (
                                    <DateInput type={dateType} name="eventDate" label={dateLabel} validateLabel='Accept'/>
                                ) }
                                { template && (
                                    <>
                                        <Select name="template.repeats" label="Template Repeats" options={repeats} required={repeatsRequired} key="event-template-repeats"/>
                                        { values.template?.repeats === 'monthly' && (
                                            <Select name="template.position" label="On The" options={positions} required={positionRequired} key="event-template-position"/>
                                        )}
                                        <Select name="template.day" label={dayLabel} options={days} required={repeatsRequired} key="event-template-day"/>
                                    </>
                                )}
                                { isGameNight && (
                                    <NumberInput name="playersPerTable" label="Max Players per Table" />
                                )}
                              </div>
                          </Form>
                    </Card>
                );
            } }
        </Formik>
    );
}));
import { observable, action, computed, makeObservable, runInAction } from 'mobx';
import axios from 'axios';
import { AuthStore } from '@vaettyr/boltcave-auth-client';
import { storeHelpers } from '@vaettyr/boltcave-client-core';
import { System } from '../type';

export default class SystemStore
{
    @observable systems: System[] = [];
    @observable busy:boolean = false;

    private route:string;

    constructor()
    {
        makeObservable(this);
        this.route = storeHelpers.GetEndpoint("RPGService");
    }

    getSystem = (id: number) => {
        const system = this.systems.find((s) => s.id === id);
        if(!system) return undefined;
        if(!!system.mergedWith) return this.systems.find((s) => s.id === system.mergedWith);
        return system;
    }

    @computed get activeSystems(): System[] {
        return this.systems?.filter((system) => !system.mergedWith);
    }

    @action init = () => {
        if (this.systems?.length > 0) return;
        const { __RPG: { data: { systems = false } = {} } = {} } = window as any;
        if (!!systems) {
            this.systems = systems as System[];
        } else {
            this.fetch();
        }
    }

    @action save = async (system:System):Promise<System> =>
    {
        this.busy = true;
        return new Promise((resolve, reject) => {
            const isUpdate = !!system.id;
            const call = isUpdate ?
                axios.post(`${this.route}api/v1/system`, system, AuthStore.GetConfig()):
                axios.put(`${this.route}api/v1/system`, system, AuthStore.GetConfig());

            call.then(response => {
                const updatedSystem = response.data;
                runInAction(() => {
                    if(isUpdate) {
                        const index = this.systems.findIndex(i => i.id === updatedSystem.id);
                        if(index >= 0) {
                            this.systems = [...this.systems.slice(0, index), updatedSystem, ...this.systems.slice(index + 1)];
                        }
                    } else {
                        this.systems = [...this.systems, updatedSystem];
                    }
                });
                resolve(updatedSystem);
            })
            .catch(err => reject(err))
            .finally(() => {
                runInAction(() => { this.busy = false; });
            });
        })
    }

    @action fetch = async():Promise<System[]> =>
    {
        this.busy = true;
        return new Promise((resolve, reject) => {
            axios.get(`${this.route}api/v1/system`, AuthStore.GetConfig())
            .then(response => {
                const { data = [] }:{ data: System[] } = response;
                runInAction(() => {
                    this.systems = data;
                });
                resolve(data ?? []);
            })
            .catch(err => reject(err))
            .finally(() => {
                runInAction(() => { this.busy = false; })
            });
        });
    }

    @action remove = async(item:System):Promise<boolean> => {
        return new Promise((resolve, reject) => {
            this.busy = true;
            axios.delete(`${this.route}api/v1/system/${item.id}`, AuthStore.GetConfig())
                .then((response) => {
                    runInAction(() => {
                        const index = this.systems.findIndex(v => v.id === item.id);
                        if(index) {
                            this.systems = [...this.systems.slice(0, index), ...this.systems.slice(index + 1)];
                        }
                        resolve(response.data);
                    });
                })
                .catch((err) => {
                    reject(err.response);
                })
                .finally(() => {
                    runInAction(() => {
                        this.busy = false;
                    });
                })
        });
    }

    @action merge = async(from:System, to:System):Promise<boolean> => {
        return new Promise((resolve, reject) => {
            this.busy = true;
            axios.patch(`${this.route}api/v1/system/${from.id}/${to.id}`, undefined, AuthStore.GetConfig())
                .then(({ data: merged }: { data: System }) => {
                    runInAction(() => {
                        const index = this.systems.findIndex(v => v.id === merged.id);
                        const old = this.systems.findIndex(v => v.id === from.id);
                        const order = index < old ? [index, old] : [old, index];
                        this.systems = [...this.systems.slice(0, order[0]), merged, ...this.systems.slice(order[0] + 1, order[1]), ...this.systems.slice(order[1] + 1)];
                        resolve(true);
                    });
                })
                .catch((err) => {
                    reject(err.response);
                })
                .finally(() => {
                    runInAction(() => {
                        this.busy = false;
                    });
                })
        });
    }
}
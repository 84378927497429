import { inject, observer } from 'mobx-react';
import { useContext } from 'react';
import { AuthStore, User } from '@vaettyr/boltcave-auth-client';
import useGame from '../../../hooks/useGame';
import { Game, System } from '../../../type';
import GameStore from '../../../stores/gameStore';
import PlayerStore from '../../../stores/playerStore';
import EventContext from '../../event/event.context';
import GameContext from './../game.context';
import GameStatusIcon from '../widgets/game.status.icon';
import PlayerStatusIcon from '../widgets/game.players.status';
import SystemIcon from '../../system/widgets/system.icon.view';
import PlayerIcon from '../../player/widgets/player.icon';
import GameMenu from '../menus/game.menu';
import GameBadges from '../widgets/game.badges';

type GameViewProps = {
  game: Game,
  gamestore?: GameStore,
  playerstore?: PlayerStore,
  authstore?: AuthStore,
  flexStart?: boolean,
  line?: number,
  onSelect?: (() => void)
}

export default inject('gamestore', 'authstore', 'playerstore') (observer(
({ playerstore, game, authstore, onSelect, line = 0, flexStart }: GameViewProps) => {

  const { user } = authstore ?? {};
  const { host, players = [] } = playerstore?.games[game.id as number] ?? {};
  const { event, self } = useContext(EventContext);
  const details = useGame({ event, game, players, user: user as User });
  const { flat } = game?.metadata ?? {};

  return (
    <GameContext.Provider value={{game, players, host, self, details}}>
      <div className={`game-view line-${line + 1}`} onClick={onSelect}>
        <SystemIcon systems={game.systems as System[]} mode="short">
          <GameStatusIcon />
          <GameBadges game={game} />
        </SystemIcon>
        <PlayerStatusIcon />
        <GameMenu />
        { !flat && <PlayerIcon player={host} large showHistory/> }
        { flat && <div className="flat-game">GM-less</div> }
        <p className='game-title has-text-weight-semibold is-size-6'>{game?.title}</p>
      </div>
      <fieldset className={`separator ${flexStart ? 'flex-start' : ''}`}>{ flexStart && <legend>Flex</legend>}</fieldset>
    </GameContext.Provider>
  );
}));
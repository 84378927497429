import { useContext } from 'react';
import GameContext from '../game.context';
import { Player } from "../../../type"

export default () => {
  const { game, players, details: { playerStatus: { committed = 0} = {}, totalSlots = 0 } = {} } = useContext(GameContext);
  return (
        <div className={`player-count-status ${committed < (game?.minPlayers ?? 1) ? 'under' : ''} slots-${totalSlots}`}>
        {([...Array(totalSlots).keys()]).map((index) => {
          const player = index < (players?.length ?? 0) ? (players as Player[])[index] : undefined;
          const slotStatus = !player ? "empty" : (player.game?.id === game?.id ? "committed" : "waitlist");
          return (
            <span className={`icon is-small game-player-status ${slotStatus}`} key={`${game?.id}-player-${index}`}>
                <i className={`${slotStatus === 'empty' ? 'fa-regular' : 'fa-solid'} ${player?.checkedIn ? 'fa-check-square' : 'fa-square'}`}></i>
            </span>
          );
        })}
      </div>
    )
}
import { observer, inject } from 'mobx-react';
import { Card, toastService, modalService } from '@vaettyr/boltcave-client-core';
import SystemStore from '../../../stores/systemStore';
import { System } from '../../../type';
import { deleteItemToasts } from '../../../utility/toast.utility';

type DeleteSystemProps = {
    toastservice?:toastService,
    modalservice?:modalService,
    item?: System,
    systemstore?: SystemStore
}

const modalName = 'delete-system';

export default inject( 'systemstore', 'toastservice', 'modalservice' )
    ( observer(({ toastservice, modalservice, item, systemstore }: DeleteSystemProps ) => {

    const onDelete = ():void => {
        const { success, failure } = deleteItemToasts(toastservice, item?.name);
        systemstore?.remove(item as System)
            .then(success).catch(failure).finally(() => modalservice?.hide(modalName));
    }

    const onCancel = () => {
        modalservice?.hide(modalName);
    }

    const actions = (
        <>
            <button type="button" className="card-footer-item button is-primary" onClick={onDelete}>Delete</button>
            <button className="card-footer-item button" onClick={onCancel}>Cancel</button>
        </>
    );

    return (
        <Card header="Delete System" footer={actions}>
            <p>Are you sure you want to delete System {item?.name ?? ''}</p>
        </Card>
    );
}));
import { observable, action, makeObservable, runInAction } from 'mobx';
import axios from 'axios';
import { AuthStore } from '@vaettyr/boltcave-auth-client';
import { storeHelpers } from '@vaettyr/boltcave-client-core';
import { Game, Event, Player, System } from '../type';
import { User } from '@vaettyr/boltcave-auth-client';

type settings = {
    supressed?: boolean,
    createSupressed?: boolean,
    templates?: Record<string, Game>
}

export default class GameStore
{
    @observable games: Game[] = [];
    @observable busy:boolean = false;
    @observable templates: Record<string, Game>;
    @observable supressed: boolean;
    @observable createSupressed: boolean;

    private route:string;
    private static readonly storageKey = "RPG-Game";

    constructor()
    {
        makeObservable(this);
        this.route = storeHelpers.GetEndpoint("RPGService");
        const { supressed = false, createSupressed = false, templates = {} } = this.settings;
        this.supressed = supressed;
        this.createSupressed = createSupressed;
        this.templates = templates;
    }

    @action set = (games:Game[]):void => {
        this.games = games;
    }

    @action save = async ( game: Game & { newSystem?:string, host?: User|string } ):Promise<{game:Game, host?:Player, previous?:Player, deleted?:Game}> =>
    {
        // this part needs a little work, k?

        this.busy = true;
        const { event, owner, host, ...rest } = game;
        const transformed = {
            event: typeof(event) === 'object' ? event.id : event,
            // owner: typeof(owner) === 'object' ? owner.id : owner, // owner is always hard-coded to whoever created it
            host: typeof(host) === 'object' ? { ...host } : { name: host },
            ...rest
        };
        return new Promise((resolve, reject) => {
            const isUpdate = !!game.id;
            const call = isUpdate ?
                axios.post(`${this.route}api/v1/game`, transformed, AuthStore.GetConfig()):
                axios.put(`${this.route}api/v1/game`, transformed, AuthStore.GetConfig());

            call.then(response => {
                const { game: returnedGame, host: returnedHost, previous, deleted }:{ game:Game, host:Player, previous?:Player, deleted?:Game} = response.data;
                runInAction(() => {
                    const index = this.games.findIndex(i => i.id === returnedGame.id);
                    if(index >= 0) {
                        this.games = [...this.games.slice(0, index), returnedGame, ...this.games.slice(index + 1)];
                    } else {
                        this.games = [...this.games, returnedGame];
                    }
                    if(deleted) {
                        const dIndex = this.games.findIndex(i => i.id === deleted.id);
                        if(dIndex >= 0) {
                            this.games = [...this.games.slice(0, dIndex), ...this.games.slice(dIndex + 1)];
                        }
                    }
                    resolve({ game: returnedGame, host: returnedHost, previous });
                });
            })
            .catch(err => reject(err))
            .finally(() => {
                runInAction(() => { this.busy = false; });
            });
        })
    }

    @action fetch = async(event:Event|Event[]):Promise<Game[]> =>
    {
        if(!event || (Array.isArray(event) && !event.length) || this.busy) return [];
        this.busy = true;
        const path = Array.isArray(event) ? `?${event.map(e => `event=${e.id}`).join('&')}` : `/${event.id}`;
        return new Promise((resolve, reject) => {
            axios.get(`${this.route}api/v1/game${path}`, AuthStore.GetConfig())
            .then(response => {
                const { data = [] }:{ data: Game[] } = response;
                runInAction(() => {
                    this.games = data ?? [];
                });
                resolve(data ?? []);
            })
            .catch(err => reject(err))
            .finally(() => {
                runInAction(() => { this.busy = false; })
            });
        });
    }

    @action remove = async(item:Game):Promise<boolean> => {
        return new Promise((resolve, reject) => {
            this.busy = true;
            axios.delete(`${this.route}api/v1/game/${item.id}`, AuthStore.GetConfig())
                .then((response) => {
                    runInAction(() => {
                        const index = this.games.findIndex(v => v.id === item.id);
                        if(index >= 0) {
                            this.games = [...this.games.slice(0, index), ...this.games.slice(index + 1)];
                        }
                        resolve(response.data);
                    });
                })
                .catch((err) => {
                    reject(err.response);
                })
                .finally(() => {
                    runInAction(() => {
                        this.busy = false;
                    });
                })
        });
    }

    @action clear = (game: Game): void => {
        const index = this.games.findIndex((g) => g.id === game.id);
        if(index >= 0) {
            this.games = [...this.games.slice(0, index), ...this.games.slice(index + 1)];
        }
    }

    @action Supress = (value: boolean = true, create?: boolean) => {
        const { templates } = this.settings;
        let { supressed, createSupressed } = this.settings;
        if(create) {
            this.createSupressed = value;
            createSupressed = value;
        } else {
            this.supressed = value;
            supressed = value;
        }
        this.settings = { supressed, createSupressed, templates };
    }

    @action saveTemplate = (name: string, template: Game, supress: boolean = false, index: number = -1): boolean => {
        // check to make sure it's not a duplicate name?
        const keys = Object.keys(this.templates);
        if(index >= 0 && index < keys.length && keys.some((key, i) => i !== index && key === name)) {
            return false;
        }
        const { systems, title, description, metadata, isFlex, minPlayers, maxPlayers } = template;
        const allTemplates = keys.map((key) => {
            const { metadata: tm, ...rest } = this.templates[key];
            return [key, { ...rest, metadata: { ...tm }}];
        });
        const entry = [name, { systems, title, description, metadata: metadata ?? {}, isFlex, minPlayers, maxPlayers }];
        if(index < 0 || index >= allTemplates.length) {
            allTemplates.push(entry);
        } else {
            allTemplates[index] = entry;
        }
        this.settings = { templates: Object.fromEntries(allTemplates), supressed: supress };
        this.supressed = supress;
        this.templates = Object.fromEntries(allTemplates);;
        return true;
    }

    @action deleteTemplate = (name: string): boolean => {
        const keys = Object.keys(this.templates);
        const index = keys.indexOf(name);
        if(index < 0 || index >= keys.length) {
            return false;
        }
        const allTemplates = keys.map((key) => {
            const { metadata: tm, ...rest } = this.templates[key];
            return [key, { ...rest, metadata: { ...tm }}];
        });
        const trimmed = Object.fromEntries([...allTemplates.slice(0, index), ...allTemplates.slice( index + 1 )]);
        this.settings = { templates: trimmed, supressed: this.settings.supressed };
        this.templates = trimmed;
        return true;
    }

    byEvent = (event:Event):Game[] => {
        return this.games.filter((game) => (game.event as Event)?.id === event.id)
    }

    private get settings() {
        const stored = localStorage.getItem(GameStore.storageKey);
        if(!stored) return {};
        try {
            const parsed = JSON.parse(stored);
            return parsed;
        } catch {
            return {};
        }
    }

    private set settings(value: settings) {
        localStorage.setItem(GameStore.storageKey, JSON.stringify(value));
    }
}
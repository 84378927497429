import { AuthStore } from '@vaettyr/boltcave-auth-client';
import { Token } from '../../type';

// need to know permission about what can be changed on this token

type TokenControllerProps = {
    authstore?: AuthStore;
    token?: Token;
}

type TokenController = {
    editAmount?: boolean;
    fullEdit?: boolean;
    type: "token" | "bid";
    mode: "edit" | "create";
}

export default ({ authstore, token }: TokenControllerProps): TokenController => {
    const { id, prize } = token ?? {};

    const mode = !!id ? "edit" : "create";
    const type = !!prize ? "bid" : "token";

    const fullEdit = authstore?.hasPermission("BOOLEAN.TokenService.CanEditTokens", true) ?? false;
    const editAmount = fullEdit || type === 'bid';

    return {
        editAmount,
        fullEdit,
        mode,
        type,
    };
}
import { inject, observer } from 'mobx-react';
import PlayerStore from '../../../stores/playerStore';
import { Event, Player } from '../../../type';
import { Card, modalService } from '@vaettyr/boltcave-client-core';
import useAttendeesController from './event.attendees.controller';
import PlayerAttendee from './event.attendee.player';

type AttendeesProps = {
  event: Event;
  filter?: (player:Player) => boolean;
  isModal?: boolean;
  modalservice?: modalService;
  mode?: 'view'|'status'|'token';
  playerstore?: PlayerStore;
  sort?: (a:Player, b:Player) => number;
}

export const modalName = 'event-attendees';

// needs to allow checking players in and out

export default inject('playerstore', 'modalservice') ( observer(
  ({ event, filter, isModal, modalservice, mode = 'view', playerstore, sort }: AttendeesProps) => {

    const { players } = useAttendeesController({ filter, playerstore, sort });
    const content = players.map((player, index) => <PlayerAttendee player={player} event={event} game={ player.game } key={`attendee-${player.id ?? 0}`} index={index} mode={mode} /> );
    const cancel = () => modalservice?.hide(modalName);

    return !isModal ?
      <div className={`attendees-list ${mode}`}>{ content }</div> :
      ( <Card header="Attendees" footer={<button className="card-footer-item button" onClick={cancel}>OK</button>}>
          <div className={`attendees-list ${mode}`}>
            {content}
          </div>
      </Card>
    );
  }
));
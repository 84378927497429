import { User } from '@vaettyr/boltcave-auth-client';
import { Player } from '../type';

export default (player?: Player, user?: User) => {
  if(!player) return '';
  const { name, user: playerUser } = player;
  if(name) return name;
  const { FirstName, LastName, Username } = playerUser ?? user ?? {};
  if(Username) return Username;
  return `${FirstName ?? ""} ${LastName ?? ""}`;
}
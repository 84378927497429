import { inject, observer } from 'mobx-react';
import { modalService, TextInput } from '@vaettyr/boltcave-client-core';
import { useField } from 'formik';
import MarkdownPreview, { modalName } from './markdown.preview.modal';

type MarkdownPreviewWidgetProps = {
  modalservice?: modalService,
  label?: string,
  name: string,
  required?: boolean,
  disabled?: boolean,
  lines?: number
}

export default inject('modalservice') ( observer(({ modalservice, name, label: title, disabled, required, lines = 3 }: MarkdownPreviewWidgetProps) => {
  const [_field, meta] = useField<string>(name);

  const showPreview = () => {
    const { value } = meta;
    modalservice?.show({
      body: <MarkdownPreview text={value} title={`Preview ${title}`} />,
      key: modalName,
      options: { className: modalName }
    });
  }

  const label = (
    <div className='widget-row'>
        <label className="label">{title}</label>
        <span className="icon clickable has-tooltip-left" onClick={showPreview} data-tooltip="Preview Markdown">
            <i className="fa-solid fa-eye"></i>
        </span>
    </div>
  );
  return <TextInput name={name} label={label} disabled={disabled} lines={lines} required={required} />
}));
import { Prize, Token } from '../../type';
import { AuthStore, User } from '@vaettyr/boltcave-auth-client';
import TokenStore from '../../stores/tokenStore';
import { useEffect, useState } from 'react';

export type PrizeController = {
  balance: number;
  bidAmount?: number;
  canAward: boolean;
  canBid: boolean;
  myBid: Token|undefined;
  initialize: (() => Promise<Token[]>);
  owned?: boolean;
  owner?: User;
  owns?: boolean;
  thresholdMet?: boolean;
  totalBids?: number;
  wonOn?: Date;
  wonAt?: string;
}

type PrizeControllerArgs = {
  authstore?: AuthStore;
  prize: Prize;
  tokenstore?: TokenStore;
}

export default ({ authstore, prize, tokenstore }: PrizeControllerArgs): PrizeController => {

  const { user } = authstore ?? {};
  const { balance = 0 } = tokenstore ?? {};
  const { id, wonBy, wonAt: prizeEvent, bids, updated } = prize;

  const [totalBids, setTotalBids] = useState<number>(bids?.reduce((tot, token) => tot - (token.amount ?? 0), 0) ?? 0);
  useEffect(() => {
    setTotalBids(bids?.reduce((tot, token) => tot - (token.amount ?? 0), 0) ?? 0);
  }, [bids]);

  const owned = !!wonBy?.id;
  const canBid = (balance ?? 0) > 0 && !owned;
  const myBid = bids?.find((bid) => bid.user?.id === user?.id);
  const owns = owned && !!user?.id && wonBy?.id === user?.id;
  const canAward = authstore?.hasPermission("BOOLEAN.PrizeService.CanAwardPrizes", true) ?? false;
  const owner = wonBy ?? bids?.find((bid) => bid.won === true);
  const wonAt = prizeEvent ? prizeEvent.name : undefined;
  let wonOn = owned ? ( prizeEvent ? prizeEvent.eventDate : updated ) : undefined;
  if (wonOn && typeof wonOn === 'string') wonOn = new Date(wonOn);

  const initialize = () => new Promise<Token[]>(
    (resolve) => tokenstore?.get({ type: 'prize', id: id ?? 0 }, undefined, { column: 'amount', direction: -1 })
      .then(response => {
        const total = response.reduce((tot, token) => tot + (token.amount ?? 0), 0);
        setTotalBids(-total);
        resolve(response);
      })
  );

  return {
    balance,
    canAward,
    canBid,
    myBid,
    initialize,
    owned,
    owner,
    owns,
    totalBids,
    wonOn: wonOn as Date,
    wonAt,
  }
}
import React from 'react';
import { Game } from '../../../type';

type GameBadgesProps = {
  game: Game;
  showFlat?: boolean;
  className?: string;
}

export default ({ game, className = "", showFlat = false } : GameBadgesProps) => {

  const { isFlex, metadata: { flat, type } = {} } = game;
  const isPrivate = type?.toLowerCase() === 'private';
  const classNames = {
    flat: `${className} flat-game`,
    flex: `${className} flex-badge game-badge`,
    private: `${className} private-badge game-badge`
  }
  return <>
    { !!flat && showFlat && <div className={classNames.flat}>GM-less</div> }
    { isFlex && (
      <div className={classNames.flex}>
        <span className="icon"><i className="fa-solid fa-circle-question"></i></span>
        <span className="badge-name">Flex</span></div>
    )}
    { isPrivate && (
      <div className={classNames.private}>
        <span className="icon"><i className="fa-solid fa-triangle-exclamation"></i></span>
        <span className="badge-name">Private</span></div>
    )}
  </>
}
import { Event, Player } from '../../../type';
import { Card, modalService } from '@vaettyr/boltcave-client-core';
import { inject, observer } from 'mobx-react';

type eventCodeProps = {
  event: Event,
  player?: Player,
  game?: number,
  flex?: number,
  modalservice?: modalService
}

const modalName = 'event-code';

export default inject('modalservice') ( observer (
  ({event, modalservice, game, flex, player}:eventCodeProps) => {

    const queries: Record<string, string> = {};
    if(!!game) queries.game = game.toString();
    if(!game && !!flex) queries.flex = flex.toString();
    if(player?.checkedIn) queries.checkin = 'true';

    const query = Object.keys(queries).length > 0 ? `?${Object.keys(queries).map((key) => `${key}=${queries[key]}`).join('&')}` : '';
    const source = `/api/v1/event/qr/${event.id}${query}`;

    const onCancel = () => {
      modalservice?.hide(modalName);
    }

    return (
      <Card header={`Share ${event.name}`} footer={<button className="card-footer-item button" onClick={onCancel}>OK</button>}>
        <img className="image is-square" src={source}/>
      </Card>
    )
} ));
import { Content, EventContent } from '../../../type';
import { Collapsible } from '@vaettyr/boltcave-client-core';
import { inject, observer } from 'mobx-react';
import { AuthStore } from '@vaettyr/boltcave-auth-client';
import ContentStore from '../../../stores/contentStore';
import TopicMenu from './event.topic.menu';

type TopicArgs = {
  authstore?: AuthStore;
  contentstore?: ContentStore;
  topic: Content;
}
export default inject('authstore', 'contentstore') ( observer( ({ authstore, contentstore, topic }: TopicArgs ) => {
  const { user } = authstore ?? {};
  const { metadata } = topic;
  const { title, description, upvotes = [] } = metadata as EventContent ?? {};

  const canUpvote = !!user && !upvotes.includes(user?.id ?? 0);

  const toggleVote = (e:React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    const index = upvotes.indexOf(user?.id ?? 0);
    const newUpvotes = canUpvote ? [...upvotes, user?.id] : [...upvotes.slice(0, index), ...upvotes.slice(index + 1)];
    contentstore?.save({ ...topic, metadata: { ...metadata, upvotes: newUpvotes as number[] }})
  }

  const canEditTopic = authstore?.hasPermission("ENUM.ContentService.CanEditContent", "ALL") ||
    (authstore?.hasPermission("ENUM.ContentService.CanEditContent", "OWN") && (topic.createdBy?.id ?? 0) === (authstore?.user?.id ?? -1));

  if(!title && !description) return null;

  const header = (
    <div className={`topic-header${!description?' no-description':''}`}>
      <h3 className="is-3">{title}</h3>
      <span className={`icon upvote${!!user ? ' clickable':''}`} onClick={toggleVote}>
        <i className={`${!canUpvote ? 'fa-solid' : 'fa-regular' } fa-thumbs-up`}></i>
        { upvotes.length > 0 && <span>{upvotes.length}</span> }
      </span>
      {canEditTopic && (
        <TopicMenu topic={topic} />
      )}
    </div>
  );

  if(!description) return header;
  return <Collapsible className="topic" header={header}><div>{description}</div></Collapsible>;
} ));
import { observable, action, makeObservable, runInAction } from 'mobx';
import axios from 'axios';
import { AuthStore } from '@vaettyr/boltcave-auth-client';
import { storeHelpers, stringHelpers } from '@vaettyr/boltcave-client-core';
import { Event, EventType, EventDefinition } from '../type';
import { fromString, toISO } from '../utility/date.utility';
import fromObservable from '../utility/data.utility';

type eventSearchParams = {
    name?:string,
    type?: EventType,
    template?:string,
    date?:Date,
    from?:Date,
    to?:Date
}

export default class EventStore
{
    @observable events: Event[] = [];
    @observable busy:boolean = false;

    private route:string;
    private lastParams: eventSearchParams = {}

    constructor()
    {
        makeObservable(this);
        this.route = storeHelpers.GetEndpoint("RPGService");
    }

    @action save = async (event:Event):Promise<Event> =>
    {
        this.busy = true;
        return new Promise((resolve, reject) => {
            const isUpdate = !!event.id;
            const payload = {...fromObservable(event, EventDefinition), eventDate: toISO(event.eventDate)};
            // there's a problem here that date is shifting no matter what. need to fix
            // also, if the date shifts off a templated date, it will be recreated. That's an issue
            const call = isUpdate ?
                axios.post(`${this.route}api/v1/event`, payload, AuthStore.GetConfig()):
                axios.put(`${this.route}api/v1/event`, payload, AuthStore.GetConfig());

            call.then(response => {
                const updatedEvent = EventStore.parseEvent(response.data);
                runInAction(() => {
                    if(isUpdate) {
                        const index = this.events.findIndex(i => i.id === updatedEvent.id);
                        if(index >= 0) {
                            this.events = [...this.events.slice(0, index), updatedEvent, ...this.events.slice(index + 1)];
                        }
                    } else {
                        this.events = [...this.events, updatedEvent];
                    }
                });
                resolve(updatedEvent);
            })
            .catch(err => reject(err))
            .finally(() => {
                runInAction(() => { this.busy = false; });
            });
        })
    }

    @action getNext = async ({ name, template, type }:{name?:string, template?:string, type?: EventType} = {}): Promise<Event[]> => {
        // clean up name and template. Is there a utility for that aleady?
        if(this.hasRun({name, template, type})) return this.events;
        this.lastParams = { name, template };
        const queryString = `?next=true&withgames=true${name && name !== 'none' ? `&name=${name}`:''}${template ? `&template=${template}` : ''}${!!type ? `&type=${type}` : ''}`;
        return this.fetch(queryString);
    }

    @action getByDate = async ({ date, name, template, type } : {date:Date, name?:string, template?:string, type?: EventType} ): Promise<Event[]> => {
        if(this.hasRun({ date, name, template, type })) return this.events;
        this.lastParams = { name, date, template, type };
        const queryString = `?date=${this.dateQuery(date)}&withgames=true${name ? `&name=${name}`:''}${template ? `&template=${template}` : ''}${!!type ? `&type=${type}` : ''}`;
        return this.fetch(queryString);
    }

    @action getRange = async ({ from, to, name, template, instantiate, type }:{ from: Date, to?: Date, name?: string, template?: string, instantiate?: boolean, type?: EventType }): Promise<Event[]> => {
        if(this.hasRun({ name, template, from, to, type })) return this.events;
        this.lastParams = { name, template, from, to };
        const queryString = `?from=${this.dateQuery(from)}&to=${this.dateQuery(to)}${this.boolQuery("instantiate", instantiate)}${name ? `&name=${name}`:''}${template ? `&template=${template}` : ''}${!!type ? `&type=${type}` : ''}`;
        return this.fetch(queryString);
    }

    @action fetch = async(queryString?:string):Promise<Event[]> =>
    {
        this.busy = true;
        return new Promise((resolve, reject) => {
            axios.get(`${this.route}api/v1/event${queryString}`, AuthStore.GetConfig())
            .then(response => {
                const { data = [] }:{ data: Event[] } = response;
                runInAction(() => {
                    this.events = data.map(e => EventStore.parseEvent(e));
                });
                resolve(data ?? []);
            })
            .catch(err => reject(err))
            .finally(() => {
                runInAction(() => { this.busy = false; })
            });
        });
    }

    @action remove = async(item:Event):Promise<boolean> => {
        return new Promise((resolve, reject) => {
            this.busy = true;
            axios.delete(`${this.route}api/v1/event/${item.id}`, AuthStore.GetConfig())
                .then((response) => {
                    runInAction(() => {
                        const index = this.events.findIndex(v => v.id === item.id);
                        if(index) {
                            this.events = [...this.events.slice(0, index), ...this.events.slice(index + 1)];
                        }
                        resolve(response.data);
                    });
                })
                .catch((err) => {
                    reject(err.response);
                })
                .finally(() => {
                    runInAction(() => {
                        this.busy = false;
                    });
                })
        });
    }

    public onDate = (date?:Date, title?:string, type?: EventType) => {
        const short = toISO(date, 3);
        const safeTitle = stringHelpers.prettyEncodeUri(title);
        return this.events.filter((event) => (!short || toISO(event.eventDate, 3) === short) && (!safeTitle || stringHelpers.prettyEncodeUri(event.name) === safeTitle) && (!type || event.type === type));
    }

    private hasRun = (newParams:eventSearchParams): boolean =>  {
        if(this.busy) return true; // just to keep us out of an endless loop
        const sameName = (!newParams.name && !this.lastParams.name) || newParams.name === this.lastParams.name;
        const sameTemplate = (!newParams.template && !this.lastParams.template) || newParams.template === this.lastParams.template;
        let inRange = true;
        if(newParams.date) {
            if(this.lastParams.date && toISO(this.lastParams.date, 3) !== toISO(newParams.date, 3)) inRange = false;
            if(this.lastParams.from && this.lastParams.from < newParams.date) inRange = false;
            if(this.lastParams.to && this.lastParams.to > newParams.date) inRange = false;
            if(!this.lastParams.date && !this.lastParams.from && !this.lastParams.to) inRange = false;
        }
        if(newParams.from && (!this.lastParams.from || newParams.from < this.lastParams.from)) inRange = false;
        if(newParams.to && (!this.lastParams.to || newParams.to > this.lastParams.to)) inRange = false;
        return inRange && sameName && sameTemplate;
    }

    private static parseEvent(event:Event):Event {
        if(event.eventDate && typeof(event.eventDate) === 'string') {
            event.eventDate = fromString(event.eventDate);
        }
        return event;
    }

    private dateQuery(date?:Date|string):string {
        return date ? ( typeof(date) === "string" ? date : toISO(date, 3) ?? "") : "";
    }

    private boolQuery(name:string, value?:boolean):string {
        return value ? `&${name}=true` : "";
    }
}
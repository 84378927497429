import { Checkbox } from '@vaettyr/boltcave-client-core';
import { useField } from 'formik';
import { Game, System } from '../../../type';

type SystemsProps = {
    name: string,
    label?: string,
    className?: string,
    game?: Game
}

export default ({ name, label, game, className }: SystemsProps) => {

  const [_field, meta, helpers] = useField<Record<number, number[]>>(name);
  const { value = {}, error } = meta;

  const handleChange = (system: string|number|System) => (checked?: boolean) => {
    const systemId = typeof(system) === 'object' ? system.id ?? 0 : system as number ?? 0;
    if(checked) {
      if(value[game?.id ?? 0] === undefined) {
        helpers.setValue( { ...value, [game?.id ?? 0]: [systemId] } );
      } else {
        helpers.setValue( { ...value, [game?.id ?? 0]: [...value[game?.id ?? 0], systemId] } );
      }
    } else {
      if(value[game?.id ?? 0] !== undefined && value[game?.id ?? 0].includes(systemId)) {
        const index = value[game?.id ?? 0].indexOf(systemId);
        helpers.setValue( { ...value, [game?.id ?? 0]: [...value[game?.id ?? 0].slice(0, index), ...value[game?.id ?? 0].slice(index + 1)]});
      }
    }
  }

  if( (game?.systems?.length ?? 0) <= 1) return null

  return (
    <div className={`field ${className ?? ''}`}>
        { label && <label className="label">{label}</label> }
        <div className="control">
          { game?.systems?.map((system, index) => {
            const cname = typeof(system) === 'object' ? system.id?.toString() ?? '' : system as string ?? '';
            const clabel = typeof(system) === 'object' ? system.name ?? '' : system as string ?? '';
            return <Checkbox name={`preference.${cname}`} label={clabel} key={`system-preference-${index}`} onChange={handleChange(system)} />
          })}
        </div>
        {error && <p className="has-text-danger">{error}</p>}
    </div>
  );
};
import { useContext } from 'react';
import { MediaImage } from '@vaettyr/boltcave-media-client';
import GameContext from '../game.context';
import useFallback from '../../../hooks/useFallback';

export default () => {
  const { game, host, details: { playerCap = 0, playerStatus = null } = {} } = useContext(GameContext);
  const { systems } = game ?? {};
  const { confirmed = 0, committed = 0 } = playerStatus ?? {};
  const iconStyle = committed >= ( game?.minPlayers ?? 1) || game?.metadata?.flat ? 'solid' : 'regular';
  const iconColor = confirmed < ( game?.minPlayers ?? 1) ? 'warning' : (confirmed > playerCap ? 'danger' : 'success');
  const icon = game?.isFlex ? 'circle-question' : (game?.metadata?.flat ? 'circle-minus' : (host?.checkedIn ? 'circle-check' : 'circle'));

  const systemIcon = systems && systems.length > 0 ? (typeof(systems[0]) === 'object' ? systems[0].icon : undefined ) : undefined;
  const fallback = useFallback("system") as string|undefined;

  return (!!systemIcon || !!fallback) ?
  (
    <MediaImage src={(systemIcon ?? fallback) as string} rounded size={32} className={`game-status has-text-${iconColor}`} fallback={ fallback } />
  ) :
  (
    <span className={`icon is-large game-status has-text-${iconColor}`}>
        <i className={`fa-${iconStyle} fa-${icon}`}></i>
    </span>
  );
}
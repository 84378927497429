import { toastService, modalService } from '@vaettyr/boltcave-client-core';
import ContentStore from '../../stores/contentStore';
import { Content } from '../../type';

export type GlobalContentType = "about" | "faq" | "link";

export type EventContentType = "topic";

export type ContentType = GlobalContentType | EventContentType;

export type LinkType = "game" | "event" | "location" | "system" | "user";

export type EditContentProps = {
    toastservice?:toastService,
    modalservice?:modalService,
    contentstore?: ContentStore,
    content?: Content,
    type?: ContentType,
    linkType?: LinkType,
    parent?: number
}

export type EditContentController = {
  busy: boolean;
  cancel: () => void;
  id?: number;
  initialValues: Content;
  save: (values: Content) => void;
  type?: ContentType;
  update: boolean;
}

export const modalName = 'edit-content';

export default ({ toastservice, contentstore, modalservice, content, type: inputType, linkType, parent }: EditContentProps ): EditContentController => {

  const { busy = false } = contentstore ?? {};
  const update = !!content?.id;
  const type = (content?.type as ContentType) ?? inputType;
  const { id } = content ?? {};
  // should this be memo-ized?
  const initialValues: Content = {
    type: content?.type ?? type ?? '',
    linkType: content?.linkType ?? linkType ?? undefined,
    body: content?.body ?? '',
    parent: content?.parent ?? parent ?? undefined,
  }

  switch(linkType) {
    case 'event':
      initialValues.metadata = {
        title: content?.metadata?.title ?? '',
        description: content?.metadata?.description ?? ''
      }
      break;
    default:
      initialValues.metadata = {
        single: content?.metadata?.single ?? (type === 'about' ? true : false),
        display: content?.metadata?.display ?? (type === "link" ? "link" : ( type === "faq" ? "accordion" : undefined)),
        uri: content?.metadata?.uri ?? '',
        icon: content?.metadata?.icon ?? '',
        heading: content?.metadata?.heading ?? ''
      };
      break;
  }

  const cancel = () => modalservice?.hide(modalName);
  // add toasts to this
  const save = (values: Content) => {
    contentstore?.save({ id: content?.id, ...values })
      .then(() => modalservice?.hide(modalName));
  }

  return {
    busy,
    cancel,
    id,
    initialValues,
    save,
    type,
    update
  };
}
import { inject, observer } from 'mobx-react';
import { useContext, useMemo } from 'react';
import { AuthStore } from '@vaettyr/boltcave-auth-client';
import ReactMarkdown from 'react-markdown';
import EventContext from '../event.context';
import EventDetailMenu from './event.detail.menu';
import useEventPermissions from '../../../hooks/useEventPermissions';
import Address from '../../location/location.address.view';
import useMenu from '../../../hooks/useMenu';
import EventActions from './event.detail.actions';

type eventDetailProps = {
  authstore?: AuthStore
}

// add previous and next event navigation

export default inject('authstore') ( observer (
({ authstore }: eventDetailProps) => {
  const { event = {} } = useContext(EventContext);
  const { isActive, open, close } = useMenu();

  const title = event.name ?? event.location?.name;
  const time = `${event.eventDate?.toLocaleDateString('en-US')} ${event.eventDate?.toLocaleTimeString('en-US')}`;

  const { address } = event.location ?? {};

  const { canEditEvent } = useMemo(() => useEventPermissions({authstore, event}), [authstore?.user?.id, event?.id]);
  const showActions = !event.cancelled && !event.locked;

  const menuClick = (e?:React.MouseEvent):void => {
    if(!canEditEvent) return;
    e?.preventDefault();
    e?.stopPropagation();
    if(isActive) {
      close();
    } else {
      open();
    }
  }

  return (
    <header className="event event-detail">
      <div className="top-row">
        <div className="event-names">
          <h1 className={`title is-4${event.cancelled ? ' cancelled': '' }`}>{ title }</h1>
          {event.cancelled && (
            <div className="has-text-danger is-cancelled">Cancelled</div>
          )}
          <h2 className="subtitle is-5">{ time }</h2>
        </div>
        <Address address={address} icon="map-marker-alt" />
        { canEditEvent && (
          <span className={`dropdown clickable is-right${isActive ? ' is-active' : ''}`}>
            <span className="icon is-large is-primary dropdown-trigger" onClick={menuClick}>
              <i className="fa-solid fa-ellipsis-vertical"></i>
            </span>
            <EventDetailMenu onClick={menuClick} event={event} />
          </span>
        )}
      </div>

      { showActions && <EventActions /> }
      { event.description && (
        <div className="event-description markdown"><ReactMarkdown>{ event.description }</ReactMarkdown></div>
      )}
      {/* might want to move this to the event menu since it's just for game nights */}
      {event.playersPerTable && (
        <div className="has-text-warning max-players-warning">
          <span className="icon">
            <i className="fa-solid fa-triangle-exclamation"></i>
          </span>
          Currently limited to a maximum of {event.playersPerTable} players per game.
        </div>
      )}

    </header>
  );
} ));
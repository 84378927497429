import { inject, observer } from 'mobx-react';
import TokenStore from '../../../stores/tokenStore';
import { Token } from '../../../type';
import { Card, NumberInput, TextInput, modalService, toastService } from "@vaettyr/boltcave-client-core";
import { AuthStore, User, UserLookup } from '@vaettyr/boltcave-auth-client';
import useTokenController from '../token.controller';
import { Formik, Form } from 'formik';
import { saveItemToasts } from '../../../utility/toast.utility';
import { useState, useEffect } from 'react';
import PrizeStore from '../../../stores/prizeStore';

type EditTokenProps = {
  authstore?: AuthStore;
  tokenstore?: TokenStore;
  isModal?: boolean;
  modalservice?: modalService;
  toastservice?: toastService;
  onAction?: ((e?:React.MouseEvent) => void);
  token?: Token;
  user?: User;
  prizestore?: PrizeStore;
}

export const modalName = 'edit-token';

export default inject('authstore', 'toastservice', 'tokenstore', 'modalservice', 'prizestore') ( observer (
  ({ authstore, user: tokenUser, toastservice, tokenstore, onAction, isModal, modalservice, token, prizestore }: EditTokenProps) => {
    const { balance = 0 } = tokenstore ?? {};
    const { user: { id: userId = 0 } = {} } = authstore ?? {};

    const [targetUser, setTargetUser] = useState<number|undefined>(tokenUser?.id);
    const [userBalance, setUserBalance] = useState<number>(balance);

    useEffect(() => {
      if (!targetUser && !!tokenUser?.id) {
        tokenstore?.getBalance(tokenUser.id)
        .then((response) => {
          setUserBalance(response);
        })
      }
    }, [tokenUser?.id]);

    const { editAmount, fullEdit, mode, type } = useTokenController({ authstore, token });

    const initialValues: Token & { user?: User } = {
      id: token?.id,
      description: token?.description,
      amount: token?.amount && type === 'bid' ? Math.abs(token.amount) : token?.amount ?? 0,
      game: token?.game,
      event: token?.event,
      prize: token?.prize,
      user: token?.user ?? tokenUser,
      locked: token?.locked ?? false,
      won: token?.won ?? false
    }

    const saveToken = (values: Token):void => {
      const { amount = 0 } = values;
      if (type === 'bid' && amount > 0) values.amount = -(amount);
      const refreshId = userId === initialValues.user?.id ? userId : undefined;
      const { success, failure } = saveItemToasts(toastservice, type, !!values.id);
      tokenstore?.save(values, refreshId)
        .then(() => {
          if (token?.prize) {
            prizestore?.refresh(token.prize);
          }
          modalservice?.hide(modalName);
          if (onAction) {
            onAction();
          }
          success();
        })
        .catch(failure);
    }

    const userChanged = (user?: User) => {
      setTargetUser(user?.id);
    }

    const closeModal = () => {
      modalservice?.hide(modalName);
    }

    const actualBalance = !!token && !!token.id && !token.locked
      ? userBalance + (type === 'bid' ? Math.abs(token.amount ?? 0) : (token.amount ?? 0))
      : userBalance;
    return (
      <Formik initialValues={ initialValues } onSubmit={ saveToken } isInitialValid={false}>
          {({ isValid, handleSubmit }) => {
            const actions = (
              <>
                <button className="card-footer-item button is-primary" disabled={!isValid} onClick={() => handleSubmit()}>Save</button>
                <button className="card-footer-item button" onClick={closeModal}>Cancel</button>
              </>
            );
            const body = (
              <Form>
                  { fullEdit && !tokenUser && !token?.user && <UserLookup name="user" label="User" limited={false} required onSelect={ userChanged }/>}
                  { (fullEdit || editAmount) && <NumberInput name="amount" label="Amount" required min={0} max={actualBalance} /> }
                  { fullEdit && <TextInput name="description" label="Description" lines={4} /> }
                </Form>
            );
            return isModal ? (
              <Card header={`${mode} ${type}`} footer={actions}>
                {body}
              </Card>
            ) : body;
          }}
      </Formik>
    )
  }
));
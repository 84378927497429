import { useState } from 'react';

type menuProps = {
  isActive: boolean,
  open: ((e?:MouseEvent) => void),
  close: ((e?:MouseEvent) => void),
  menuClick: ((e?:React.MouseEvent) => void)
}

export default (): menuProps => {
  const [isActive, setActive] = useState<boolean>(false);
  const [click, setClick] = useState<number|undefined|null>(null); // maybe not needed, but w/e

  const close = (e?: MouseEvent) => {
    if(click && e?.timeStamp === click) {
      setClick(null);
    } else {
      setActive(false);
      document.onclick = null;
    }
  }

  const open = (e?: MouseEvent) => {
    e?.preventDefault();
    e?.stopPropagation();
    setActive(true);
    setClick(e?.timeStamp);
    document.onclick = close;
  }

  const menuClick = (e?: React.MouseEvent) => {
    e?.preventDefault();
    e?.stopPropagation();
    if(isActive) {
        close(e?.nativeEvent);
    } else {
        open(e?.nativeEvent);
    }
  }

  return {
    isActive,
    open,
    close,
    menuClick
  };
}
import { inject, observer } from 'mobx-react';
import { useContext, useMemo } from 'react';
import { AuthStore } from '@vaettyr/boltcave-auth-client';
import { modalService, toastService } from '@vaettyr/boltcave-client-core';
import PlayerStore from '../../../stores/playerStore';
import GameContext from '../../game/game.context';
import PlayerContext from '../player.context';
import EventContext from '../../event/event.context';
import LeavePlayerModal, { modalName as leaveModalName } from '../modals/player.modal.leave';
import PlayerPreference from '../modals/player.modal.preference';
import PlayerWaitlist, { modalName } from '../modals/player.modal.waitlist';
import { Event, Game, Player } from '../../../type';
import { checkinToasts } from '../../../utility/toast.utility';
import usePlayerPermissions from '../../../hooks/usePlayerPermissions';
import useGame from '../../../hooks/useGame';
import useMenu from '../../../hooks/useMenu';

type menuProps = {
    authstore?: AuthStore,
    modalservice?: modalService,
    playerstore?: PlayerStore,
    toastservice?: toastService
}

export default inject('authstore', 'playerstore', 'modalservice', 'authstore') ( observer (
    ({ authstore, modalservice, playerstore, toastservice }:menuProps) => {
        const { player } = useContext(PlayerContext);
        const { game } = useContext(GameContext);
        const { event = {}, self } = useContext(EventContext);
        const { players = [] } = playerstore?.games[game?.id as number] ?? {};
        const { user } = authstore ?? {};
        const { playerCap, playerStatus: { committed } } = useGame({ event, game: game ?? {}, players, user });
        const isFull = committed >= playerCap;
        const {
            canCheckPlayerIn,
            canCheckPlayerOut,
            canRemovePlayer,
            canUpdatePreferences,
            canMoveToWaitlist,
            canPromoteFromWaitlist
        } = useMemo(() => usePlayerPermissions({ authstore, game, isFull, player, self }), [authstore?.user?.id, game?.id, player?.id, self?.id]);
        const { isActive, open, close, menuClick } = useMenu();

        const playerName = player?.user?.Username ?? player?.name ?? 'player';
        const isPrivate = game?.metadata?.type?.toLowerCase() === 'private';

        const doAction = (action:"checkin"|"leave"|"preference"|"promote"|"waitlist", { checkout }:{ checkout?: boolean } = {}) => {
            return (e:React.MouseEvent) => {
                e.preventDefault();
                e.stopPropagation();
                switch(action) {
                    case 'checkin':
                        const { success: checkinSuccess, failure: checkinFailure } = checkinToasts(toastservice, playerName, checkout);
                        playerstore?.checkin(event, game, player, checkout)
                        .then(checkinSuccess).catch(checkinFailure).finally(() => close(e?.nativeEvent));
                        break;
                    case 'leave':
                        modalservice?.show({
                            body: <LeavePlayerModal
                                game={game as Game}
                                event={event as Event}
                                player={player as Player}
                                name={playerName}
                            />,
                            options:{className: leaveModalName},
                            key:leaveModalName,
                        });
                        close(e?.nativeEvent);
                        break;
                    case 'preference':
                        const onWaitlist = player?.waitlist?.includes(game?.id ?? 0);
                        modalservice?.show({
                            body: <PlayerPreference game={game as Game} event={event} player={player} waitlist={onWaitlist} />,
                            options:{ className: "player-preference" },
                            key:'player-preference'
                        });
                        break;
                    case 'waitlist':
                    case 'promote':
                        modalservice?.show({
                            body: <PlayerWaitlist event={event} player={player} game={game} playerName={playerName} promote={action === 'promote'} isPrivate={isPrivate} />,
                            options: { className: modalName },
                            key: modalName
                        });
                        break;
                    default:
                        return;
                }
            }
        }

        if(!canRemovePlayer && !canCheckPlayerIn && !canCheckPlayerOut) return null;

        return (
            <span className={`dropdown is-right${isActive ? ' is-active' : ''}`}>
                <span className="icon is-large is-primary dropdown-trigger clickable" onClick={menuClick}>
                    <i className="fa-solid fa-ellipsis-vertical"></i>
                </span>
                <div className="dropdown-menu">
                    <div className="dropdown-content">
                        { canUpdatePreferences && (<div className="dropdown-item clickable" onClick={doAction('preference')}>Update Player Preferences</div>)}
                        { canRemovePlayer && (<div className="dropdown-item clickable" onClick={doAction('leave')}>Remove Player</div>) }
                        { canPromoteFromWaitlist && (<div className="dropdown-item clickable" onClick={doAction('promote')}>{isPrivate ? "Approve Request" : "Promote from Waitlist"}</div>)}
                        { canMoveToWaitlist && (<div className="dropdown-item clickable" onClick={doAction('waitlist')}>{isPrivate ? "Move to Pending" : "Move to Waitlist"}</div>)}
                        { canCheckPlayerIn && !player?.checkedIn && (<div className="dropdown-item clickable" onClick={doAction('checkin')}>Check Player In</div>) }
                        { canCheckPlayerOut && player?.checkedIn && (<div className="dropdown-item clickable" onClick={doAction('checkin', {checkout: true})}>Check Player Out</div>) }
                    </div>
                </div>
            </span>
        )
    }
));
import { AutoComplete, useTimeout } from "@vaettyr/boltcave-client-core";
import { UserWidget, User } from "@vaettyr/boltcave-auth-client";
import PlayerStore from "../../stores/playerStore";
import { inject, observer } from 'mobx-react';
import { useState } from 'react';

type UserLookupProps = {
  playerstore?: PlayerStore,
  name: string,
  label?: string,
  required?: boolean,
  disabled?: boolean,
  minCharacters?: number,
  waitSeconds?: number
}

export default inject('playerstore') ( observer (
  ({ playerstore, name, label, required, disabled, minCharacters = 2, waitSeconds }: UserLookupProps) => {
    const [users, setUsers] = useState<User[]|null>(null);
    const [filter, setFilter] = useState<string>();
    const { players = [], busy = false } = playerstore ?? {};
    const waitTime = waitSeconds ? waitSeconds * 1000 : undefined;
    const setWait = useTimeout(waitTime);

    const userTemplate = (user:User) => {
      // are they already in this event?
      const inEvent = players?.some((player) => player.user?.id === user.id);
      // maybe mutate the player name to show the matching part? Maybe later
      return <UserWidget user={user} className={ inEvent ? "in-event" : ""}/>
    }

    const suggestions = (users && filter) ? users.filter( user => {
      const compare = new RegExp(`${filter}`, 'i');
      return compare.test(user.FirstName ?? "") || compare.test(user.LastName ?? "") || compare.test(user.Username ?? "");
    } ) : undefined;



    const onChange = (value:string): void => {
        if(!users) {
            if(!busy) {
                if(value && value.length >= minCharacters) {
                    setWait(() => {
                      playerstore?.getUsers(value)
                      .then(results => {
                          setUsers(results);
                          setFilter(value);
                      }); // maybe add a catch eventually
                    });
                } else {
                    setUsers(null);
                    setFilter(undefined);
                }
            }
        } else {
            setFilter(value);
            if(!value || value.length < minCharacters) {
                setUsers(null);
            }
        }
    }

    return (
      <AutoComplete
        name={name}
        label={label}
        limited={false}
        template={userTemplate}
        suggestions={suggestions}
        onChange={onChange}
        required={required}
        disabled={disabled}
        />
    );
  }
))
import { toastService, toastStyle } from "@vaettyr/boltcave-client-core";

type toastResponse = {
    success: (() => void),
    failure: (() => void)
}

const getToasts = (service?: toastService, messages?: string[], lifespan: number = 2): toastResponse => {

    const successMessage = messages && messages.length > 0 ? messages[0] : "Succeeded";
    const failureMessage = messages && messages.length > 1 ? messages[1] : "Failed";

    const onSuccess = () => {
        service?.show({
            message: successMessage,
            style: toastStyle.success,
            dismissable: true,
            lifespan
        })
    }

    const onFailure = () => {
        service?.show({
            message: failureMessage,
            style: toastStyle.danger,
            dismissable: true,
            lifespan
        });
    }

    return { success: onSuccess, failure: onFailure };
}

export const addPlayerToasts = (service?:toastService, name: string = 'player', game:string = 'game', waitlist?: boolean):toastResponse => {
    return getToasts(service, [
        `Added ${name} to ${game}${waitlist ? ' waitlist' : ''}`,
        `Error adding ${name} to ${game}${waitlist ? ' waitlist' : ''}`
    ]);
}

export const awardPrizeToasts = (service?:toastService, prize: string = 'Prize', user: string = 'User'):toastResponse => {
    return getToasts(service, [
        `Awarded ${prize} to ${user}`,
        `Error awarding ${prize} to ${user}`
    ]);
}

export const revertPrizeToasts = (service?:toastService, prize: string = ""):toastResponse => {
    return getToasts(service, [
        `Reverted Prize ${prize}`,
        `Error reverting Prize ${prize}`
    ]);
}

export const checkinToasts = (service?:toastService, name: string = 'player', checkout: boolean = false):toastResponse => {
    return getToasts(service, [
        `Checked ${name} ${checkout ? 'Out' : 'In'}`,
        `Error checking ${name} ${checkout ? 'out' : 'in'}`
    ]);
}

export const saveItemToasts = (service?:toastService, item: string = '', update:boolean = false) => {
    return getToasts( service, [
        `${update? 'Updated' : 'Created'} ${item}`,
        `Error ${update ? 'Updating' : 'Creating'} ${item}`
    ]);
}

export const deleteItemToasts = (service?:toastService, item: string = ''): toastResponse => {
    return getToasts( service, [
        `Deleted ${item}`,
        `Error deleting ${item}`
    ]);
}

export const flexToasts = (service?:toastService, game: string = 'game', isFlex: boolean = false): toastResponse => {
    return getToasts(service, [
        `Set ${game} to ${isFlex ? 'Flex' : 'Active'}`,
        `Error setting ${game} to ${isFlex ? 'Flex' : 'Active'}`
    ]);
}

export const joinGameToasts = (service?:toastService, game: string = 'game', waitlist?:boolean):toastResponse => {
    return getToasts(service, [
        `Joined ${game}${waitlist ? ' waitlist' : ''}`,
        `Error joining ${game} ${waitlist ? ' waitlist' : ''}`
    ]);
}

export const rsvpToasts = (service?:toastService, event: string = 'event'):toastResponse => {
    return getToasts(service, [
        `RSVPed to ${event}`,
        `Error RSVPing to ${event}`
    ]);
}

export const cancelRSVPToasts = (service?: toastService, event: string = 'event'): toastResponse => {
    return getToasts(service, [
        `Cancelled RSVP to ${event}`,
        `Error cancelling RSVP to ${event}`
    ]);
}

export const removePlayerToasts = (service?:toastService, game: string = 'game', name?: string):toastResponse => {
    return getToasts(service, [
        !!name ? `Removed ${name} from ${game}` : `Left ${game}`,
        `Error ${!name ? 'leaving' : `removing ${name} from`} ${game}`
    ]);
}

export const waitlistToasts = (service?:toastService, name: string = 'player', promote?: boolean, isPrivate?: boolean ) => {
    return getToasts(service, [
        promote ? ( isPrivate ? `Approved ${name}'s request to join` : `Promoted ${name} from waitlist` ) : ( isPrivate ? `Moved ${name} to Pending` : `Moved ${name} to waitlist` ),
        promote ?
            ( isPrivate ? `Error approving ${name}'s request to join` : `Error promoting ${name} from waitlist` ) :
            ( isPrivate ? `Error moving ${name} to Pending` : `Error moving ${name} to waitlist`)
    ]);
}

export const beginEventToasts = (service?: toastService, name: string = 'Event') => {
    return getToasts(service, [
        `Successfully started ${name}`,
        `Failed to start ${name}`
    ]);
}

export default getToasts;

import { observable, action, makeObservable, runInAction, computed } from 'mobx';
import axios from 'axios';
import { AuthStore } from '@vaettyr/boltcave-auth-client';
import { storeHelpers } from '@vaettyr/boltcave-client-core';
import { Event, Token, Prize } from '../type';

type GetPrizeParams = { id?: number|number[], name?: string, active?: boolean, withBids?: boolean, hasBids?: boolean, event?: Event }

export default class PrizeStore
{
    @observable prizes: Prize[] = [];
    @observable busy: boolean = false;
    @observable count?: number = undefined;
    @observable lastQuery?: { query: GetPrizeParams, paging: {size: number, offset:number} } = undefined;

    private route:string;

    constructor()
    {
        makeObservable(this);
        this.route = storeHelpers.GetEndpoint("RPGService");
    }

    @computed get canPage() { return !!this.lastQuery && this.lastQuery.paging && this.prizes.length < (this.count ?? 0);}

    @action page = async() => {
      if (!this.lastQuery || !this.lastQuery.paging) return;
      const { query, paging } = this.lastQuery;
      return await this.get(query, { size: paging.size, offset: paging.offset + 1 });
    }

    @action get = async(query: GetPrizeParams, paging?: {size: number, offset: number } ):Promise<Prize[]> => {
      const { id, name, active, withBids, hasBids, event } = query;
      this.busy = true;
      if (paging) this.lastQuery = { query, paging };
      return new Promise((resolve, reject) => {
        const queryArgs: string[] = [];
        // should also support won_at and won_by
        if(active) queryArgs.push('active=true');
        if(withBids) queryArgs.push('with_bids=true');
        if(hasBids) queryArgs.push('has_bids=true');
        if(name) queryArgs.push(`name=${name}`);
        if(!!paging) queryArgs.push(`pagesize=${paging.size}`, `page=${paging.offset}`);
        if(!!id && Array.isArray(id)) queryArgs.push(...id.map((i) => `id[]=${i}`));
        const queryString = queryArgs.length > 0 ? `?${queryArgs.join('&')}` : '';
        const singleId = !!id && typeof(id) === 'number';
        const path = event ? `/p/${event.id}` : singleId ? `/${id}` : '';
        axios.get<{ count: number, records?: Prize[]}>(`${this.route}api/v1/prize${path}${queryString}`)
          .then(({ data: { records = [], count } = {} }) => {
            runInAction(() => {
              this.count = count;
              this.prizes = (paging?.offset ?? 0) > 0 ? [ ...this.prizes, ...records] : records;
            });
            resolve(records);
          })
          .catch(err => reject(err))
          .finally(() => {
              runInAction(() => { this.busy = false; });
          });
      });
    }

    @action save = async (prize: Prize): Promise<Prize> => {
      this.busy = true;
      return new Promise((resolve, reject) => {
        const isUpdate = !!prize.id;
        const call = isUpdate ?
            axios.post<Prize>(`${this.route}api/v1/prize`, prize, AuthStore.GetConfig()):
            axios.put(`${this.route}api/v1/prize`, prize, AuthStore.GetConfig());

        call.then(({ data }) => {
          runInAction(() => {
            if(isUpdate) {
              const index = this.prizes.findIndex((p) => p.id === prize.id);
              if(index >= 0) {
                this.prizes = [...this.prizes.slice(0, index), data, ...this.prizes.slice(index + 1)];
              }
            } else {
              this.prizes = [...this.prizes, data];
            }
          });
          resolve(data);
        })
        .catch(err => reject(err))
        .finally(() => {
            runInAction(() => { this.busy = false; });
        });
    })
    }

    @action refresh = async (prize: Prize): Promise<Prize|undefined> => {
      const index = this.prizes.findIndex((p) => p.id === prize.id);
      if (index < 0) return;
      this.busy = true;
      return new Promise((resolve, reject) => {
        axios.get<{ count: number, records?: Prize[]}>(`${this.route}api/v1/prize/${prize.id}?with_bids=true`)
        .then(({ data: { records = [], count = 0 } = {} }) => {
          if (count === 1 && records.length === 1) {
            const refreshed = records[0];
            runInAction(() => {
              this.prizes = [...this.prizes.slice(0, index), refreshed, ...this.prizes.slice(index + 1)];
            });
            resolve(refreshed);
          } else {
            reject ('unknown error refreshing prize');
          }
        })
        .catch(err => reject(err))
        .finally(() => {
            runInAction(() => { this.busy = false; });
        });
      });
    }

    @action remove = async(item:Prize):Promise<boolean> => {
      return new Promise((resolve, reject) => {
        this.busy = true;
        axios.delete(`${this.route}api/v1/prize/${item.id}`, AuthStore.GetConfig())
          .then((response) => {
            runInAction(() => {
              const index = this.prizes.findIndex((t) => t.id === item.id);
              if(index >= 0) {
                this.prizes = [...this.prizes.slice(0, index), ...this.prizes.slice(index + 1)];
              }
            });
            resolve(response.data);
          })
          .catch((err) => {
            reject(err.response);
          })
          .finally(() => {
            runInAction(() => {
                this.busy = false;
            });
          });
      });
    }

    @action award = async(prize: Prize, bid?: Token, event?: Event):Promise<Prize> => {
      const payload = bid ? { id: bid.id } : undefined;
      const path = `api/v1/prize/${prize.id}${event?`/${event.id}`:''}${!bid ? '?nowinner=true':''}`;
      return new Promise((resolve, reject) => {
        this.busy = true;
        axios.post<Token>(`${this.route}${path}`, payload, AuthStore.GetConfig())
          .then(({ data }) => {
            const index = this.prizes.findIndex((p) => p.id === prize.id);
            if (index >= 0) {
              runInAction(() => {
                this.prizes = [...this.prizes.slice(0, index), data, ...this.prizes.slice(index + 1)];
              })
            }
            resolve(data);
          })
          .catch((err) => {
            reject(err.response);
          })
          .finally(() => {
            runInAction(() => {
              this.busy = false;
            });
          })
      })
    }

    @action revert = async(prize: Prize): Promise<Prize> => {
      return new Promise((resolve, reject) => {
        this.busy = true;
        axios.patch<Token>(`${this.route}api/v1/prize/${prize.id}`, AuthStore.GetConfig())
          .then(({ data }) => {
            const index = this.prizes.findIndex((p) => p.id === prize.id);
            if (index >= 0) {
              runInAction(() => {
                this.prizes = [...this.prizes.slice(0, index), data, ...this.prizes.slice(index + 1)];
              })
            }
            resolve(data);
          })
          .catch((err) => {
            reject(err.response);
          })
          .finally(() => {
            runInAction(() => {
              this.busy = false;
            });
          })
      })
    }
}
import { User } from "@vaettyr/boltcave-auth-client";
import { media } from "@vaettyr/boltcave-media-client";
import { TypeDefinition } from "./utility/data.utility";

type coords = { lat:number, long:number };
export type Address = {
    line1: string,
    line2: string,
    city: string,
    state: string,
    zip: string
}

export type GetResponse<T> = {
  records: T[];
  count: number;
};

export type System = {
  id?: number,
  name?: string,
  shortName?: string,
  userSubmitted?: boolean,
  aliases?: string[],
  icon?: string,
  banner?: string,
  mergedWith?: number
}

export type Location = {
  id?: number,
  name?: string,
  description?: string,
  geo?: coords,
  address?:Address,
  icon?: media,
  banner?: media
}

export type Player = {
  id?: number,
  name?: string,
  user?: User,
  event?: Event,
  game?: Game,
  addedBy?: User,
  checkedIn?: boolean,
  host?: Game,
  isHost?: boolean,
  systemPreference?: Record<number, number[]>,
  waitlist?: number[]
};

export type EventTemplate = {
  repeats: 'weekly' | 'monthly',
  day: 0|1|2|3|4|5|6,
  position?: 'first'|'second'|'third'|'last'
};

export type EventLink = {
  type?: string,
  link: string
};

export type EventType = 'game night'| 'dm workshop'| 'ad hoc'| 'external'| 'other';

export type Event = {
  id?: number,
  type?: string, // not sure why it won't let me type this correctly
  status?: string,
  name?: string,
  description?: string,
  location?: Location,
  eventDate?: Date,
  template?: EventTemplate,
  parent?: Event,
  owner?: User,
  links?: EventLink[],
  locked?: boolean,
  cancelled?: boolean,
  players?: Player[],
  playersPerTable?: number,
  checkinKey?: string, // are we going to return this?
  games?: Game[]
}

export const EventDefinition: TypeDefinition = [
  'id', 'type', 'status', 'name', 'description', 'eventDate', 'template', 'links', 'locked', 'cancelled', 'playersPerTable', 'checkinKey',
  { key: 'parent', type: 'reference' }, { key: 'location', type: 'reference' },
]

export type GameMetadata = {
  level?: number,
  pregen?: boolean,
  premade?: boolean,
  type?: string,
  flat?: boolean
};

export type Game = {
  id?: number,
  owner?: User,
  title?: string,
  description?: string,
  event?: Event|number,
  isFlex?: boolean,
  systems?: (System | string | number)[], // not sure about this one . . .
  minPlayers?: number,
  maxPlayers?: number,
  players?: Player[],
  metadata?: GameMetadata
}

export type GlobalContent = {
  single?: boolean;
  display?: "link" | "accordion";
  uri?: string;
  icon?: string;
  heading?: string;
};

export type EventContent = {
  title?: string;
  description?: string;
  upvotes?: number[];
}

export type Content = {
  id?: number;
  type?: string;
  body?: string;
  linkType?: "game" | "event" | "location" | "system" | "user";
  parent?: number;
  sortOrder?: number;
  metadata?: GlobalContent & EventContent;
  createdBy?: User;
}

export type Token = {
  id?: number;
  user?: User;
  game?: Game;
  event?: Event;
  prize?: Prize;
  amount?: number;
  description?: string;
  locked?: boolean;
  won?: boolean;
  addedBy?: User;
  updatedBy?: User;
  created?: string|Date;
  updated?: string|Date;
}

export const TokenDefinition: TypeDefinition = [
  'id', 'amount', 'description', 'locked', 'won',
  { key: 'user', type: 'reference' }, { key: 'game', type: 'reference' }, { key: 'event', type: 'reference' }, { key: 'prize', type: 'reference' },
]

export type Prize = {
  id?: number;
  name?: string;
  description?: string;
  image?: string;
  minimumBid?: number;
  wonBy?: User;
  wonAt?: Event;
  bids?: Token[];
  created?: string|Date;
  updated?: string|Date;
  createdBy?: User;
  updatedBy?: User;
}
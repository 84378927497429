import { inject, observer } from 'mobx-react';
import { Card, modalService, NumberInput, toastService } from '@vaettyr/boltcave-client-core';
import { Event, Prize } from '../../type';
import { AuthStore } from '@vaettyr/boltcave-auth-client';
import { MediaImage } from '@vaettyr/boltcave-media-client';
import ReactMarkdown from 'react-markdown';
import TokenItem from '../token/token.item.view';
import EditToken, { modalName as editModalName } from '../token/modals/token.edit.view';
import { PrizeController } from './prize.controller';
import useFallback from '../../hooks/useFallback';
import { Formik } from 'formik';
import TokenStore from '../../stores/tokenStore';
import { saveItemToasts } from '../../utility/toast.utility';
import { useEffect } from 'react';
import PlayerIcon from '../player/widgets/player.icon';
import PrizeMenu from './menus/prize.menu';

type PrizeDetailProps = {
  authstore?: AuthStore;
  controller: PrizeController;
  isModal?: boolean;
  modalservice?: modalService;
  event?: Event;
  prize: Prize;
  toastservice?: toastService;
  tokenstore?: TokenStore;
}

export const modalName = 'prize-detail';

export default inject('authstore', 'modalservice', 'toastservice', 'tokenstore') ( observer(
  ({ authstore, controller, isModal, modalservice, event, prize, toastservice, tokenstore }: PrizeDetailProps) => {
    const { tokens: bids = [] } = tokenstore ?? {};
    const { user: { id: userId = 0 } = {} } = authstore ?? {};
    const { description, image, name } = prize;
    const { balance, bidAmount, canAward, canBid, myBid, initialize, owned, owner, wonOn, wonAt } = controller;
    const fallback = useFallback("prize") as string | undefined;

    // this is bad but I don't feel like doing the proper fix right now
    useEffect(() => {
      initialize();
    }, []);

    const close = () => modalservice?.hide(modalName);

    const addBid = () => {
      modalservice?.show({
        body: <EditToken isModal token={{ amount: -1, prize, description: `Bid on ${prize.name}` }} />,
        key: editModalName,
        options: { className: editModalName }
      });
    }

    const saveBid = ({ amount }: { amount: number }) => {
      const { success, failure } = saveItemToasts(toastservice, "bid");
      tokenstore?.save({ amount: -amount, prize, description: `Bid on ${prize.name}` }, userId)
      .then(success)
      .catch(failure);
    }

    const body = (
      <div className="prize-detail">
        <MediaImage src={ image ?? fallback ?? '' } mode="crop" className="current-image" ratio='1by1' />
        <ReactMarkdown className="markdown">{ description ?? '' }</ReactMarkdown>

        { canBid && !myBid && (
          <Formik initialValues={{ amount: bidAmount ?? 1 }} onSubmit={saveBid}>
            {({ handleSubmit }) => (
              <div className="bid-form">
                <NumberInput name="amount" integer min={1} max={balance} label="Bid Amount"/>
                <button className="button is-primary" onClick={() => handleSubmit()}>Place Bid</button>
              </div>
            )}
          </Formik>
        )}
        { !owned && (
          <>
            <h2 className="subtitle bid-header" key="bid-header">
              <span className="text">Bids</span>
              { canAward && <span className="icon clickable" onClick={ addBid } data-tooltip={`Add Bid`}>
                  <i className="fa-solid fa-square-plus"></i>
              </span> }
            </h2>
            { bids.length === 0 && <span key="no-bids">There are no bids on this Prize yet</span>}
            { bids.length > 0 && (
              <div className="bid-list" key="bid-list">
                { bids.map((bid, index) => <TokenItem user bid token={bid} key={`prize-bid-${index}`} showAward={canAward && !prize.wonBy} onAction={close} event={event} />)}
              </div>
            )}
          </>
        )}
        { owned && (
          <div className="won-info">
            <span>Won</span>
            { wonOn && <span> on {wonOn.toLocaleDateString()}</span> }
            { wonAt && <span> at {wonAt}</span> }
            <span>by</span>
            <PlayerIcon player={{ user: owner }} key="won-owner" />
          </div>
        )}
      </div>
    );

    const actions = <>
      <button className="card-footer-item button" onClick={close}>Ok</button>
    </>;

    const canEditPrize = authstore?.hasPermission('ENUM.PrizeService.CanEditPrizes', 'ALL') ||
      (authstore?.hasPermission('ENUM.PrizeService.CanEditPrizes', 'OWN') && ((prize.createdBy?.id ?? 0) === authstore?.user.id ?? -1));

    const header = <>
      <p className="card-header-title">{name}</p>
      { canEditPrize && <PrizeMenu prize={prize} event={event} /> }
    </>;

    return isModal ? (
      <Card header={header} footer={actions}>
        { body }
      </Card>
    ) : body;
  }
));
import { observer, inject } from 'mobx-react';
import { modalService } from '@vaettyr/boltcave-client-core';
import EditLocation from './modals/location.edit';
import DeleteLocation from './modals/location.delete';
import { Location } from '../../type';

type LocationActionProps = {
    item?: Location,
    modalservice?: modalService
}

export default inject('modalservice') ( observer (
({ item, modalservice }: LocationActionProps) => {

    const onEdit = () => {
        modalservice?.show({
            body:<EditLocation item={item} />,
            options:{className:"edit-location"},
            key:"edit-location"
        });
    }

    const onDelete = () => {
        modalservice?.show({
            body: <DeleteLocation item={item} />,
            options: { className: "delete-location" },
            key: "delete-location"
        });
    }

    return (
        <>
            <span
                className="icon clickable"
                data-tooltip="Edit Location"
                key={`edit-location-${item?.id}`}
                onClick={ onEdit }
            >
                <i className="fa-solid fa-pen-to-square"></i>
            </span>
            <span
                className="icon clickable"
                data-tooltip="Delete Location"
                key={`delete-location-${item?.id}`}
                onClick={ onDelete }
            >
                <i className="fa-solid fa-trash"></i>
            </span>
        </>
    );
}));
import { storeHelpers } from "@vaettyr/boltcave-client-core";
import FallbackContext from "../utility/fallback.context";
import { useContext } from "react";

type FallbackResponse = Record<string, string|undefined>|string|undefined;

export default ( tags: string|string[]): FallbackResponse => {
    const { images } = useContext(FallbackContext);
    const route = storeHelpers.GetEndpoint("MediaService");
    if(typeof(tags) === 'string') {
        const fallback = images.find((image) => image.tags?.includes(tags));
        return !!fallback ? `${route}${fallback.location.substring(1)}` : undefined;
    } else {
        return Object.fromEntries(tags.map((tag) => {
            const fallback = images.find((image) => image.tags?.includes(tag));
            return [tag, !!fallback ? `${route}${fallback.location.substring(1)}` : undefined];
        }));
    }
}
import { AuthStore } from "@vaettyr/boltcave-auth-client";
import { Game, Player } from '../type';
import { claimTypes } from "../const";

type PermissionArgs = {
  authstore?: AuthStore,
  game?: Game,
  player?: Player,
  self?: Player,
  isFull?: boolean
}

type Permissions = {
  canRemovePlayer?: boolean,
  canMoveToWaitlist?: boolean,
  canPromoteFromWaitlist?: boolean,
  canCheckPlayerIn?: boolean,
  canCheckPlayerOut?: boolean,
  canUpdatePreferences?: boolean
}

export default ({ authstore, game, player, isFull, self }: PermissionArgs): Permissions => {

  const { claims } = authstore ?? {};
  const CanEditAllPlayers = claims?.find((c) => c.Key === claimTypes.Players)?.Value as boolean ?? false;

  const isHost = self?.host?.id === game?.id;
  const addedPlayer = !!player?.addedBy?.id && !!self?.user?.id && player.addedBy.id === self.user.id;
  const canRemovePlayer = CanEditAllPlayers || addedPlayer || isHost;
  const canCheckPlayerIn = (!!self?.checkedIn && canRemovePlayer && !player?.checkedIn) || CanEditAllPlayers;
  const canCheckPlayerOut = (!!self?.checkedIn && canRemovePlayer && !!player?.checkedIn) || CanEditAllPlayers;
  const canUpdatePreferences = addedPlayer && (game?.systems?.length ?? 0) > 1;
  const isPrivate = game?.metadata?.type?.toLowerCase() === 'private';
  const onWaitlist = player?.waitlist?.includes(game?.id ?? 0) ?? false;
  const canMoveToWaitlist = !onWaitlist && canRemovePlayer;
  const canPromoteFromWaitlist = onWaitlist && !isFull && (CanEditAllPlayers || isHost || (addedPlayer && !isPrivate));

  return {
    canRemovePlayer,
    canCheckPlayerIn,
    canCheckPlayerOut,
    canUpdatePreferences,
    canMoveToWaitlist,
    canPromoteFromWaitlist
  };
}
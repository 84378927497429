import { observer, inject } from 'mobx-react';
import { modalService } from '@vaettyr/boltcave-client-core';
import EditSystem from './modals/system.edit';
import DeleteSystem from './modals/system.delete';
import MergeSystems from './modals/system.merge';
import { System } from '../../type';

type SystemActionProps = {
  item: System,
  modalservice?: modalService
}

export default inject('modalservice') ( observer (
({ item, modalservice }: SystemActionProps) => {
  const onEdit = () => {
    modalservice?.show({
      body:<EditSystem item={item} />,
      options:{className:"edit-system"},
      key:"edit-system"
    });
  }

  const onDelete = () => {
    modalservice?.show({
      body:<DeleteSystem item={item} />,
      options:{className:"delete-system"},
      key:"delete-system"
    });
  }

  const onMerge = () => {
    modalservice?.show({
      body:<MergeSystems from={item} />,
      options: { className: 'merge-systems' },
      key: 'merge-systems'
    });
  }

  return (
      <>
          <span className="icon clickable" onClick={ onEdit } data-tooltip="Edit System">
              <i className="fa-solid fa-pen-to-square"></i>
          </span>
          <span className="icon clickable" onClick={ onMerge } data-tooltip="Merge System">
            <i className="fa-solid fa-object-group"></i>
          </span>
          <span className="icon clickable" onClick={ onDelete } data-tooltip="Delete System">
              <i className="fa-solid fa-trash"></i>
          </span>
      </>
  );
} ));
export type DataType = "number" | "reference" | "string" | "object";

export type TypeDefinition = (string|{key:string, type: DataType}) [];

const fromObservable = <T>(item: T, definition: TypeDefinition): T => {
  return Object.fromEntries(definition.map((entry) => {
    if (typeof entry === 'string') return [entry, (item as Record<string, any>)[entry]];
    let value = (item as Record<string, any>)[entry.key];
    if (entry.type === 'reference') {
      value = getId(value);
    }
    return [entry.key, value];
  } )) as T;
}

export const getId = (entry?: {id?: number} | number): number|undefined => {
  if (typeof entry === 'number') return entry;
  return entry?.id;
}

export default fromObservable;
import { Event } from '../type';
import { DateArray, EventAttributes } from 'ics';

const toCalendarEvent = (event: Event, length: number = 1): EventAttributes => {
  const { eventDate = new Date(), location } = event;
  const start: DateArray = [
    eventDate.getFullYear(),
    eventDate.getMonth(),
    eventDate.getDate(),
    eventDate.getHours(),
    eventDate.getMinutes()
  ];
  const duration = { hours: length };
  return {
    start,
    duration,
    title: event?.name ?? "ATLRPG Event",
    description: event?.description ?? "ATLRPG Event",
    location: location?.name ?? "Unknown location",
    geo: !!location?.geo ? { lat: location.geo.lat, lon: location.geo.long } : undefined,
    status: 'CONFIRMED',
    busyStatus: 'BUSY'
  }
}

export { toCalendarEvent }
import { observer, inject } from 'mobx-react';
import { Formik, Form } from 'formik';
import { Card, toastService, modalService, Select } from '@vaettyr/boltcave-client-core';
import { AuthStore, authHelpers } from '@vaettyr/boltcave-auth-client';
import SystemStore from '../../../stores/systemStore';
import { System } from '../../../type';

type EditSystemProps = {
    authstore?:AuthStore,
    toastservice?:toastService,
    modalservice?:modalService,
    from: System,
    systemstore?: SystemStore
}

const modalName = 'merge-systems';

export default inject( 'systemstore', 'toastservice', 'modalservice','authstore' )
    ( observer(({ toastservice, modalservice, authstore, systemstore, from }: EditSystemProps ) => {

    function mergeSystems( { fromSystem, toSystem }: { fromSystem: number, toSystem: number } ):void {
        systemstore?.merge({id: fromSystem }, {id: toSystem})
            .then(() => {
                modalservice?.hide(modalName);
            })
            .catch( err => {
                authHelpers.HandleSecureError(err, toastservice, authstore);
            });
    }

    function onCancel() {
        modalservice?.hide(modalName);
    }

    const disabled = systemstore?.busy;

    const initialValues = {
        fromSystem: from.id ?? 0,
        toSystem: 0
    }

    const { systems = [] } = systemstore ?? {};
    const otherSystems = ['', ...systems.filter((s) => s.id !== from.id).map((s) => ({ value: s.id ?? 0, label: s.name }))];

    return (
        <Formik initialValues={ initialValues } onSubmit={ mergeSystems }>
            {({dirty, isValid, handleSubmit}) => {

                const actions = (
                    <>
                        <button type="button" className="card-footer-item button is-primary" disabled={!dirty||!isValid||disabled} onClick={()=>{handleSubmit()}}>Save</button>
                        <button className="card-footer-item button" onClick={onCancel}>Cancel</button>
                    </>
                );

                return (
                    <Card header="Merge Systems" footer={actions}>
                        <Form className="merge-systems">
                            { systemstore?.busy && (<progress className="progress is-primary is-medium" max="100">15%</progress>)}
                            <Select name="fromSystem" label="From System" required options={[{ value: from.id ?? 0, label: from.name }]} disabled />
                            <Select name="toSystem" label="To System" required options={otherSystems} />
                        </Form>
                    </Card>
                );
            } }
        </Formik>
    );
}));
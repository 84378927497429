import { observer, inject } from 'mobx-react';
import { Card, toastService, modalService } from '@vaettyr/boltcave-client-core';
import EventStore from '../../../stores/eventStore';
import { Event } from '../../../type';
import { deleteItemToasts } from '../../../utility/toast.utility';

type DeleteEventProps = {
    toastservice?:toastService,
    modalservice?:modalService,
    event?: Event,
    eventstore?: EventStore
}

const modalName = 'delete-event';

export default inject( 'eventstore', 'toastservice', 'modalservice' )
    ( observer(({ toastservice, modalservice, event, eventstore }: DeleteEventProps ) => {

    const onDelete = ():void => {
        const { success, failure } = deleteItemToasts(toastservice, event?.name);
        eventstore?.remove(event as Event)
            .then(success).catch(failure).finally(() => modalservice?.hide(modalName));
    }

    const onCancel = () => modalservice?.hide(modalName);

    const actions = (
        <>
            <button type="button" className="card-footer-item button is-primary" onClick={onDelete}>Delete</button>
            <button className="card-footer-item button" onClick={onCancel}>Cancel</button>
        </>
    );

    return (
        <Card header="Delete Event" footer={actions}>
            <p>Are you sure you want to delete Event {event?.name ?? ''}</p>
        </Card>
    );
}));
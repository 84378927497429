import MarkdownInput from '../markdown/markdown.preview.input';
import { TextInput } from '@vaettyr/boltcave-client-core';
import { ImageInput } from '@vaettyr/boltcave-media-client';

type EditGlobalProps = {
  busy?: boolean;
  id?: number;
  type?: string;
}

export default ({ busy, id, type }: EditGlobalProps) => (<>
  { type !== 'about' && <TextInput name="metadata.heading" label={ type === 'faq' ? 'Question' : 'Display Text' } required disabled={busy}/> }
  { type !== 'link' && (
    <MarkdownInput
      name="body"
      label={ type === 'faq' ? 'Answer' : 'Content' }
      lines={ type === 'faq' ? 3 : 10 }
      required
      disabled={busy}
    />
  ) }
  { type === 'link' && (
    <>
      <TextInput name="metadata.uri" label="Address" required disabled={busy} />
      <ImageInput
          name='metadata.icon'
          showButton
          label="Icon"
          ingest
          fullPath
          imageProps={{ name: `link-icon-${id ?? 'new'}`, sector: 'site-content', tags: ['site-content', 'icon', 'link'] }}
          previewProps={{ mode: 'crop', size: 48, rounded: true }}
      />
    </>
  )}
</>);
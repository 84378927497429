import { observable, action, computed, makeObservable, runInAction } from 'mobx';
import { AuthStore, User } from '@vaettyr/boltcave-auth-client';
import { storeHelpers, toastService, toastStyle } from '@vaettyr/boltcave-client-core';
import GameStore from './gameStore';
import PlayerStore from './playerStore';
import EventStore from './eventStore';
import { Event, Player } from '../type';

export default class LiveUpdateStore
{
    @observable socket?:WebSocket = undefined;
    @observable connected: boolean = false;

    private playerStore:PlayerStore;
    private eventStore:EventStore;
    private gameStore:GameStore;
    private route:string;
    private toastService: toastService|undefined = undefined;
    private event?:Event = undefined;
    private self?: Player = undefined;
    private user?: User = undefined;

    @computed get isConnected(): boolean { return !!this.socket && this.socket.readyState === WebSocket.OPEN };

    constructor(eventstore:EventStore, gamestore:GameStore, playerstore:PlayerStore)
    {
        makeObservable(this);
        this.route = storeHelpers.GetEndpoint("RPGService").replace('http', 'ws');
        this.playerStore = playerstore;
        this.eventStore = eventstore;
        this.gameStore = gamestore;
    }

    initialize = (user?: User, toastservice?: toastService) => {
        this.user = user;
        this.toastService = toastservice;
    }

    @action connect = (event: Event, player?: Player) => {
        if(this.isConnected) { this.disconnect(); }
        this.event = event;
        this.self = player;
        this.socket = new WebSocket(`${this.route}api/v1/subscribe/${event.id}`);
        this.socket.onerror = console.error;
        this.socket.onmessage = this.processMessage;
        this.socket.onopen = () => {
            this.gameStore?.fetch(event);
            this.playerStore?.fetch(event);
            runInAction(() => this.connected = true);
        }
        this.socket.onclose = () => {
            console.log('connection terminated');
        }
    }

    @action disconnect = () => {
        this.socket?.close();
        this.socket = undefined;
    }

    private processMessage = ({data}:MessageEvent) => {
        try {
            const { service, event, payload }: {service: string, event?:string, payload?: any} = JSON.parse(data);
            switch(service) {
                case "authenticate":
                    this.socket?.send(JSON.stringify({event:'authenticate', payload: { event: this.event?.id, authorization: AuthStore.GetConfig()?.headers.Authorization}}));
                    break;
                case "ping":
                    this.socket?.send(JSON.stringify({event: 'pong', payload: { event: this.event?.id, user: this.user?.id }}));
                    break;
                case "player":
                    this.processPlayerEvent(event, payload);
                    break;
                case "game":
                    this.processGameEvent(event, payload);
                    break;
                case "event":
                    this.processEventEvent(event, payload);
                    break;
                case "error":
                    console.error('caught error', payload);
                    break;
                default:
                    break;
            }
        } catch(err) {
            console.error(err);
        }
    }

    private processPlayerEvent = (event?: string, payload?: any) => {
        const { players } = this.playerStore;
        const pIndex = players.findIndex((p) => p.id === payload.id);
        // const formerGame = pIndex >= 0 ? players[pIndex].game?.id : undefined;
        runInAction(() => {
            switch(event ?? "") {
                case "add":
                case "edit":
                    if(pIndex >= 0) {
                        this.playerStore.players = [...players.slice(0, pIndex), payload, ...players.slice(pIndex + 1)];
                    } else {
                        this.playerStore.players = [...players, payload];
                    }
                    // if we're in this game, should show a toast
                    /*
                    if(!!this.self?.game && payload.game?.id === this.self?.game?.id && formerGame !== this.self?.game?.id) {
                        this.toastService?.show({
                            message: "A new player has joined your game.",
                            dismissable: true,
                            lifespan: 3,
                            style: toastStyle.info
                        });
                    } else if(!!this.self?.game && formerGame === this.self?.game?.id && payload?.game?.id !== formerGame) {
                        this.toastService?.show({
                            message: "A player has left your game.",
                            dismissable: true,
                            lifespan: 3,
                            style: toastStyle.warning
                        });
                    }
                    */
                    break;
                case "delete":
                    if(pIndex >= 0) {
                        this.playerStore.players = [...players.slice(0, pIndex), ...players.slice(pIndex + 1)];
                        /*
                        this.toastService?.show({
                            message: "A player has left your game.",
                            dismissable: true,
                            lifespan: 3,
                            style: toastStyle.warning
                        });
                        */
                    }
                    break;
                default:
                    console.error("Invalid player event received");
                    break;
            }
        });
    }

    private processGameEvent = (event?:string, payload?:any) => {
        const { games } = this.gameStore;
        const gIndex = games.findIndex((g) => g.id === payload.id);
        runInAction(() => {
            switch(event ?? "") {
                case "add":
                case "edit":
                    if(gIndex >= 0) {
                        this.gameStore.games = [...games.slice(0, gIndex), payload, ...games.slice(gIndex + 1)];
                    } else {
                        this.gameStore.games = [...games, payload];
                    }
                    /*
                    if(event === "add" && !this.self?.game?.id) {
                        this.toastService?.show({
                            message: "A new game has become available to join",
                            dismissable: true,
                            lifespan: 5,
                            style: toastStyle.success
                        });
                    }
                    */
                    break;
                case "delete":
                    if(gIndex >= 0) {
                        this.gameStore.games = [...games.slice(0, gIndex), ...games.slice(gIndex + 1)];
                        this.playerStore?.fetch(this.event as Event);
                        /*
                        if(payload.id === this.self?.game?.id) {
                            this.toastService?.show({
                                message: "Your game has been cancelled.",
                                dismissable: true,
                                lifespan: 3,
                                style: toastStyle.danger
                            });
                        }
                        */
                    }
                    break;
                default:
                    console.error("Invalid game event received");
                    break;
            }
        });
    }

    private processEventEvent = (event?:string, payload?:any) => {
        const { events } = this.eventStore;
        const eIndex = events.findIndex((e) => e.id === payload.id);
        // we should check that it's this event tho
        runInAction(() => {
            switch(event ?? "") {
                case "add":
                case "edit":
                    if(eIndex >= 0) {
                        this.eventStore.events = [...events.slice(0, eIndex), payload, ...events.slice(eIndex + 1)];
                    } else {
                        this.eventStore.events = [...events, payload];
                    }
                    break;
                case "delete":
                    if(eIndex >= 0) {
                        this.eventStore.events = [...events.slice(0, eIndex), ...events.slice(eIndex + 1)];
                        if(payload.id === this.self?.event?.id) {
                            this.toastService?.show({
                                message: "This event has been cancelled",
                                dismissable: true,
                                lifespan: 5,
                                style: toastStyle.danger
                            });
                        }
                    }
                    break;
                default:
                    break;
            }
        });
    }
}
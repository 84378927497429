
import { Card, modalService, toastService } from "@vaettyr/boltcave-client-core";
import { inject, observer } from 'mobx-react';
import { Event, Game, Player } from '../../../type';
import { waitlistToasts } from "../../../utility/toast.utility";
import PlayerStore from "../../../stores/playerStore";
import GameStore from '../../../stores/gameStore';

type PlayerWaitlistProps = {
  playerstore?: PlayerStore,
  gamestore?: GameStore,
  event: Event,
  game?: Game,
  player?: Player,
  promote?: boolean,
  isPrivate?: boolean,
  playerName: string,
  modalservice?: modalService,
  toastservice?: toastService
}

export const modalName = "player-waitlist";

export default inject('playerstore', 'gamestore', 'modalservice', 'toastservice') ( observer (
  ({ event, game, player, gamestore, playerName, promote, isPrivate, playerstore, modalservice, toastservice }: PlayerWaitlistProps ) => {

    const onToggle = () => {
      const { success: waitlistSuccess, failure: waitlistFailure } = waitlistToasts(toastservice, playerName, promote, isPrivate);
      playerstore?.toggleWaitlist(event, game, { id: player?.id, waitlist: player?.waitlist } as Player)
      .then(({deleted}) => {
          if(deleted) {
              gamestore?.clear(deleted);
          }
          waitlistSuccess();
          modalservice?.hide(modalName);
      }).catch(waitlistFailure);
    }

    const onCancel = () => modalservice?.hide(modalName);

    const actions = (
      <>
        <button type="button" className="card-footer-item button is-primary" onClick={onToggle}>Yes</button>
        <button className="card-footer-item button" onClick={onCancel}>No</button>
      </>
    )

    const title = promote ?
      ( isPrivate ? 'Approve Request' : 'Promote from Waitlist') :
      ( isPrivate ? 'Move to Pending' : 'Move to Waitlist')

    const body = promote ?
      ( isPrivate ? `approve ${playerName}'s request to join` : `promote ${playerName} from waitlist` ) :
      ( isPrivate ? `move ${playerName} to Pending` : `move ${playerName} to waitlist` );

    return (
      <Card header={title} footer={actions}>
        <p>Are you sure you want to {body}?</p>
      </Card>
    )
  }
));
import { observer, inject } from 'mobx-react';
import { Card, modalService } from '@vaettyr/boltcave-client-core';

type EditEventProps = {
    modalservice?:modalService,
    isHost?: boolean
}

const modalName = 'game-permission';

export default inject( 'modalservice' )
    ( observer(({ modalservice, isHost }: EditEventProps ) => {

    function onCancel() {
        modalservice?.hide(modalName);
    }

    const message = isHost ? "You are already the host of a game. In order to create a new game you must cancel your existing game." :
      "Anonymous Accounts cannot create games. In order to host a game, create an account or sign in with one of the other options.";

    return (
      <Card footer={<button className="card-footer-item button" onClick={onCancel}>Ok</button>}>
        <p>{ message }</p>
      </Card>
    );
}));
import { useEffect, useRef } from 'react';
import { inject, observer } from 'mobx-react';
import { modalService } from '@vaettyr/boltcave-client-core';
import { AuthStore } from '@vaettyr/boltcave-auth-client';
import EventStore from '../../stores/eventStore';
import EventDateView from '../event/event.date.view';
import EditEvent from '../event/modals/event.edit';
import { toISO, weekDays } from '../../utility/date.utility';

type DateViewProps = {
  authstore?: AuthStore,
  date?: Date,
  eventstore?: EventStore,
  month?: number,
  modalservice?: modalService
}

export default inject('authstore', 'eventstore', 'modalservice') (observer(
({ authstore, date, eventstore, month, modalservice }: DateViewProps) => {

  const canCreateEvents = (authstore?.hasPermission("ENUM.EventService.CanEditEvents", "ALL") ||
    authstore?.hasPermission("ENUM.CanEditEvents", "OWN")) ?? false;

  const showEventModal = () => {
    if(canCreateEvents) {
      modalservice?.show({
        body:<EditEvent date={date}/>,
        options:{className:"edit-event"},
        key:"edit-event"
      });
    }
  }

  const ref = useRef<HTMLElement|null>(null);

  const events = eventstore?.onDate(date);
  const isMonth = month === date?.getMonth();
  const isToday = toISO(date, 3) === toISO(new Date(Date.now()), 3);

  useEffect(() => {
    if(isToday && !!ref && !!ref.current) {
      ref.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [ref.current]);

  return (
    <section className={`date-view${ isMonth ? ' active' : ' inactive' }${ isToday ? ' selected' : '' }`} ref={ref} onClick={showEventModal}>
      <h3 className="date-identifier">
        <span className="week-day">{ weekDays[date?.getDay() ?? 0].substring(0,3)} </span>
        <span className="date-number">{ date ? date.getDate() : ''}</span>
      </h3>
      <div className="date-body">
        { events && events.map((event, index) => <EventDateView event={event} key={`event-${index}`} />)}
      </div>
    </section>
  );
}
));
import { observable, action, computed, makeObservable, runInAction } from 'mobx';
import axios from 'axios';
import { AuthStore } from '@vaettyr/boltcave-auth-client';
import { storeHelpers } from '@vaettyr/boltcave-client-core';
import { Content } from '../type';

type LinkType = "game" | "event" | "location" | "system" | "user";
type ContentType = "about" | "faq" | "link" | "topic";

export default class ContentStore
{
    @observable contents: { global?: Content[], [key:string]: Record<string, Content[]>|Content[]|undefined } = {};
    @observable busy:boolean = false;

    private route:string;

    constructor()
    {
        makeObservable(this);
        this.route = storeHelpers.GetEndpoint("RPGService");
    }

    @action init = () => {
      if (!!this.contents.global) return;
      const { __RPG: { data: { content = false } = {} } = {} } = window as any;
      if (!!content) {
        this.sortContent(content as Content[] );
      } else {
        this.fetch(); // ooh, maybe clean this up too
      }
    }

    @action save = async (content: Content):Promise<Content> =>
    {
        this.busy = true;
        return new Promise((resolve, reject) => {
            const isUpdate = !!content.id;
            const call = isUpdate ?
                axios.post(`${this.route}api/v1/content`, content, AuthStore.GetConfig()):
                axios.put(`${this.route}api/v1/content`, content, AuthStore.GetConfig());

            call.then(({ data }: { data: Content }) => {
              runInAction(() => {
                  this.sortContent([data]);
              });
              resolve(data);
            })
            .catch(err => reject(err))
            .finally(() => {
                runInAction(() => { this.busy = false; });
            });
        })
    }

    @action fetch = async({ parent, id, type }: { parent?: LinkType, id?: number|number[], type?: ContentType } = {}):Promise<Content[]> =>
    {
      this.busy = true;
      const queryArgs:string[] = [];
      if(!!type) queryArgs.push(`type=${type}`);
      if(!!id && Array.isArray(id)) queryArgs.push(...id.map((i) => `parent[]=${i}`));
      const query = queryArgs.length > 0 ? `?${queryArgs.join('&')}` : '';
      const path = `api/v1/content${!!parent ? `/${parent}${!!id ? `/${id}` : ''}` : ''}${query}`;
      return new Promise((resolve, reject) => {
          axios.get(`${this.route}${path}`, AuthStore.GetConfig())
          .then(({ data = [] }:{ data: Content[] }) => {
              runInAction(() => {
                this.sortContent(data);
              });
              resolve(data ?? []);
          })
          .catch(err => reject(err))
          .finally(() => {
              runInAction(() => { this.busy = false; })
          });
      });
    }

    @action remove = async(item:Content):Promise<boolean> => {
        return new Promise((resolve, reject) => {
          this.busy = true;
          axios.delete(`${this.route}api/v1/content/${item.id}`, AuthStore.GetConfig())
            .then((response) => {
              runInAction(() => {
                this.sortContent([item], true);
                resolve(response.data);
              });
            })
            .catch((err) => {
              reject(err.response);
            })
            .finally(() => {
              runInAction(() => {
                  this.busy = false;
              });
            });
        });
    }

    @computed get siteContents(): string[] {
      const contentTypes = this.contents.global?.reduce((types, item) => !item.type || types.includes(item.type) ? types : [...types, item.type], [] as string[]) ?? [];
      contentTypes.sort();
      return contentTypes;
    }

    private sortContent = (content: Content[], remove?: boolean) => {
      this.contents = content.reduce((allcontent, item) => {
        const parentType = item.linkType ?? 'global';
        const parentId = item.parent?.toString() ?? "0";
        if(parentType === 'global') {
          const indexOf = allcontent.global?.findIndex((c) => c.id === item.id) ?? -1;
          const global = allcontent.global ?? [];
          if(remove) {
            if(indexOf >= 0) {
              return { ...allcontent, global: [...global.slice(0, indexOf), ...global.slice(indexOf + 1)] };
            } else {
              return allcontent;
            }
          } else {
            if(indexOf >= 0) {
              return { ...allcontent, global: [...global.slice(0, indexOf), item, ...global.slice(indexOf + 1)] };
            } else {
              return { ...allcontent, global: [...global, item] };
            }
          }
        } else {
          const typeContent = (allcontent[parentType] ?? {}) as Record<string, Content[]>;
          const entryContent = typeContent[parentId] ?? [];
          const entryIndex = entryContent.findIndex((c) => c.id === item.id);
          if(remove) {
            if(entryIndex >= 0) {
              return { ...allcontent, [parentType]: {...typeContent, [parentId]: [...entryContent.slice(0, entryIndex), ...entryContent.slice(entryIndex + 1)] }};
            } else {
              return allcontent;
            }
          } else {
            if(entryIndex >= 0) {
              return { ...allcontent, [parentType]: {...typeContent, [parentId]: [...entryContent.slice(0, entryIndex), item, ...entryContent.slice(entryIndex + 1)] }};
            } else {
              return { ...allcontent, [parentType]: {...typeContent, [parentId]: [...entryContent.slice(), item] }};
            }
          }
        }
      }, this.contents);
    }
}
import { Card, modalService, toastService } from "@vaettyr/boltcave-client-core";
import GameStore from "../../../stores/gameStore";
import PlayerStore from "../../../stores/playerStore";
import { inject, observer } from 'mobx-react';
import { Game, Event } from '../../../type';
import { deleteItemToasts } from "../../../utility/toast.utility";

type GameDeleteProps = {
  gamestore?: GameStore,
  game: Game,
  template?: string,
  modalservice?: modalService,
  playerstore?: PlayerStore,
  toastservice?: toastService
}

const modalName = "game-delete";

export default inject('gamestore', 'modalservice', 'playerstore', 'toastservice') ( observer (
  ({ game, template, gamestore, modalservice, playerstore, toastservice }: GameDeleteProps ) => {

    const onDelete = () => {
      const { success, failure } = deleteItemToasts(toastservice, game?.title ?? 'game')
      if(!!template) {
        const removed = gamestore?.deleteTemplate(template);
        if(removed) modalservice?.hide(modalName);
      } else {
        gamestore?.remove(game)
        .then(() => {
          // refresh the player list
          playerstore?.fetch(game.event as Event)
          .then(() => {
            success();
            modalservice?.hide(modalName);
          })
          .catch(failure);
        }).catch(failure)
      }
    }

    const onCancel = () => modalservice?.hide(modalName);

    const actions = (
      <>
        <button type="button" className="card-footer-item button is-primary" onClick={onDelete}>Yes</button>
        <button className="card-footer-item button" onClick={onCancel}>No</button>
      </>
    )

    return (
      <Card header={`Delete Game{!!template && ' Template'}`} footer={actions}>
        <p>Are you sure you want to delete this game{!!template && ' template'}?</p>
      </Card>
    )
  }
));